import React, { useState, useEffect } from 'react';

import { Col, Row, Form, Container, Button, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import { Toast, ToastStyle } from "../Views/Toast"
import { MdOutlineDelete } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import { useIntl } from 'react-intl';
import styled from "styled-components"
import Loader from '../Views/Loader';
import PaimentService from '../Service/PaimentService';

function GeneralParamater(props) {
    const intl = useIntl();
    const updateParameter = (ob) => {
        setLoding(true)
        PaimentService.updateParamater(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("update success", ToastStyle);
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }



    const [data, setData] = useState(null);
    const getData = () => {
        PaimentService.getParamater()
            .then(res => {
                setData(res.data.data);
            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData()
    }, [])
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const handalSubmit = (e) => {
        e.preventDefault();
        updateParameter(JSON.stringify(data))
    }


    const addN = (index) => {

        let tab = [...data.nature_envoi];
        tab.splice(index + 1, 0, "")

        setData({ ...data, nature_envoi: tab })
    }
    const delateN = (index) => {
        if (data.nature_envoi.length > 1)
            setData({
                ...data,
                nature_envoi: data.nature_envoi.filter((el, key) => {
                    return (key != index);
                })
            }
            )
    }
    return (
        data != null ?
            <Form onSubmit={handalSubmit}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3">

                                <InputGroup className="mt-1">
                                    <InputGroup.Text >1 EUR =</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={data.eur_to_dinar}
                                        onChange={(e) => setData({ ...data, eur_to_dinar: e.target.value })}
                                    />
                                    <InputGroup.Text >DZD</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3">
                                <Form.Label> Nature de l’envoi List </Form.Label>
                                {
                                    data.nature_envoi.map((el, key) => {
                                        return <div className="d-flex align-items-center mt-1">
                                            <Form.Control
                                                type="text"
                                                value={el}
                                                onChange={(e) => setData(
                                                    {
                                                        ...data,
                                                        nature_envoi: data.nature_envoi.map((item, index) => {
                                                            return key == index ? e.target.value : item
                                                        }
                                                        )
                                                    }
                                                )}
                                            />
                                            <span className="btn btn-default btn-sm ms-1 border" onClick={() => delateN(key)}><MdOutlineDelete /></span>
                                            <span className="btn btn-default btn-sm ms-1 border" onClick={() => addN(key)}><FiPlus /></span>
                                        </div>
                                    })
                                }
                            </Form.Group>
                        </Col>
                        <Col className="d-flex justify-content-end mt-3">
                            <Button type="submit" disabled={loding}>
                                {loding ? spanLoding : ""}
                                {
                                    intl.formatMessage({ id: 'SAVE' })
                                }
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form> : <Loader />
    );
}

export default GeneralParamater;