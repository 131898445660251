import { useState, useContext, useEffect } from 'react';
import { VisaTable } from '../Views/Table';
import { Container, Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import VisaService from '../Service/VisaService';
import Loader from '../Views/Loader';
import { StateContext } from "../Context/";
import { Toast, ToastStyle } from "../Views/Toast"
import { MdDone } from "react-icons/md"
import { useIntl } from 'react-intl';
import Modal from "../Views/Modal"
import PreviewImages from "../Colis/PreviewImages"
import styled from "styled-components"
import DeliveryAddress from "../Views/DeliveryAddress"
import ApiConfig from '../Config/ApiConfig';
const Upload = styled.div`
img{
    max-height:200px
}

`


const stylebtn = {
    minWidth: "100px"
}


function VisaListRequest(props) {
    const [data, setData] = useState(null);
    const [bank, setBank] = useState(null);
    const { user } = useContext(StateContext);
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState(null);
    const intl = useIntl();
    const [image, setImage] = useState(null)
    const [parameter, setParameter] = useState(null);
    const getData = () => {
        VisaService.getAll()
            .then(res => {
                setData(res.data.data);
                setBank(res.data.bank);
                setParameter(res.data.parameter)
            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData();
    }, []);

    const showModalF = (type, ob) => {
        setAction({ type, data: ob });
        
        if(["cart_arr","cart_send","cart_res"].includes(type))
        setAcc(
            {
                id_visa: ob.id_visa,
                new_state: (type=="cart_arr")*4+(type=="cart_send")*5+(type=="cart_res")*6
            }
        );
        // console.log((type=="cart_arr")*4+(type=="cart_send")*5+(type=="cart_res")*6)
        setShowModal(true)
    }
    const hideModallF = () => {
        setShowModal(false)
        setImage(null)
        setAcc(null)
        setAction(null)
    }
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )


    const handaleClick = () => {
        switch (action.type) {
            case "visa": ; break;
        }
    }
    const DisableBtn = () => {

        if (loding) return true;
        if (!action) return false;
        switch (action.type) {
            case "visa": return false;
        }
    }
    const states = [
        "En attente de paiement",
        "Paiement validé",
        "Paiement accépté",
        "Paiement rejeté",
        "Cartes reçues à l'entrepôt",
        intl.formatMessage({ id: 'CARTE_ROUT' }),
        intl.formatMessage({ id: 'CARTE_ARR' }),
    ]
    const [disable, setDisable] = useState(false);
    const handleSubmitAdd = (e) => {
        sendProof()
    }
    const sendProof = () => {
        let data = new FormData();
        data.append("id_visa", action.data.id_visa);
        data.append("image", image);
        setLoding(true)
        VisaService.sendProof(data)
            .then(res => {
                if (res.data.error == "") {
                    Toast.success("send success", ToastStyle);
                    getData()
                    hideModallF()
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }
                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }



    const [showModal2, setShowModal2] = useState(false);
    const [acc, setAcc] = useState(null)
    const showModalF2 = (bool) => {
        setAcc({ id_visa: action.data.id_visa, new_state: bool ? 2 : 3 })
        setShowModal2(true)
    }
    const hideModallF2 = () => {
        setShowModal2(false)
    }
    const changeAcc = () => {
        hideModallF2()
        setLoding(true)

        VisaService.changeState(acc)
            .then(res => {
                setLoding(false)
                hideModallF()
                if (res.data.error == "") {
                    getData()
                    Toast.success("change success", ToastStyle);
                }
                else
                    Toast.success(res.data.error, ToastStyle);

            }).catch(err => {
                setLoding(false)
                hideModallF()
                Toast.error(err.message, ToastStyle);
            })
    }
    const getDinar = (v) => {
        let x = v % 1000;
        let y = Math.ceil(x / 100) * 100 + Math.floor(v / 1000) * 1000;
        return y;
    }
    return (
        <>
            <Row>
                <Col>
                    {
                        data == null ? <Loader no={true} /> :
                            <VisaTable
                                user={user}
                                data={
                                    data.map((el) => {
                                        return {
                                            ...el,
                                            info:el,
                                            state: states[el.state],
                                            action: el
                                        }
                                    })
                                }
                                showModalF={showModalF}
                            />
                    }
                </Col>
            </Row>




            <Modal
                show={showModal}
                showbtn={false}
                title={
                    action && action.type == "pay" && "Uploade  reçu de versement " ||
                    action && action.type == "valid" && "Vérification du paiement" ||
                    action && action.type == "cart_arr" && "Êtes-vous sur ?" ||
                    action && action.type == "cart_send" && "Expédiées vers l’Algérie" ||
                    action && action.type == "cart_res" && "Avez-vous vraiment reçu la carte ?"
                }
                onHide={hideModallF}


            >



                {
                    action && action.type == "pay" && <>
                        <div>
                            <div>Veuillez envoyer l'argent sur ce compte :</div>
                            <hr />
                            <h1 className="text-center">
                                {action.data.visa_data.price} EUR / {parameter && (getDinar(parameter.eur_to_dinar * action.data.visa_data.price) + " DA")}
                            </h1>
                            <hr />
                            {
                                bank && <div class="p-4">
                                    <div className="">
                                        <img src={ApiConfig.urlBankImages + bank.logo} alt="" height="70" />
                                    </div>
                                    <div className="mt-2">
                                        {
                                            JSON.parse(bank.shipping_data).map((el, key) => {
                                                return <div key={key}>
                                                    <strong>{el.item} : </strong>
                                                    {el.value}
                                                </div>
                                            })
                                        }
                                    </div>

                                </div>
                            }
                            <Form.Group controlId="logo">
                                <Form.Label>Preuve photo</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => { setImage(e.target.files[0]); setDisable(true); setTimeout(() => { setDisable(false) }, 10); }}
                                />
                                {
                                    image && <Upload className="mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                        <PreviewImages file={image} />
                                    </Upload>
                                }
                            </Form.Group>
                        </div>

                    </>
                }


                {
                    action && action.type == "valid" && <>
                        <img src={ApiConfig.urlVisaPay + action.data.image_confirm} alt="" width="100%" />
                    </>
                }
                {
                    action && action.type == "cart_send" && <>
                        <DeliveryAddress data={action.data.address}/>
                    </>
                }

                <div className='d-flex justify-content-end mt-2'>
                    {
                        action && action.type == "valid" && (user.rol == 1 || user.rol == 2) && <>
                            <button className='btn btn-danger me-2' onClick={() => showModalF2(false)}>Rejeté</button>
                            <button className='btn btn-success me-2' onClick={() => showModalF2(true)}>Accépté</button>
                            <div className="flex-grow-1"></div>
                            <button className='btn btn-danger' style={stylebtn} onClick={hideModallF}>
                                {intl.formatMessage({ id: 'CLOSE' })}
                            </button>
                        </>
                    }
                    {
                        action && action.type == "pay" && <div className='d-flex justify-content-end mt-2'>
                            <button className='btn btn-success me-1' style={stylebtn} disabled={loding || disable || image == null} onClick={handleSubmitAdd}>
                                {loding ? spanLoding : ""}

                                upload
                            </button>
                            <button className='btn btn-danger' style={stylebtn} onClick={hideModallF}>
                                {intl.formatMessage({ id: 'CLOSE' })}
                            </button>
                        </div>
                    }
                    {
                        action && (action.type == "cart_arr" || action.type == "cart_send" || action.type == "cart_res") && <>
                            <button className='btn btn-success me-1' style={stylebtn} onClick={() => changeAcc()} >
                                {loding ? spanLoding : ""}
                                {intl.formatMessage({ id: 'YES' })}
                            </button>
                            <button className='btn btn-danger' style={stylebtn} onClick={() => hideModallF()}>

                                {intl.formatMessage({ id: 'NO' })}
                            </button>
                        </>
                    }

                </div>
            </Modal>


            <Modal
                show={showModal2}
                showbtn={false}
                title={"Êtes-vous sur ?"}
                onHide={hideModallF2}

            >




                <div className='d-flex justify-content-end mt-2'>
                    <button className='btn btn-success me-1' style={stylebtn} onClick={() => changeAcc()} >
                        {loding ? spanLoding : ""}
                        {intl.formatMessage({ id: 'YES' })}
                    </button>
                    <button className='btn btn-danger' style={stylebtn} onClick={() => hideModallF2()}>

                        {intl.formatMessage({ id: 'NO' })}
                    </button>
                </div>
            </Modal>

        </>
    );
}

export default VisaListRequest;