import {useState,useContext,useEffect} from 'react';
import { StoreTable } from '../Views/Table';
import { Container,Col,Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import StoreService from '../Service/StoreService';
import Loader from '../Views/Loader';
import {StateContext} from "../Context/";
import {Toast,ToastStyle} from "../Views/Toast"


function StoreList(props) {
    const [data,setData]=useState(null);
    const [country,setCountry]=useState(null);
    const [transport,setTransport]=useState(null);
    const navigate=useNavigate();

    const {user } = useContext(StateContext);
    const getData=()=>{
        StoreService.getAll(user)
        .then(res=>{
            setData(res.data.data);
            setCountry(res.data.country);
            setTransport(res.data.transport);
        }).catch(err=>{
            setData([])
            Toast.error(err.message,ToastStyle);
        })
    }
  
    useEffect(()=>{
        getData();
    },[])
    const changeStateStore=(id,state)=>{
        StoreService.changeStateStore(id,state==0?1:0)
        .then(res=>{
            if(res.data.error==""){
                getData()
            }else{
                Toast.error(res.data.error,ToastStyle);
            }

        }).catch(err=>{
            Toast.error(err.message,ToastStyle);
        })
    }
    return (
        <>
            {
                user.rol==2&&<Row className="p-2">
                    <Col className="d-flex justify-content-end">
                        <button className="btn btn-primary" 
                            onClick={()=>{
                                navigate("/store/add",{state:{
                                    country
                                          
                                }})
                            }}>
                            Ajouter
                        </button>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                {
                    data===null ?  <Loader no={true} /> :
                    <StoreTable
                    changeStateStore={changeStateStore}
                    editable={user.rol==2}
                    data={data.map((el,key)=>{
                        return {
                            ...el,
                            action:el
                        }
                    })}
                    country={country}
                    transport={transport}
                    />
                }
                </Col>
            </Row>

  
        </>
    );
}

export default StoreList;