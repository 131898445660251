import { useState, useEffect, useContext } from 'react';

import { Col, Row, Form, Container, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import VisaService from "../Service/VisaService"
import { Toast, ToastStyle } from "../Views/Toast"
import { useIntl } from 'react-intl';
import ShippingService from "../Service/ShippingService"
import ApiConfig from '../Config/ApiConfig';
import { StateContext } from "../Context/";
import Loader from '../Views/Loader';
import Select from 'react-select';
import Stepper from 'react-stepper-horizontal';
import styled from 'styled-components';
const DivC = styled.div`
min-height:400px;
.bt{
    min-width: 100px;
}
.list-group-item{
    border:none
}
`
const storeShema = (c) => Yup.object().shape({

    fname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup
        .string()
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, {
            message: "Invalid number +12345678900"
        })
        .required('Required'),
});

function VisaRequest({ update }) {
    const [loding, setLoding] = useState(false);
    const location = useLocation()
    const [step, setStep] = useState(0);
    const datar = location.state;


    const clear = (actions) => {
        setTimeout(() => {

            actions.setSubmitting(false)
            actions.resetForm();
            setBank(null);
            setStep(3)
        }, 200);

    }

    const intl = useIntl();
    const addVisa = (ob, actions) => {

        setLoding(true)
        VisaService.addVisa(ob)
            .then(res => {

                if (res.data.error == "") {
                    Toast.success("Add success", ToastStyle);
                    clear(actions)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }


    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const { user } = useContext(StateContext);
    const [bank, setBank] = useState(null);
    const [data, setData] = useState(null);
    const [isSend, setIsSend] = useState(false);
    const [parameter, setParameter] = useState(null);
    const [allAddress, setAllAddress] = useState([]);

    const [country, setCountry] = useState(null);
    const getData = () => {
        VisaService.visaInfo()
            .then(res => {
                let bs = res.data.data.banks
                setData(bs.map(el => {
                    return { ...el, visa_data: JSON.parse(el.visa_data) }
                }));
                setParameter(res.data.data.parameter)

                setAllAddress(res.data.data.address.map((el, key) => {
                    return {
                        label: el.name + " , " + el.country + " , " + el.state + " , " + el.city + " , " + el.zip_code + " , " + el.address_line1,
                        value: el
                    }
                }))

            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData()
    }, [])
    const getDinar = (v) => {
        let x = v % 1000;
        let y = Math.ceil(x / 100) * 100 + Math.floor(v / 1000) * 1000;
        return y;
    }
    const content =
        <DivC>
            {/* <Row className="p-2">
                <Col className="">
                    <h4>
                        Demander une carte visa
                    </h4>
                    <hr />
                </Col>
            </Row> */}
            <Row className="mb-2">
                <Col>
                <div className="mb-5">
                    <Stepper
                        steps={[
                            { title: 'Type de Carte ' },
                            { title: 'Renseignements personnels' },
                            { title: 'Sauvegarder' },
                        ]}
                        size={50}
                        activeStep={step}
                        completeColor="#29d155 "
                        completeTitleColor="#444"

                        completeBarColor="#29d155"
                        defaultBarColor="#AAA"
                        defaultBarStyle="dotted"
                        activeColor="#616161"
                        circleFontSize={24}
                    />
                    </div>
                </Col>
            </Row>

            <Formik
                initialValues={{
                    fname: user.fname,
                    lname: user.lname,
                    email: user.email,
                    phone: user.phone
                }}

                validationSchema={storeShema()}
                onSubmit={(values, actions) => {
                    let dataVisa = {
                        id_user: user.id,
                        id_au: country.value.id_au,
                        id_bank: bank.id_bank,
                        fname: values.fname,
                        lname: values.lname,
                        email: values.email,
                        phone: values.phone,
                    };
                    addVisa(dataVisa, actions);
                }}
            >
                {
                    (
                        {
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors
                        }
                    ) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Container>
                                {
                                    step == 0 &&
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group controlId="">
                                                <ul className="list-group">
                                                    {
                                                        data && data.map((el, key) => {
                                                            return <li className="list-group-item d-flex align-items-center p-3" style={{ border: "none" }}>

                                                                <input type="radio" className="form-check-input me-3" name="bank" checked={bank && bank.id_bank == el.id_bank} onChange={() => { setBank(data[key]); }} />

                                                                <img src={ApiConfig.urlBankImages + el.logo} alt={el.name} height="30" className="ms-3" />
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </Form.Group>

                                        </Col>
                                        <Col lg={6} sm={12}>
                                            {
                                                bank && <div className="mt-3">
                                                    <div>
                                                        Délai  de livraison : <strong>{bank.visa_data.delivery_time}</strong> jours
                                                    </div>
                                                    <div>
                                                        Prix de livraison :
                                                    </div>
                                                    <hr />
                                                    <h1 className="text-center">
                                                        {bank.visa_data.price} EUR / {parameter && (getDinar(parameter.eur_to_dinar * bank.visa_data.price) + " DA")}
                                                    </h1>
                                                    <hr />
                                                </div>
                                            }
                                        </Col>
                                        <Col sm={12} className='mt-4 d-flex justify-content-end'>
                                            <button class="btn btn-primary ms-3 bt" onClick={() => setStep(1)} disabled={bank == null}>Next</button>
                                        </Col>
                                    </Row>

                                }
                                {step == 1 &&
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group controlId="fname">
                                                <Form.Label>{intl.formatMessage({ id: 'FNAME' })}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fname"
                                                    value={values.fname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.fname}
                                                    disabled={bank == null}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fname}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group controlId="lname">
                                                <Form.Label>{intl.formatMessage({ id: 'LNAME' })}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="lname"
                                                    value={values.lname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.lname}
                                                    disabled={bank == null}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lname}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group controlId="email">
                                                <Form.Label>{intl.formatMessage({ id: 'EMAIL' })}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    disabled={bank == null}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group controlId="phone">
                                                <Form.Label>{intl.formatMessage({ id: 'PHONE' })}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.phone}
                                                    disabled={bank == null}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phone}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>

                                            <Form.Group className="mb-3" controlId="to">
                                                <Form.Label>Address de livraison</Form.Label>
                                                <Select
                                                    value={country}
                                                    defaultValue={country}
                                                    onChange={setCountry}
                                                    isSearchable={true}
                                                    options={allAddress}
                                                    className="mb-2"
                                                />

                                                {
                                                    allAddress.length == 0 && <div>Veuillez ajouter l'adresse de livraison
                                                        <Link to={"/profile"}> ici
                                                        </Link>
                                                    </div>
                                                }

                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} className='mt-4 d-flex justify-content-end'>
                                            <button class="btn btn-primary bt" onClick={() => setStep(0)}>Previous</button>
                                            <button class="btn btn-primary ms-3 bt" onClick={() => setStep(2)} disabled={
                                                (values.fname.length == "" || values.lname.length == "" || values.email.length == "" || values.phone.length == "") ||
                                                !!errors.phone || !!errors.email || !!errors.fname || !!errors.lname ||
                                                country == null}>Next</button>
                                        </Col>
                                    </Row>
                                }
                                {step == 2 && <Row>
                                    <Col className="d-flex justify-content-end mt-4">
                                        <button class="btn btn-primary bt  me-3" onClick={() => setStep(1)}>Previous</button>
                                        <Button type="submit" disabled={loding || bank == null || country == null}>
                                            {loding ? spanLoding : ""}
                                            {
                                                update ? intl.formatMessage({ id: 'SAVE' }) : intl.formatMessage({ id: 'SUBMIT' })
                                            }
                                        </Button>
                                    </Col>
                                </Row>}
                                {
                                    step == 3 && <div class="alert alert-success p-2">
                                        <strong>Envoyé avec succès : </strong>Votre commande est en attente de paiement
                                    </div>
                                }

                            </Container>
                        </Form>

                    )}

            </Formik>

        </DivC>;

    return data == null ? <Loader /> : content
}


export default VisaRequest;