import req from "../Config/http-common";
import ApiConfig from "../Config/ApiConfig";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))


const addPaiment=(ob)=>{
    ob.append("session",getSession());
    return req.httpUpload.post("/paiment/",ob);
}
const updateParamater=(data)=>{
    return req.http3.post("/paiment/",{
        data,
        session:getSession(),
        update_parameter:true
    });
}
const getParamater=()=>{
    
    return req.http3.post("/paiment/",{
        session:getSession(),
        get_parameter:true
    });
}
const PaimentService = {
    addPaiment,
    updateParamater,
    getParamater
};

export default PaimentService;