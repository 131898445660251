import { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import ApiConfig from '../Config/ApiConfig';
import styled from 'styled-components';
import Modal from "../Views/Modal"
import { useIntl } from 'react-intl';

const DivC = styled.div`
    p{padding:0;margin:0}
    .w{width:100%!important}
  
`
const stylebtn = {
    minWidth: "100px"
}
function PaimentView(props) {
    const { colis } = props;
    const duan = JSON.parse(colis.paiment.duan_data);
    const insurance = JSON.parse(colis.paiment.insurance);

    const pv=parseFloat(colis.width)*parseFloat(colis.height)*parseFloat(colis.length)/5000;
    const w=parseFloat(colis.weight)>pv?parseFloat(colis.weight):pv;

    var calc=props.colis.paiment.shipping
    for(let i=0;i!=calc.length;i++){
        if(w > parseFloat(calc[i].from) && w <= parseFloat(calc[i].to)){
            calc=calc[i];
            break;
        }
    }
    
    const price=parseFloat(parseFloat(calc.price) + parseFloat(insurance.value?insurance.value:0));

    const getDinar = (v) => {
        let x = v % 1000;
        let y = Math.ceil(x / 100) * 100 + Math.floor(v / 1000) * 1000;
        return y;
    }

    const address = colis.paiment.address;
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const showModalF = () => {


        setShowModal(true)
    }
    const hideModallF = () => {
        setShowModal(false)
    }
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    return (
        <DivC className='table-responsive'>
            <table className='table table-bordered'>
                <tbody>
                    <tr>
                        <td><strong>Adresse de livraison</strong></td>
                        <td className="p-0 text-center">
                            <table className='w'>
                                <tr className='border-bottom'>
                                    <td colSpan={2}>
                                        {
                                            address.country + " , " +
                                            address.state + " , " +
                                            address.city + " , " +
                                            address.address_line1
                                        }
                                    </td>
                                </tr>
                                <tr className='border-bottom p-0'>
                                    <td className='border-end p-0'><p>Code Postal</p></td>
                                    <td className='p-0'><p>{address.zip_code}</p></td>
                                </tr>
                                <tr className='border-bottom p-0'>
                                    <td className='border-end p-0'><p>Email</p></td>
                                    <td className='p-0'><p>{address.email}</p></td>
                                </tr>
                                <tr>
                                    <td className=' border-end p-0'><p>Phone</p></td>
                                    <td className='p-0'><p>{address.phone}</p></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Mode de livraison</strong></td>
                        <td>
                            <img src={ApiConfig.urlTransportCompani + colis.paiment.tc.logo} alt={colis.paiment.tc.name} height="30" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <strong>Nature de l’envoi</strong>
                        </td>
                        <td>
                            {duan.nature_envoi}
                        </td>
                    </tr>

                    <tr>
                        <td >
                            <strong>Contenu</strong>
                        </td>
                        <td className="p-0 text-center">
                            <table className='w'>
                                <tr className='border-bottom' >
                                    <td className=' border-end'><p>Description </p></td>
                                    <td className=' border-end'><p>Poids unitaire en Kg </p></td>
                                    <td className=' border-end'><p>Quantité </p></td>
                                    <td><p>Valeur unitaire HT en EUR </p></td>
                                </tr>
                                {
                                    duan.articles.map((el, key) => {
                                        let classe = (key != (duan.articles.length - 1)) ? "border-bottom" : ""
                                        return <>

                                            <tr className={classe}>
                                                <td className='p-0 text-center border-end'><p>{el.desc}</p></td>
                                                <td className='p-0 text-center border-end'><p>{el.weight}</p></td>
                                                <td className='p-0 text-center border-end'><p>{el.quantity}</p></td>
                                                <td className='p-0 text-center'><p>{el.price}</p></td>
                                            </tr>
                                        </>
                                    })
                                }
                            </table>

                        </td>
                    </tr>
                    <tr>
                        <td><strong>Assurance</strong></td>
                        <td>
                            {insurance.price?insurance.price+"EUR":"Assurance non souscrite"} 
                        </td>
                    </tr>
                    
                    <tr>
                        <td><strong>Mode de paiement</strong></td>
                        <td>
                            <img src={ApiConfig.urlBankImages + colis.paiment.bank.logo} alt={colis.paiment.bank.name} height="30" />
                        </td>
                    </tr>

                    <tr>
                        <td><strong>Prix de paiement</strong></td>
                        <td>
                           {price} EUR / 
                           {getDinar(props.param.eur_to_dinar*price)}  DZD
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2" className='text-center'><strong>Preuve de paiement</strong></td>
                    </tr>
                    <tr>
                        <td colspan="2" className='p-0 '>
                           
                                <img  src={ApiConfig.urlPaiment + colis.paiment.image_confirm} alt="" width="100%" />
                            
                            
                        </td>
                    </tr>
                </tbody>
            </table>
           






            
        </DivC>
    );
}

export default PaimentView;