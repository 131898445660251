import {useState,useContext} from 'react';
import {Col,Row ,Form,Container,Button} from 'react-bootstrap';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import {Toast,ToastStyle} from "../Views/Toast"
import {DispatchContext,updateUserContext,StateContext} from "../Context/"
import UserService from "../Service/UserService"
import { useIntl } from 'react-intl';

const userShema = Yup.object().shape({

    fname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),

    birth_date: Yup.date(),
    gender: Yup.number(''),
    email: Yup.string().email('Invalid email').required('Required'),
    phone:Yup
    .string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, {
      message: "Invalid number +12345678900"
    }),
});
function General(props) {
    const [loding,setLoding]=useState(false);
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const dispatch = useContext(DispatchContext);

    const { user } = useContext(StateContext);

    const updateUser=(ob)=>{
        
        setLoding(true)
        UserService.updateUser({...user,...ob})
        .then(res=>{
            
            if(res.data.error===""){
                Toast.success("update success",ToastStyle);
                updateUserContext(dispatch,{...user,...ob});
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const intl = useIntl();

    return (
        <Container>
            <Formik
                initialValues={{
                    fname: user.fname,
                    lname:user.lname,
                    birth_date:user.birth_date,
                    gender:user.gender,
                    email:user.email,
                    phone:user.phone
                }}

                validationSchema={userShema}
                onSubmit={(values,actions) => {
                    updateUser(values);
                }}
            >
                {
                    (
                        { 
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors 
                        }
                    ) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Container className="p-0 ">
                            <Row className="m-0">
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="">
                                        <Form.Label>{intl.formatMessage({ id: 'ID_EXPED' })} </Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled={true}
                                            value={user.id_exped}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="fname">
                                        <Form.Label>{intl.formatMessage({ id: 'FNAME' })} </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="fname"
                                            value={values.fname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.fname}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="lname">
                                        <Form.Label>{intl.formatMessage({ id: 'LNAME' })} </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lname"
                                            value={values.lname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.lname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lname}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="birth_date">
                                        <Form.Label>{intl.formatMessage({ id: 'BIRTH_DATE' })} </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="birth_date"
                                            value={values.birth_date}
                                            onChange={handleChange}
                                            isInvalid={!!errors.birth_date}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.birth_date}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="gender">
                                        <Form.Label>{intl.formatMessage({ id: 'GENDER' })} </Form.Label>
                                        
                                        <Form.Select 
                                            name="gender"
                                            onChange={handleChange}
                                            value={values.gender}
                                            isInvalid={!!errors.gender}
                                        >
                                            <option value='2'>{intl.formatMessage({ id: 'SELECT_GENDER' })} </option>
                                            <option value="0">{intl.formatMessage({ id: 'MALE' })} </option>
                                            <option value="1">{intl.formatMessage({ id: 'FEMALE' })} </option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gender}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="email">
                                        <Form.Label>{intl.formatMessage({ id: 'EMAIL' })} </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>{intl.formatMessage({ id: 'PHONE' })} </Form.Label>
                                        <Form.Control
                                            type="tel"
                                            value={values.phone}
                                            onChange={handleChange}
                                            isInvalid={!!errors.phone}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end mt-3">
                                    <Button type="submit" disabled={loding}>
                                        {loding ? spanLoding:""}
                                        {intl.formatMessage({ id: 'SAVE' })} 
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default General;