
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import lang from './lang';


import Exped from "./Components/Exped"
import Login from "./Components/Login"
import Home from "./Components/Home"
import Register from "./Components/Register"

import Colis from "./Components/Colis"
import Store from "./Components/Store"
import Users from "./Components/Users"
import Tools from "./Components/Tools"
import Profile from "./Components/Profile"
import Shipping from "./Components/Shipping/"
import Visa from "./Components/Visa"
import Calculator from './Components/Calculator/';
import Notifications from './Components/Notifications';
import { useJsApiLoader,useLoadScript } from '@react-google-maps/api';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import Layout from "./Components/Layout"
import ExpedProvider from "./Components/Context/"
import Loader from "./Components/Views/Loader"
import './assets/style/sidebar.scss';
import './index.css';
import ApiConfig from './Components/Config/ApiConfig';

function App() {
  const [locale, setLocale] = useState('fr');
  // const libraries=["places"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: ApiConfig.googleMapsApiKey
  })
  return (isLoaded?
  <ExpedProvider>
     
      <IntlProvider locale={locale} messages={lang[locale]}>
        <Router>
          <Routes> 
            <Route path="/" element={<Exped />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/calc" element={<Calculator access={true}/>}/>
              
            <Route path="/calc/notes" />

            <Route path="/colis" element={<Layout><Colis /></Layout>}>
              <Route path="/colis/add" />
              <Route path="/colis/edit" />
              <Route path="/colis/pay" />
              <Route path="/colis/edit" />
              <Route path="/colis/a" />
              <Route path="/colis/b" />
              <Route path="/colis/c" />
              <Route path="/colis/d" />
              <Route path="/colis/e" />
              <Route path="/colis/f" />
              <Route path="/colis/g" />
              <Route path="/colis/h" />
              <Route path="/colis/i" />
              <Route path="/colis/calculator" />
              <Route path="/colis/how_to" />
            </Route>
            <Route path="/notifications" element={<Layout><Notifications /></Layout>}/>
            
            <Route path="/calculator" element={<Layout><Calculator /></Layout>}>
              <Route path="/calculator/notes" />
            </Route>

            <Route path="/store" element={<Layout><Store /></Layout>}>
              <Route path="/store/add" />
              <Route path="/store/edit" />
              <Route path="/store/settings" />
            </Route>

            <Route path="/visa" element={<Layout><Visa /></Layout>}>
              <Route path="/visa/request" />
              <Route path="/visa/trac" />
              <Route path="/visa/how_to" />
            </Route>

            <Route path="/shipping" element={<Layout><Shipping /></Layout>}>
              <Route path="/shipping/add" />
              <Route path="/shipping/edit" />
              <Route path="/shipping/calculator" />
              <Route path="/shipping/how_to" />
            </Route>

            <Route path="/users" element={<Layout><Users /></Layout>}/>
            <Route path="/tools" element={<Layout><Tools /></Layout>}>
              <Route path="/tools/bank/add" />
              <Route path="/tools/bank/edit" />
              <Route path="/tools/transport_companies/add" />
              <Route path="/tools/transport_companies/edit" />
            </Route>
            <Route path="/profile" element={<Layout><Profile /></Layout>}>
              <Route path="/profile/delivery/add" />
              <Route path="/profile/delivery/edit" />
            </Route>
            
            <Route path="/dashboard" element={<Layout><Home/></Layout>}/>



          </Routes>
        </Router>
      </IntlProvider>
    </ExpedProvider>
    :<><Loader/></>
  );
}

export default React.memo(App);
