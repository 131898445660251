import {useState,useContext,useEffect} from 'react';

import { Container,Col,Row } from 'react-bootstrap';
import { Link ,useNavigate} from 'react-router-dom';
import Loader from '../Views/Loader';
import {StateContext} from "../Context/";
import {Toast,ToastStyle} from "../Views/Toast"
import ShippingService from "../Service/ShippingService"
import { useIntl } from 'react-intl';
import ApiConfig from '../Config/ApiConfig';
import styled from "styled-components"
import {MdEdit} from "react-icons/md"
const DivC=styled.div`

th{
    background-color: inherit!important;;
    font-weight: 500;
}
td{
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.bg-rr{
    /* background-color: #ff3f39; */
}
`


function TransportCompaniList(props) {
    const [data,setData]=useState(null);
    const {user } = useContext(StateContext);
    const navigate=useNavigate()
    const getData=()=>{
        ShippingService.getTranportCompani()
        .then(res=>{
            setData(res.data.data.map(el=>{
                return {...el,insurance:JSON.parse(el.insurance)}
            }));
        }).catch(err=>{
            setData([])
            Toast.error(err.message,ToastStyle);
        })
    }
    useEffect(()=>{
      getData()
    },[])
    const intl = useIntl();
    return (
        data?<>
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <button onClick={()=>navigate("/tools/transport_companies/add",{state:{user}})} className="btn btn-primary">
                    {intl.formatMessage({ id: 'ADD' })}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col className="container p-0">
                    <DivC className='table-responsive-lg'>
                        <table className='table table-borderless'>
                            <tbody>
                                <tr>
                                    <td>{intl.formatMessage({ id: 'NAME' })}</td>
                                    <td>{intl.formatMessage({ id: 'LOGO' })}</td>
                                    <td>{intl.formatMessage({ id: 'ACTION' })}</td>
                                    <td>{intl.formatMessage({ id: 'EDIT_DATE' })}</td>
                                    <td>{intl.formatMessage({ id: 'ADD_DATE' })}</td>
                                    </tr>
                                {
                                    data&&data.map((el,key)=>{
                                        return <tr>
                                            <td>{el.name}</td>
                                            <td><img src={ApiConfig.urlTransportCompani+el.logo} height="30" alt="" /></td>
                                            <td>
                                                <button className="btn btn-success btn-sm"
                                                    onClick={()=>{
                                                        navigate("/tools/transport_companies/edit",{state:el})
                                                    }}><MdEdit/></button></td>
                                            <td><small>{el.edit_date}</small></td>
                                            <td><small>{el.add_date}</small></td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </DivC>  
                </Col>
            </Row>
        </>:
        <Loader no={true} />
    );
}

export default TransportCompaniList;