import React, { useState, useEffect } from 'react';

import { Col, Row, Form, Container, Button, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import { MdOutlineDelete } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import * as Yup from 'yup';
import StoreService from "../Service/StoreService"
import { Toast, ToastStyle } from "../Views/Toast"
import Select from 'react-select';
import { useIntl } from 'react-intl';
import ApiConfig from '../Config/ApiConfig';
import { FaDollarSign } from "react-icons/fa"
import Modal from "../Views/Modal"
const storeShema = Yup.object().shape({


});

function Settins() {
    const [loding, setLoding] = useState(false);
    const location = useLocation()
    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState(false);
    const showModalF = (dm) => {

        setDataModal(dm);
        setShowModal(true)
    }
    const hideModallF = () => {
        setShowModal(false)
        setDataModal(null);
    }
    const datar = location.state;

    const [countryData, setCountryData] = useState(
        datar.country.map((el, value) => {
            return { label: el.name, value: el.name, code: el.shortname, transport: [] }
        })
    )
    const [storePrice, setStorePrice] = useState(datar.price_data.map((el, key) => {
        return { label: el.name, value: el.name, code: el.code, transport: el.transport}
    }))

    const intl = useIntl();



    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )

    const addColisPrice = () => {
        let ob = {
            id_store: datar.id_store,
            price_data: JSON.stringify(storePrice.map(
                (el) => {
                    return {
                        name: el.label,
                        code: el.code,
                        transport: el.transport
                    }
                }
            ))
        }
        setLoding(true)
        StoreService.updatePriceData(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("update success", ToastStyle);
                    // clear(actions)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }
                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }

    const addCalc = (countryindex,trindex, calcindex) => {
        
        let sp=[...storePrice]
        let tab=[...sp[countryindex].transport[trindex].calc];
        tab.splice(calcindex + 1, 0, { from: 0, to: 0, price: 0 });
        sp[countryindex].transport[trindex].calc=tab;
        setStorePrice(
            sp
        )
    }

    const deleteCalc = (countryindex,trindex, calcindex) => {
        let sp=[...storePrice]
        let tab=[...sp[countryindex].transport[trindex].calc.filter((item, index) => index != calcindex)];
        if(tab.length>0) 
            sp[countryindex].transport[trindex].calc=tab;
        setStorePrice(
            sp
        )
    }
    const updateCalcValue = (value, countryindex,trindex, calcindex, label) => {
        let sp=[...storePrice]
        let tab=[...sp[countryindex].transport[trindex].calc.map((el,key)=>{
            if(key==calcindex){
                let t = { ...el };
                t[label] = value;
                return t
            }
            return el;
        })]
        sp[countryindex].transport[trindex].calc=tab;
        setStorePrice(
            sp
        )
    }


    const addTransport = (key, id, ex) => {

        setStorePrice(
            storePrice.map((el, index) => {
                if (key == index) {
                    return { ...el, transport: [...el.transport, { id, duration_from: "", duration_to: "", calc: [{ from: 0, to: 0, price: 0 }] }] }
                } else {
                    return el
                }
            })
        )
    }
    const deleteTransport = (key, pos) => {
        setStorePrice(
            storePrice.map((el, index) => {

                if (key == index) {
                    return { ...el, transport: el.transport.filter((o, i) => i != pos) }
                } else {
                    return el
                }
            })
        )
    }
    const updateTranportVal = (key1, key2, value, label) => {
        setStorePrice(
            storePrice.map((el, index) => {
                if (key1 == index) {
                    return {
                        ...el,
                        transport: el.transport.map(
                            (tr, ind) => {
                                if (ind == key2) {
                                    let ob = { ...tr };
                                    ob[label] = value;
                                    console.log(ob)
                                    return ob;
                                }
                                console.log(tr)
                                return tr;
                            }
                        )
                    }
                } else {
                    return el
                }
            })
        )
    }


    return (
        <>
            <Row className="p-2">
                <Col sm={12} className="d-flex justify-content-end">
                    <Link to="/store" className="btn btn-default">
                        {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
                <Col sm={12}>
                    <h3>Le prix du poids des colis par pays :</h3>
                </Col>
            </Row>
            <Formik

                initialValues={{ name: "" }}
                validationSchema={storeShema}
                onSubmit={(values, actions) => {
                    // return
                    addColisPrice()

                }}
            >
                {
                    (
                        {
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors
                        }
                    ) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Pays</Form.Label>
                                        <Select
                                            value={storePrice}
                                            defaultValue={storePrice}
                                            onChange={setStorePrice}
                                            // onChange={update_currency}
                                            isMulti={true}
                                            isSearchable={true}

                                            options={countryData}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">

                                {
                                    storePrice.map((el, key) => {
                                        return <Col lg={6} className=" ">
                                            <div className='m-1 p-2 bg-light rounded-1'>
                                            <h4 className="border-bottom pb-2 ">{el.label}</h4>
                                            <Form.Group controlId="name">
                                                <Form.Label>Mode de livraison</Form.Label>
                                                {
                                                    datar.transport.map((el2, index) => {
                                                        let ex = false;
                                                        let pos = -1;
                                                        for (let i = 0; i != el.transport.length; i++)
                                                            if (el.transport[i].id == el2.id_tc) {
                                                                pos = i;
                                                                ex = true;
                                                                break
                                                            }

                                                        return <div className='mb-4'>
                                                            <div className='d-flex align-items-center'>
                                                                <Form.Check
                                                                    name="group1"
                                                                    type="checkbox"
                                                                    checked={ex}
                                                                    onChange={() => { if (!ex) addTransport(key, el2.id_tc, ex) }}
                                                                />
                                                                <img className="ms-2" height="25" src={ApiConfig.urlTransportCompani + el2.logo} alt="" />
                                                            </div>
                                                            {
                                                                ex && <>
                                                                    <Form.Group controlId="">
                                                                        <Form.Label>Heure de livraison</Form.Label>
                                                                        <div className="d-flex">
                                                                            <InputGroup size="sm" >
                                                                                <InputGroup.Text >De </InputGroup.Text>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    value={storePrice[key].transport[pos].duration_from}
                                                                                    onChange={(e) => updateTranportVal(key, pos, e.target.value, "duration_from")}
                                                                                />
                                                                                <InputGroup.Text >Jour</InputGroup.Text>
                                                                            </InputGroup>
                                                                            <InputGroup size="sm" className="ms-1">
                                                                                <InputGroup.Text  >à </InputGroup.Text>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    value={storePrice[key].transport[pos].duration_to}
                                                                                    onChange={(e) => updateTranportVal(key, pos, e.target.value, "duration_to")}
                                                                                />
                                                                                <InputGroup.Text >Jour</InputGroup.Text>
                                                                            </InputGroup>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end p-2">
                                                                            <span className="btn btn-danger btn-sm me-1" onClick={() => deleteTransport(key, pos)}><MdOutlineDelete /></span>
                                                                            <span className="btn btn-warning btn-sm" onClick={() => showModalF({ title: el.label, numC: key, numT: pos })}><FaDollarSign /></span>

                                                                        </div>
                                                                    </Form.Group>
                                                                </>
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </Form.Group>
                                            </div>
                                        </Col>
                                    })
                                }


                            </Row>
                           
                            <Row>
                                <Col className="d-flex justify-content-end mt-3">
                                    <Button type="submit" disabled={loding}>
                                        {loding ? spanLoding : ""}
                                        {
                                            intl.formatMessage({ id: 'SAVE' })
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                    )}

            </Formik>
            <Modal
                show={showModal}
                showbtn={true}
                title={dataModal && dataModal.title}
                // fullscreen
                onHide={hideModallF}
            >
                <div className='d-flex justify-content-center'>
                <Container style={{ width: "100%",maxWidth:"500px" }}>
                    {
                        dataModal&&storePrice[dataModal.numC].transport[dataModal.numT].calc.map((item, index) => {
                            return <Row className="m-0 p-0 mt-4">
                                <Col className='m-0 p-0 me-1'>
                                    <InputGroup size="sm" >
                                        <InputGroup.Text className="bg-light" >De </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={item.from}
                                            onChange={(e) =>updateCalcValue(e.target.value,dataModal.numC,dataModal.numT, index, "from") }
                                        />
                                        <InputGroup.Text className="bg-light" >Kg</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col className='m-0 p-0'>
                                    <InputGroup size="sm">
                                        <InputGroup.Text className="bg-light">à</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={item.to}
                                            onChange={(e) =>updateCalcValue(e.target.value,dataModal.numC,dataModal.numT, index, "to") }
                                        />
                                        <InputGroup.Text className="bg-light">Kg</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col sm={12} className='m-0 mt-1 p-0 d-flex align-items-center'>
                                    <InputGroup size="sm">
                                        <InputGroup.Text >Prix</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={item.price}
                                            onChange={(e) =>updateCalcValue(e.target.value,dataModal.numC,dataModal.numT, index, "price") }
                                        />
                                        <InputGroup.Text>EUR</InputGroup.Text>
                                    </InputGroup>
                                    <span className="btn btn-danger btn-sm ms-1 " 
                                    onClick={() => deleteCalc(dataModal.numC,dataModal.numT, index)}
                                    ><MdOutlineDelete  
                                    /></span>
                                    <span className="btn btn-success btn-sm ms-1  " 
                                    onClick={() => addCalc(dataModal.numC,dataModal.numT, index)}
                                    ><FiPlus /></span>
                                </Col>
                            </Row>
                        })
                    }
                </Container>
                </div>
                
            </Modal>
        </>
    );
}

export default Settins;