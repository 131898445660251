import { useState,useContext ,useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import { useIntl } from 'react-intl';
import {FaBars} from "react-icons/fa"
import {BiMenuAltLeft,BiMenuAltRight,BiLogIn} from "react-icons/bi"
import styled from 'styled-components';
import {DispatchContext,logout,StateContext} from "../Context/"
import UserService from '../Service/UserService';
import {IoNotificationsOutline,IoNotifications} from "react-icons/io5"
import {GrMail} from "react-icons/gr"
import { useDetectClickOutside } from 'react-detect-click-outside';
const Header=styled.header`
height:60px;
background-color: #FFF;
box-shadow: 0 0 5px 0 #0001;
border-bottom-color: #DADADA!important;
margin:6px;
position: relative;
.btn-toggle,.btn-collaps{
  font-size: 20px;

  border-radius:3px;
  color: #222;
} 
.btn-collaps{
  font-size:28px;
}
.btn-logout{
  font-size:22px
}
.cy{
  width: 40px;height:40px;
  border-radius:50%;
  font-size: 20px;
  background: var( --global-bg-color);
  color:#FFF;
  overflow: hidden;
}
.cy img{
  width: 100%;
  height:100%;

}
.fname p{
  font-size: 12px;
}
.not{
  position: relative;
  cursor: pointer;
}
.not svg{
  color:#0eb334
}
.not .num{
  width: 16px;
  height:16px;
  position: absolute;
  top:-5px;
  right:-10px;
  color:#FFF;
  font-size: 12px;
  background-color: #ff3f39;
  border-radius: 50%;
  
}
.not svg{
  font-size: 24px;
}
.notBox{
  position: absolute;
  top:105%;
  right:10px;
  width:100%;
  max-width: 240px;
  border-radius:5px;
  background-color: #FFF;
  box-shadow: 0px 2px 4px 0 rgba(34, 41, 47, 0.1);
  z-index: 1000;
  min-height: 150px;
  animation: an .3s;
}
@keyframes an{
  from{
    transform: translateY(30px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}
.notBox h5{
  font-size: 16px;
  font-weight: 600;
  color:#666
}
.notBox hr{
  background-color:#AAA
}
.item .icon{
  font-size: 18px;
}
.item span {
  font-size: 12px;
  color:#666;
}
.message h6{
  color:#555;
}
.mess-800{
  font-weight: 700!important;
  color: #111!important;
}
.border-bottom{
  border-color:#EEE!important;
}
`


const Navbar = (props) => {
  const intl = useIntl();
  const [showNot,setShowNot]=useState(false);
  const navigate=useNavigate()
  const dispatch = useContext(DispatchContext);
  const { user } = useContext(StateContext);
  const [data,setData]=useState([]);

  const Logout=()=>{
    UserService.logoutUser(user)
    .then(res=>{
        
    }).catch(err=>{
      
    })
  }

  const logoutUser=()=>{
    Logout();
    logout(dispatch);
    navigate("/");
  }

  const getData=(up)=>{
    UserService.getNotifications(up,true) 
    .then(res=>{
      setData(res.data.data);
    });
  }

  useEffect(()=>{
    getData(false);
  },[])

  const getNotNumber=()=>{

    let d=data.filter(el=>{return el.state==0;})
    return d.length!=0&&<span className='num d-flex justify-content-center align-items-center'>{d.length}</span>;

  }
  const hideMenu=()=>{
    setShowNot(false)
  }
  const showMenu=()=>{
   
    setTimeout(
      ()=>{
        setShowNot(true)
      },0
    )
  }
  const Dropdown = () => {
    const ref = useDetectClickOutside({ onTriggered: ()=>{
      hideMenu()
    } });
    return (
      <div className="notBox p-3 pb-0 border" ref={ref}>
      
                <h5 >Notifications</h5>
                <hr/>
                <div className='not-body'>
  
                  {
                    data.length!=0?data.filter((el,key)=>key<=2).map((el,key)=>{
                      return <div className='item d-flex mb-1'>
                      <div className='icon d-flex align-items-center  pe-2'>
                        <GrMail/>
                      </div>
                      <div className={`message text d-flex flex-column ms-2 border-bottom pb-1 `}>
                        <h6 className={`m-0 p-0 ${el.state==0?"mess-800":""}`}>{el.message}</h6>
                        <span>{el.add_date}</span>
                      </div>
                      
                    </div>
                    }):<p>no notification</p>
                  }
  
                  <div className='item d-flex'>
                    {data.length!=0&&<button className='btn btn-default' onClick={()=>{
                        navigate("/notifications");hideMenu()
                      }
                    }>Voir tout</button>}
                  </div>
              
            </div>
      </div>
    );
  };

  return (
    <Header className="d-flex align-items-center ps-3c pe-3 ">
        <div className="btn-toggle" onClick={() => props.handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <div className="btn-collaps ms-2 justify-content-center d-flex align-items-center" onClick={()=>props.handleCollapsed(!props.collapsed)}>
          {
            props.collapsed?(
              <BiMenuAltLeft />
            ):(
              <BiMenuAltRight />
            )
          }
        </div>
        <div className='justify-content-center d-flex align-items-center'>
          <div className='cy justify-content-center d-flex align-items-center fw-bold me-2'>
            {
              user.photoURL&&(
                user.photoURL!=""?<img src={user.photoURL}/>:user.fname.charAt(0).toUpperCase()
              )}
            {!user.photoURL&&user.fname.charAt(0).toUpperCase()}
          </div>
          <div className='d-flex flex-column fname me-2'>
            <h6 className='m-0 p-0'>{user.fname}</h6>
            <p className='m-0 p-0'>{user.lname}</p>
          </div>
          <p className='p-0 m-0'>( ID: <strong>{user.id_exped}</strong> ) </p>
        </div>
        <div className='flex-grow-1'></div>
        <div className='not me-2' 
          onClick={()=>{
            showMenu(true);
            getData(false)
          }}>

          {
            showNot?<IoNotifications/>:<IoNotificationsOutline/>
          }

          {
            getNotNumber()
          }
          
          
        </div>
        <div onClick={logoutUser} className="d-flex align-items-center btn btn-logout">
          <BiLogIn/>
        </div>
        
        {  
          showNot&&<Dropdown />
          
        }
    </Header>
  );
};






export default Navbar;