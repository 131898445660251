

import { useState, useContext, useEffect } from 'react';
import { Form, Button, Container, Col, Row } from 'react-bootstrap'
import images from "../../assets/images"
import { Link, useNavigate } from 'react-router-dom';
import UserService from '../Service/UserService';
import "../../assets/style/bootstrap-extended.css"
import "../../assets/style/inline.css"
import { Toast, ToastStyle } from "../Views/Toast"
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../Config/firebase-config";
import { facebookProvider, googleProvider } from '../Config/firebase';
import {
    StateContext,
    loginUser,
    DispatchContext,
} from "../Context/";
import styled from "styled-components"
import Loader from '../Views/Loader';
import { useIntl } from 'react-intl';


const Password = styled.div`
position: relative;
.btn{
    position: absolute;
    right:5px;
   cursor: pointer;
    width: 20px;
    height: 100%;
    top: 0;
}
`
function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loding, setLoding] = useState(false);

    const { islogin, user } = useContext(StateContext);
    const [lodingP, setLodingP] = useState(true);

    const [passV, setPassV] = useState(false);
    const dispatch = useContext(DispatchContext);
    const [servervalid, setServervalid] = useState(false);
    const intl = useIntl();


    const updateVal = (e) => {
        switch (e.target.id) {
            case "email": setEmail(e.target.value); break;
            case "password": setPassword(e.target.value); break;
            default: return;
        }
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        if (email === "" || password === "") {

            return;
        }


        loginU("email_pass","");
    }
    const loginU = (type,data) => {
        setLoding(true)
        UserService.loginUser(email, password,type,data)
            .then(res => {

                if (res.data.error === "") {
                    loginUser(dispatch, res.data.user);
                    navigate("/dashboard")

                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                Toast.error(res.data, ToastStyle);
                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    const validLogin = () => {

        UserService.validLogin(user)
            .then(res => {
                if (res.data.error == "") {
                    setServervalid(true);
                    navigate("/dashboard")
                }
                setLodingP(false);
            }).catch(err => {
                Toast.error(err.message, ToastStyle);
            })
    }
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    useEffect(() => {
        if (islogin) {
            if (servervalid)
                navigate("/dashboard")
            validLogin()

        } else {
            setLodingP(false);

        }

    }, [])


    const signInWithFacebook = () => {
        signInWithPopup(auth, facebookProvider)
            .then((result) => {
                const user = result.user;
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                console.log(user.providerData[0])
                let res=user.providerData[0]
                loginU("facebook",{
                    ...getFullName(res.displayName),
                    photoURL:res.photoURL,
                    providerId:res.providerId,
                    uid:res.uid
                });
            })
            .catch((error) => {
                Toast.error(error.message, ToastStyle);
            });
    }
    const signInWithGoogle = () => {
        signInWithPopup(auth, googleProvider )
            .then((result) => {
                const user = result.user;
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                console.log(user.providerData[0])
                let res=user.providerData[0]
                loginU("google",{
                    ...getFullName(res.displayName),
                    email:res.email,
                    photoURL:res.photoURL,
                    providerId:res.providerId,
                    uid:res.uid
                })
            })
            .catch((error) => {
                Toast.error(error.message, ToastStyle);
            });
    }

    const getFullName=(s)=>{
        let ar=s.split(" ");
        let fname=ar[0];
        ar.shift();
        let lname=ar.join(" ");

        return {fname,lname} 
    }
    const divLogin = <Container className="login_register m-0">
        <div className="logo p-4">
            <Link to="/" className="d-flex align-items-center">
                <img src={images.logonoir} alt="" height="40" />
            </Link>
        </div>
        <Row style={{ minHeight: "100%" }}>
            <Col className="left d-flex align-items-center">

                <img src={images.login} alt="" width="100%" />
            </Col>
            <Col className="for_form d-flex align-items-center justify-content-center">
                <Form onSubmit={handleSubmitLogin}>
                    <Container>
                        <Row>
                            <h2 className="title">{intl.formatMessage({ id: 'WELCOM_EXPED' })} </h2>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>{intl.formatMessage({ id: 'EMAIL' })}</Form.Label>
                                    <Form.Control type="email" placeholder="jhon@eample.com" vlaue={email} onChange={updateVal} />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>{intl.formatMessage({ id: 'PASSWORD' })}</Form.Label>

                                    <Password className='pass'>
                                        <Form.Control type={`${!passV ? "password" : "text"}`} placeholder="........" vlaue={password} onChange={updateVal} />
                                        <div className="btn" onClick={() => setPassV(!passV)}>
                                            {
                                                passV ?
                                                    <AiOutlineEyeInvisible />
                                                    :
                                                    <AiOutlineEye />

                                            }
                                        </div>

                                    </Password>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className="mb-2" controlId="check">
                                    <Form.Check type="checkbox" label={intl.formatMessage({ id: 'REMMEMBER_ME' })} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} className="mb-2">
                                <Button variant="primary" type="submit" className='mt-2 btn-block' disabled={loding}>
                                    {loding ? spanLoding : ""}
                                    {intl.formatMessage({ id: 'LOGIN' })}
                                </Button>
                            </Col>
                            <Col sm={12} className="text-center">
                                {intl.formatMessage({ id: 'NEW_USER_EXPED' })}
                                <Link to="/register">
                                    {intl.formatMessage({ id: 'CREATE_ACCOUNT' })}
                                </Link>
                            </Col>
                            <div className='d-flex align-items-center'>
                                <hr className='flex-grow-1 me-2' />
                                ou
                                <hr className='flex-grow-1 ms-2' />
                            </div>
                            <Col sm={12} className="text-center">
                                {/* <FacebookLoginButton
                                    onClick={() => signInWithFacebook()}
                                    className="btn-sm d-flex justify-content-center">
                                    <small>Se connecter avec Facebook</small>
                                </FacebookLoginButton> */}
                                <GoogleLoginButton
                                    onClick={() => signInWithGoogle()}
                                    className="btn-sm d-flex justify-content-center">
                                    <small>Se connecter avec Google</small>
                                </GoogleLoginButton>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Col>
        </Row>
    </Container>
    return (
        <>
            {

                lodingP ?
                    <Loader />
                    :
                    divLogin
            }
        </>
    );
}

export default Login;