import {userState} from 'react';
import styled from "styled-components"

import {MdLocationOn,MdPhone,MdEmail} from "react-icons/md"
import { Link ,useNavigate} from 'react-router-dom';
const DivC=styled.div`
min-width: 300px;

ul{
    list-style-type: none;
    margin: 0;
    padding-top: 0!important;
}
`
function CardAddress(props) {
    const data=props.data;
    
    const navigate=useNavigate()
    return (
        <DivC className='card'>
            <div className="card-header">
                <h5>{data.name}</h5>
            </div>
            <div className="card-body ">
                <ul className="p-2">

                    <li>
                        <MdLocationOn className="me-2"/>
                        {data.country}
                    </li>
                    <li>
                        <MdLocationOn className="me-2"/>
                        {data.state}
                    </li>
                    <li>
                        <MdLocationOn className="me-2"/>
                        {data.city}
                    </li>
                    <li>
                        <MdLocationOn className="me-2"/>
                        {data.zip_code}
                    </li>
                    <li>
                        <MdLocationOn className="me-2"/>
                        {data.address_line1}
                    </li>
                    <li>
                        <MdLocationOn className="me-2"/>
                        {data.address_line2}
                    </li>
                    <li>
                        <MdEmail className="me-2"/>   
                        {data.email}
                    </li>
                    <li>
                        <MdPhone className="me-2"/>
                        {data.phone}
                    </li>
                </ul>

            </div>
            <div className="card-footer d-flex justify-content-end">
                <button className="btn btn-success me-2" onClick={()=>navigate("/profile/delivery/edit",{state:{...data,countries:props.countries}})}>Modifier</button>
                { 
                    props.hasDelete&&<button className="btn btn-danger" onClick={()=>props.showModalF(data.id_au)}>Supprimer</button>
                }
                
            </div>
        </DivC>
    );
}

export default CardAddress;


