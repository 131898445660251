import req from "../Config/http-common";
import ApiConfig from "../Config/ApiConfig";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))



const addVisa=(data)=>{
    return req.http3.post("/visa/",{
        ...data,
        session:getSession(),
        add_visa:true
    });
}
const getAll=(data)=>{
    return req.http3.post("/visa/",{
        session:getSession(),
        get_all:true
    });
}
const sendProof=(ob)=>{
    ob.append("session",JSON.stringify(getSession()));
    ob.append("send_proof",true);
    return req.httpUpload.post("/visa/",ob);
}
const changeState=(ob)=>{
    return req.http3.post("/visa/",{
        ...ob,
        session:getSession(),
        change_state:true
        
    });
}


const visaInfo=()=>{
    return req.http3.post("/visa/",{
        session:getSession(),
        info_for_visa:true
    });
}

const VisaService = {
    addVisa,
    getAll,
    sendProof,
    changeState,
    visaInfo
};

export default VisaService;