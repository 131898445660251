import React, { useState, useEffect } from 'react';

import { Col, Row, Form, Container, Button, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import { Toast, ToastStyle } from "../Views/Toast"
import ShippingService from "../Service/ShippingService"
import { useIntl } from 'react-intl';
import PreviewImages from "../Colis/PreviewImages"
import styled from "styled-components"
import ApiConfig from '../Config/ApiConfig';
import { MdOutlineDelete } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
const Upload = styled.div`
img{
    max-height:150px;
    max-width:100%
}

`



const storeShema = Yup.object().shape({

    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
});

function AddBank({ update }) {
    const [loding, setLoding] = useState(false);
    const location = useLocation()
    const intl = useIntl();
    
    const datar = location.state;
    const [insurance, setInsurance] = useState(update?datar.insurance:[]);
    const [logo, setLogo] = useState(null)






    const clear = (actions) => {
        setTimeout(() => {

            actions.setSubmitting(false)
            actions.resetForm();
            setLogo(null);
        }, 200);

    }
    const add_transport_compani = (ob, actions) => {
        setLoding(true)
        ShippingService.addTranportCompani(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("Add success", ToastStyle);
                    clear(actions)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    const update_transport_compani = (ob) => {
        setLoding(true)
        ShippingService.updateTranportCompani(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("update success", ToastStyle);
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }






    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )

    const addInsurance=(index)=>{
        let tab=[...insurance];
        tab.splice(index+1, 0, { price: 0, value: 0 })

        setInsurance(tab)
    }
    const deleteInsurance=(index)=>{
        setInsurance(
            insurance.filter((el,key)=>{
            return(key!=index);
        }))
    }
    const updateInsurance=(val,index,item)=>{
        setInsurance(insurance.map((el,key)=>{
            if(item)
                return key==index?{...el,price:val}:el
            else
                return key==index?{...el,value:val}:el
        }))
    }
    return (
        <Container className="p-0 m-0">
            <Row className="">
                <Col className="d-flex justify-content-end">
                    <Link to="/tools" className="btn btn-default">
                        {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
            </Row>
            <Formik
                initialValues={{
                    name: !update ? '' : datar.name,
                }}

                validationSchema={storeShema}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("name", values.name);
                    data.append("logo", logo);
                    data.append("insurance",JSON.stringify(insurance))
                    data.append(!update ? "add_tranport_compani" : "update_tranport_compani", true);
                    if (update)
                        data.append("id_tc", datar.id_tc);
                    if (!update)
                        add_transport_compani(data, actions)
                    else
                        update_transport_compani(data)
                }}
            >
                {
                    (
                        {
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors
                        }
                    ) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="p-0 m-0">
                                <Col sm={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>{intl.formatMessage({ id: 'NAME' })}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={12} className="mt-4">
                                    <Form.Group controlId="name">
                                        <Form.Label>Assurance</Form.Label>

                                        {
                                            insurance.length==0?
                                            <div>
                                                <span className="btn btn-default border btn-sm" onClick={()=>setInsurance([{ price: 0, value: 0 }])}>Ajouter une assurance</span>
                                            </div>
                                            :
                                            insurance.map((el,key) => {
                                                return <div className='mb-4'>
                                                    <InputGroup className="" size={"sm"}>
                                                        <InputGroup.Text >Prix</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={el.price}
                                                            onChange={(e)=>updateInsurance(e.target.value,key,true)}
                                                        />
                                                        <InputGroup.Text >EUR</InputGroup.Text>
                                                    </InputGroup>
                                                    <div className="d-flex mt-1">
                                                        <InputGroup className="" size={"sm"}>
                                                            <InputGroup.Text >Valeur d'assurance</InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                value={el.value}
                                                                onChange={(e)=>updateInsurance(e.target.value,key,false)}
                                                            />
                                                            <InputGroup.Text >EUR</InputGroup.Text>
                                                        </InputGroup>

                                                        <span className="btn btn-default btn-sm ms-1 border" onClick={()=>deleteInsurance(key)}><MdOutlineDelete /></span>
                                                        <span className="btn btn-default btn-sm ms-1 border" onClick={()=>addInsurance(key)}><FiPlus /></span>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </Form.Group>
                                </Col>

                                <Col sm={12}>
                                    <Form.Group controlId="logo">
                                        <Form.Label>Logo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="logo"
                                            accept="image/*"
                                            onChange={(e) => { handleChange(e); setLogo(e.target.files[0]); }}

                                        />
                                        {
                                            logo ? <Upload className="mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                                <PreviewImages file={logo} />
                                            </Upload> : (
                                                update &&
                                                <Upload className="mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                                    <img src={ApiConfig.urlTransportCompani + datar.logo} />
                                                </Upload>
                                            )
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end mt-3">
                                    <Button type="submit" disabled={loding || !update && logo == null}>
                                        {loding ? spanLoding : ""}
                                        {
                                            update ? intl.formatMessage({ id: 'SAVE' }) : intl.formatMessage({ id: 'SUBMIT' })
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                    )}

            </Formik>
        </Container>
    );
}

export default AddBank;