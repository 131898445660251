import { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useIntl } from 'react-intl';
import ShippingService from "../Service/ShippingService"
import { Toast, ToastStyle } from "../Views/Toast"
import Select from 'react-select';
import ApiConfig from '../Config/ApiConfig';
import Loader from "../Views/Loader"
function Calculator(props) {

    const [data, setData] = useState(null);
    const [bank, setBank] = useState(null);
    const [amount, setAmount] = useState(0);
    const [devis, setDevis] = useState(null);
    const getData = () => {
        ShippingService.getBanks(false)
            .then(res => {
                let bs = res.data.data.banks
                setData(bs.map((el) => {
                    return {
                        ...el,
                        currency_data: JSON.parse(el.currency_data)
                    }
                }));

            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData()
    }, [])
    const intl = useIntl();
    const handleSubmitLogin = (e) => {
        e.preventDefault();

    }
    const getDinar = (v) => {
        let x = v % 1000;
        let y = Math.ceil(x / 100) * 100 + Math.floor(v / 1000) * 1000;
        return y;
    }
    return (
        data ?
            <Form onSubmit={handleSubmitLogin}>
                <Container>
                   {!props.hideTitle&&<Row className="pt-2 pb-2">
                        <h5>
                        Calculateur de frais d'expédition
                        </h5>
                    </Row>}
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="currency">
                                <Form.Label>Type de recharge :</Form.Label>
                                <ul className="list-group">
                                    {
                                        data.map((el, key) => {
                                            return <li className="list-group-item d-flex align-items-center p-2" style={{ border: "none" }}>

                                                <input type="radio" className="form-check-input me-3" name="bank" checked={bank && bank.id_bank == el.id_bank} onChange={() => { setBank(data[key]); setDevis(null); setAmount(0) }} />

                                                <img src={ApiConfig.urlBankImages + el.logo} alt={el.name} height="20" className="ms-3" />
                                            </li>
                                        })
                                    }
                                </ul>
                            </Form.Group>

                        </Col>
                        {
                            bank && <Col sm={6}>
                                <Form.Group className="mb-3" controlId="currency">
                                    <Form.Label>Devise d'expédition :</Form.Label>
                                    {
                                        bank.currency_data.map((el, key) => {
                                            return <Form.Check type="radio" name="currency" label={el.currency} checked={devis && (el.currency == devis.currency)} onChange={() => setDevis(el)} />
                                        })
                                    }
                                </Form.Group>
                            </Col>
                        }
                        {
                            devis && <>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="amount">
                                        <Form.Label>Montant :</Form.Label>
                                        <Form.Control type="text" value={amount} onChange={(e) => setAmount(parseFloat(e.target.value == "" ? 0 : e.target.value))} />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <hr className="mt-4" />
                                    <Form.Group className="mb-3" controlId="amount">
                                        <Form.Label>Frais d'expédition :</Form.Label>
                                        <h1 style={{ fontSize: "60px" }}>
                                            {devis && parseFloat(amount * devis.buy).toFixed(2)} DA
                                        </h1>
                                    </Form.Group>


                                    <hr className="mt-4" />
                                </Col>
                            </>
                        }

                    </Row>



                </Container>
            </Form>
            : <Loader no={true}/>
    );
}

export default Calculator;