import main_back from "./main_back.png" 
import logo from "./logo.png"
import im1 from "./1.png"
import im2 from "./2.png"
import im3 from "./3.png"
import im4 from "./4.png"
import im5 from "./5.png"
import im6 from "./6.png"
import favicon from "./favicon.png"
import safe_moving_icon1 from "./safe-moving-icon1.png"
import safe_moving_icon2 from "./safe-moving-icon2.png"
import safe_moving_img from "./safe-moving-img.png"
import why_choose from "./why-choose.jpg"
import about_company from "./about-company.png"
import devgate from "./devgate.png"
import devgate_logo from "./devgate_logo.png"
import login from "./login-v2.svg"
import register from "./register-v2.svg"
import store from "./store.png"
import logoleft from "./logoleft.png"
import logoright from "./logoright.png"
import logorightw from "./logorightw.png"
import logonoir from "./logonoir.png"
import paysera_card from "./paysera_card.png"
import func1 from "./func1.png"
import func2 from "./func2.png"
import func3 from "./func3.png"
import func4 from "./func4.png"

import star1 from "./star1.png"
import star2 from "./star2.png"
import path1 from "./path1.png"
import path2 from "./path2.png"
import logo_left from "./logo-left.png"
import logo_right from "./logo-right.png"
import circle10 from "./circle10.png"

import imgc from "./imgc.png"
const images={
    im1,
    im2,
    im3,im6,
    im4,im5,logo,main_back,favicon,
    safe_moving_icon1,logorightw,logonoir,
    safe_moving_icon2,logoleft,logoright,
    safe_moving_img,devgate,devgate_logo,store,
    why_choose,about_company,login,register,paysera_card,func1,func2,func3,func4,star1,star2,path1,path2,
    logo_left,logo_right,circle10,imgc
}

export default images;