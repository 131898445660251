import { useState, useContext, useEffect } from 'react';
import { ColisTable } from '../Views/Table';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StoreService from '../Service/StoreService';
import Loader from '../Views/Loader';
import { StateContext,DispatchContext,changeColis } from "../Context/";
import { Toast, ToastStyle } from "../Views/Toast"
import { MdDone } from "react-icons/md"
import { useIntl } from 'react-intl';
import Modal from "../Views/Modal"
import PaimentView from './PaimentView';
import DeliveryAddress from "../Views/DeliveryAddress"


const stylebtn = {
    minWidth: "100px"
}


function ColisList(props) {
    const [data, setData] = useState(null);
    const { user } = useContext(StateContext);
    const dispatch = useContext(DispatchContext);


    const [showModal, setShowModal] = useState(false);
    const [new_state, setNewState] = useState(null);
    const [action, setAction] = useState(null);
    const [param, setParam] = useState(null);
    const state = props.state;
    const intl = useIntl();

    const getData = () => {
        StoreService.getAllColis(user)
            .then(res => {

                setData(res.data.data);
                changeColis(dispatch,res.data.data)
                setParam(res.data.parameter)
            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData();
    }, []);

    const SendC = () => {
        setLoding(true)
        StoreService.sendColis(action.data)
            .then(res => {
                setLoding(false)
                hideModallF()
                if (res.data.error == "") {
                    getData()
                    Toast.success("send success", ToastStyle);
                }
                else
                    Toast.success(res.data.error, ToastStyle);

            }).catch(err => {
                setLoding(false)
                hideModallF()
                Toast.error(err.message, ToastStyle);
            })
    }

    const changeState = () => {
        setLoding(true)
        let id_colis=["colis_send","colis_recu"].includes(action.type)?
        action.data.colis.id_colis:
        action.data.id_colis
        StoreService.changeState({ id_colis, new_state })
            .then(res => {
                setLoding(false)
                hideModallF()
                if (res.data.error == "") {
                    getData()
                    Toast.success("change success", ToastStyle);
                }
                else
                    Toast.success(res.data.error, ToastStyle);

            }).catch(err => {
                setLoding(false)
                hideModallF()
                Toast.error(err.message, ToastStyle);
            })
    }



    const RequestPay = (id_colis) => {
        setLoding(true)
        StoreService.requestPay(id_colis)
            .then(res => {
                if (res.data.error == "") {
                    getData()
                    Toast.success("Opération réussie", ToastStyle);
                }
                else
                    Toast.success(res.data.error, ToastStyle);
                setLoding(false)
                hideModallF()

            }).catch(err => {
                Toast.error(err.message, ToastStyle);
                setLoding(false)
                hideModallF()
            })
    }


    const updateChange = (e) => {
        setNewState(e.target.value)
    }

    const updateState = (e) => {
        alert(e.target.value);
        return false
    }
    const showModalF = (type, ob) => {

        switch (type) {
            case "send": break;
            case "change_state": setNewState(ob.state);break
            case "colis_send": setNewState(6); break;
            case "colis_recu": setNewState(7); 
        }
        setAction({ type, data: ob });
        setShowModal(true)
    }
    const hideModallF = () => {

        setShowModal(false)
        // setAction(null)
    }
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )


    const handaleClick = () => {
        switch (action.type) {
            case "send": SendC(); break;
            case "colis_send":
            case "colis_recu":
            case "change_state": changeState(); break;
            case "demend_pay": RequestPay(action.data.id_colis);
        }
    }
    const DisableBtn = () => {

        if (loding) return true;
        if (!action) return false;
        switch (action.type) {
            case "send": return false;
            case "change_state":
                if (new_state == action.data.state)
                    return true;
        }
    }
    const states = [
        intl.formatMessage({ id: 'COLIS_REC' }),
        intl.formatMessage({ id: 'COLIS_PREP' }),
        intl.formatMessage({ id: 'COLIS_ATT_PAIE' }),
        intl.formatMessage({ id: 'COLIS_ATT_VALID' }),
        intl.formatMessage({ id: 'COLIS_PAIE_ACC' }),
        intl.formatMessage({ id: 'COLIS_REJ' }),
        intl.formatMessage({ id: 'COLI_EN_ROUT' }),
        intl.formatMessage({ id: 'COLIS_ARR' }),
    ]









    const [showModal2, setShowModal2] = useState(false);
    const [acc,setAcc]=useState(null)
    const showModalF2 = (bool) => {
        setAcc({id_colis:action.data.colis.id_colis,new_state:bool?4:5})
        setShowModal2(true)
    }
    const hideModallF2 = () => {
        setShowModal2(false)
    }
    const changeAcc = () => {
        hideModallF2()
        setLoding(true)
        StoreService.changeState(acc)
            .then(res => {
                setLoding(false)
                hideModallF()
                if (res.data.error == "") {
                    getData()
                    Toast.success("change success", ToastStyle);
                }
                else
                    Toast.success(res.data.error, ToastStyle);

            }).catch(err => {
                setLoding(false)
                hideModallF()
                Toast.error(err.message, ToastStyle);
            })
    }
    return (
        <>
            <Row>
                <Col>
                    {
                        data === null ? <Loader no={true} /> :
                            <ColisTable
                                user={user}
                                data={
                                    state != -1 ? data.filter(el => el.state == state).map((el) => { return { ...el, state: states[el.state], action: el } }) :
                                        data.map((el) => { return { ...el, state: states[el.state], action: el } })
                                }
                                showModalF={showModalF}
                                RequestPay={RequestPay}
                                updateState={updateState}
                            />
                    }
                </Col>
            </Row>




            <Modal
                show={showModal}
                showbtn={false}
                title={
                    action && (action.type == "send" || action.type == "demend_pay") && intl.formatMessage({ id: 'CONFIRMATION' }) ||
                    action && action.type == "change_state" && intl.formatMessage({ id: 'CHANGE_STATE' }) ||
                    action && action.type == "info" && "info" ||
                    action && action.type == "info_pay" && "Vérification du paiement" ||
                    action && action.type == "colis_send" && "Envoyer à" ||
                    action && action.type == "colis_recu" && "Avez-vous bien reçu le colis ?"
                }
                onHide={hideModallF}
                size={action && action.type == "info_pay" ? "lg" : ""}

            >
                {
                    action &&action.type=="colis_send"&&<>
                        <DeliveryAddress data={action.data.colis.paiment.address}/>
                    </>
                }

                {
                    action && action.type == "demend_pay" && <>
                        Voulez-vous effectuer l'opération ?
                    </>
                }

                {
                    action && action.type == "send" && <>
                        {intl.formatMessage({ id: 'CONFIRM_SEND_COLIS' })}<br />
                        {intl.formatMessage({ id: 'CONFIRM_SEND_COLIS2' })}
                    </>
                }

                {
                    action && action.type == "info" && <div>
                        Votre colis est en cours de prépartion vous allez bientôt recevoir une demande de paiement.
                    </div>
                }

                {
                    action && action.type == "change_state" && <>
                        <ul className="list-group">
                            {
                                action.data.states.map((el, key) => {
                                    return <li className="list-group-item d-flex " style={{ border: "none" }}>
                                        <input type="radio" class="form-check-input me-2" name="optradio" value={key} disabled={user.rol == 1 && key > action.data.state} checked={key == new_state} onChange={updateChange} />
                                        {el}
                                        {
                                            action.data.state == key && <>
                                                <div className="flex-grow-1"></div>
                                                <MdDone />
                                            </>
                                        }
                                    </li>

                                })
                            }
                        </ul>
                    </>
                }

                {
                    action && action.type == "info_pay" && <>
                        <PaimentView colis={action ? action.data.colis : null} user={user} param={param}/>
                    </>
                }


                <div className='d-flex justify-content-end mt-2'>
                    {
                        action && action.type != "info" && <>

                            {
                                action.type == "info_pay"&&(user.rol == 1 || user.rol == 2) && <>
                                    <button className='btn btn-danger me-2'  onClick={()=>showModalF2(false)}>Rejeté</button>
                                    <button className='btn btn-success me-2' onClick={()=>showModalF2(true)}>Accépté</button>
                                    <div className="flex-grow-1"></div>
                                </>
                            }
                            {
                                action && action.type != "info_pay" && <button className='btn btn-success me-1' style={stylebtn} disabled={true == DisableBtn()} onClick={handaleClick}>
                                    {loding ? spanLoding : ""}

                                    {action && (["send","demend_pay","colis_recu"].includes(action.type)) && intl.formatMessage({ id: 'YES' })}
                                    {action && action.type == "change_state" && intl.formatMessage({ id: 'CHANGE' })}
                                    {action && action.type == "colis_send" && intl.formatMessage({ id: 'SEND' })}
                                </button>
                            }


                            <button className='btn btn-danger' style={stylebtn} onClick={hideModallF}>

                                {action && (["send","demend_pay","colis_recu"].includes(action.type)) && intl.formatMessage({ id: 'NO' })}
                                {action && (action.type == "change_state" || action.type == "info_pay" || action.type == "colis_send") && intl.formatMessage({ id: 'CLOSE' })}
                            </button>

                        </>
                    }

                    {
                        action && action.type == "info" &&
                        <button className='btn btn-danger' style={stylebtn} onClick={hideModallF}>
                            Ok
                        </button>
                    }
                </div>
            </Modal>



            <Modal
                show={showModal2}
                showbtn={false}
                title={"Êtes-vous sur ?"}
                onHide={hideModallF2}

            >




                <div className='d-flex justify-content-end mt-2'>
                    <button className='btn btn-success me-1' style={stylebtn} onClick={()=>changeAcc()} >
                        {loding ? spanLoding : ""}
                        {intl.formatMessage({ id: 'YES' })}
                    </button>
                    <button className='btn btn-danger' style={stylebtn} onClick={() => hideModallF2()}>

                        {intl.formatMessage({ id: 'NO' })}
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ColisList;