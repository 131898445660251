
import { Container } from 'react-bootstrap';
import { useLocation} from 'react-router-dom';
import ColisList from './ColisList';
import AddColis from './AddColis';
import Paiment from './Paiment';
import Calculator from "../Calculator/Calculator"
import HowTo from './HowTo';
function Colis(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/colis" : return <ColisList state={-1}/>;
            case "/colis/add" : return <AddColis update={false}/>;
            case "/colis/edit" : return <AddColis update={true}/>;
            case "/colis/pay" : return <Paiment update={false}/>;
            case "/colis/pay/edit" : return <Paiment update={true}/>;
            case "/colis/a" : return <ColisList state={0}/>;
            case "/colis/b" : return <ColisList state={1}/>;
            case "/colis/c" : return <ColisList state={2}/>;
            case "/colis/d" : return <ColisList state={3}/>;
            case "/colis/e" : return <ColisList state={4}/>;
            case "/colis/f" : return <ColisList state={5}/>;
            case "/colis/g" : return <ColisList state={8}/>;
            case "/colis/h" : return <ColisList state={6}/>;
            case "/colis/i" : return <ColisList state={7}/>;
            case "/colis/calculator" : return <Calculator />;
            case "/colis/how_to" : return <HowTo/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Colis;