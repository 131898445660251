import {useState,useContext} from 'react';
import {Col,Row ,Form,Container,Button} from 'react-bootstrap';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import {Toast,ToastStyle} from "../Views/Toast"
import {StateContext} from "../Context/"
import UserService from "../Service/UserService"
import Password from "../Views/Password"
import { useIntl } from 'react-intl';

const passwordShema = Yup.object().shape({

    ac_password: Yup.string()
      .min(6, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    cpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('required'),
});
function ChangePassword(props) {
    const [loding,setLoding]=useState(false);
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
   

    const { user } = useContext(StateContext);

    const clear=(actions)=>{
       
        setTimeout(() => {
            
            actions.setSubmitting(false)
            actions.resetForm();
           
        }, 100);
        
    }
    const changePassword=(ob,actions)=>{
        
        setLoding(true)
        UserService.updateUserPassword({...user,...ob})
        .then(res=>{
            
            if(res.data.error===""){
                Toast.success("update success",ToastStyle);
                clear(actions)
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const intl = useIntl();
    
    return (
        <Container>
            <Formik
                initialValues={{
                    ac_password: '',
                    password:'',
                    cpassword:'',
                }}

                validationSchema={passwordShema}
                onSubmit={(values,actions) => {
                    changePassword(values,actions);
                }}
            >
                {
                    (
                        { 
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors 
                        }
                    ) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Container className="p-0 ">
                            <Row className="m-0">
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="ac_password">
                                        <Form.Label>{intl.formatMessage({ id: 'OLD_PASS' })}</Form.Label>
                                        
                                        <Password
                                            name="ac_password"
                                            value={values.ac_password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ac_password}
                                        >
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ac_password}
                                            </Form.Control.Feedback>
                                        </Password>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="m-0">
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="password">
                                        <Form.Label>{intl.formatMessage({ id: 'NEW_PASS' })}</Form.Label>
                                        <Password
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        >
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Password>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <Form.Group controlId="cpassword">
                                        <Form.Label>{intl.formatMessage({ id: 'CNEW_PASS' })}</Form.Label>
                                        <Password
                                            name="cpassword"
                                            value={values.cpassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.cpassword}
                                        >
                                            <Form.Control.Feedback type="invalid">
                                                {errors.cpassword}
                                            </Form.Control.Feedback>
                                        </Password>
                                    </Form.Group>
                                </Col>
                               
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end mt-3">
                                    <Button type="submit" disabled={loding}>
                                        {loding ? spanLoding:""}
                                        {intl.formatMessage({ id: 'SAVE' })}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default ChangePassword;