
import { Container, Col, Row, Form } from 'react-bootstrap';
import { useState, useContext, useEffect } from 'react';
import { DispatchContext, logout, StateContext } from "../Context/"
import UserService from '../Service/UserService';

import { GrMail } from "react-icons/gr"
import { MdDeleteOutline } from "react-icons/md"
import styled from 'styled-components';
import Loader from "../Views/Loader"
import Modal from "../Views/Modal"
import { useIntl } from 'react-intl';
import { Toast, ToastStyle } from "../Views/Toast"

const stylebtn = {
    minWidth: "100px"
}
const DivC = styled.header`
.item{
    /* box-shadow:0px 0px 3px 0px #0002; */
    /* border-radius: 5px; */
    
}
.item span {
  font-size: 14px;
  color:#666;
}
.item h6{
  color:#555;
  font-size: 16px;
}
.icon{
    font-size:24px
}
.icon svg{
    color:#555;
}
`

function Notifications(props) {
    const [data, setData] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const getData = (up) => {
        UserService.getNotifications(up, false)
            .then(res => {
                setData(res.data.data.map(el => { return { ...el, select: false } }));
            });
    }

    useEffect(() => {
        getData(true);
    }, [])


    const [showModal, setShowModal] = useState(false);
    const [item, setItem] = useState(null);
    const intl = useIntl();
    const showModalF = (ob) => {
        setItem(ob);
        setShowModal(true)
    }
    const deleteNow = () => {
        let ids=JSON.stringify(item.all?data.filter(el=>el.select).map(el=>el.id_not):[item.id]);
        setLoding(true)
        UserService.deleteNotifications(ids)
        .then(res => {

           if(res.data.error==""){
                Toast.success("delete success",ToastStyle);
                
                getData(true);
                hideModallF()
           }else{
                Toast.error(res.data.error, ToastStyle);
           }
           setLoding(false)
        }).catch(err=>{
            setLoding(true)
            Toast.error(err.message, ToastStyle);
        });
        
    }
    const hideModallF = () => {
        setShowModal(false);
        setItem(null)
    }
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    return (data ?
        <DivC className="container">
            <Row className="pt-2 pb-2">
                <h5>
                    Notifications
                </h5>
            </Row>
            {!data ? <h3 className="no connection"></h3>:data.length==0?"Il n'y a pas de notifications":<Row >
                <Col className="d-flex align-items-center">
                    <Form.Check
                        type={"checkbox"}
                        className="me-2"
                        checked={selectAll}
                        label={`tout sélectionner ${data.filter(el=>el.select).length!=0?("( "+data.filter(el=>el.select).length+ " éléments)"):""}`}
                        onChange={(e) => {
                            setSelectAll(!selectAll)
                            setData(data.map((item, index) => {
                                return { ...item, select: !selectAll }
                            }))

                        }}
                    />
                    <div className="flex-grow-1"></div>
                    {
                        data.map(el => { return el.select; }).reduce((a, b) => a || b, false) &&
                        <button className='btn  btn-sm btn-danger'  onClick={()=>showModalF({all:true})}> Supprimer tout</button>
                    }
                </Col>
                {
                    data && data.map((el, key) => {
                        return <Col lg={12} sm={12} className={`mt-2 p-2 d-flex align-items-center item border-bottom ${el.select&&"bg-light"}`}>
                            <Form.Check
                                type={"checkbox"}
                                className="me-2"
                                checked={el.select}
                                onChange={(e) => {
                                    setSelectAll(false)
                                    setData(data.map((item, index) => {
                                        if (index == key)
                                            return { ...item, select: !item.select }
                                        else return item;
                                    }))
                                }}
                            />
                            <div className='icon d-flex align-items-center  pe-2 me-2'>
                                <GrMail />
                            </div>
                            <h6>{el.message}</h6>
                            <span className='fw-200 flex-grow-1 text-end me-2'>{el.add_date}</span>
                            <button className='btn btn-light ' onClick={()=>showModalF({all:false,id:el.id_not})}> <MdDeleteOutline /></button>
                        </Col>
                    })
                }
            </Row>}


            <Modal
                show={showModal}
                showbtn={false}
                title={"Voulez-vous supprimer ?"}
                onHide={hideModallF}

            >
                {
                    item&&item.all&&<>
                        {data.filter(el=>el.select).length} éléments seront supprimés
                    </>
                }



                <div className='d-flex justify-content-end mt-2'>
                    <button className='btn btn-success me-1' style={stylebtn} onClick={() => deleteNow()} >
                        {loding ? spanLoding : ""}
                        {intl.formatMessage({ id: 'YES' })}
                    </button>
                    <button className='btn btn-danger' style={stylebtn} onClick={() => hideModallF()}>

                        {intl.formatMessage({ id: 'NO' })}
                    </button>
                </div>
            </Modal>
        </DivC> : <Loader />
    );
}

export default Notifications;