import React from 'react';

function VisaTrac(props) {
    return (
        <div>
            VisaTrac
        </div>
    );
}

export default VisaTrac;