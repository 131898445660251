import React,{useState,useRef} from 'react';
import {Col,Row ,Form,Container,Button} from 'react-bootstrap';
import ApiConfig from '../Config/ApiConfig';
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import StoreService from "../Service/StoreService"
import {Toast,ToastStyle} from "../Views/Toast"
import ShowImages from './ShowImages';
import { useIntl } from 'react-intl';


function AddColis({update}) {

    
    function deleteImage(e) {
     

        // let fileListArr = Array.from(refFile.current.files)
        // fileListArr.splice(e, 1) 
        // refFile.current.files=fileListArr
        // console.log(1)
        const s = image.filter((item, index) => index !== e);
        setImage(s);
        
    }
    const refFile=useRef()
    const intl = useIntl();



    const colisShema = Yup.object().shape({

        id_exped: Yup.string()
        .matches(/^[0-9]{6}?/,{
              message:"invalid id exped"
          })
        .required('Required'),
    
        tracking_number: Yup.string("number"),
    
        weight:Yup.number()
        .integer('invalid decimal')
        .required('Required '),
    
        length:Yup.number()
        .required('Required'),
    
        width:Yup.number()
        .required('Required'),
    
        height:Yup.number()
        .required('Required'),
    
        image:Yup.string()
       
    });


    const [loding,setLoding]=useState(false);
    const location =useLocation()
    const datar=location.state;

    const clear=(actions)=>{
        setTimeout(() => {
            
            actions.setSubmitting(false)
            actions.resetForm();
            setImage([]);
        }, 200);
        
    }
   
    const [image,setImage]=useState([]);
    const [prevImages,setPrevImages]=useState(update);
    const add_colis=(ob,actions)=>{
        
        setLoding(true)
        StoreService.addColis(ob)
        .then(res=>{
            
            if(res.data.error===""){
                Toast.success("Add success",ToastStyle);
                clear(actions)
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const update_colis=(ob)=>{
        
        setLoding(true)
        StoreService.updateColis(ob)
        .then(res=>{
            
            if(res.data.error===""){
                Toast.success("update success",ToastStyle);
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
 
    return (
        <>
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <Link to={`${update?"/colis":"/store"}`} className="btn btn-default">
                    {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
            </Row>
            <Formik
                initialValues={{
                    id_exped: !update?'':datar.id_exped,
                    tracking_number: !update?'':datar.tracking_number,
                    weight:!update?0:datar.weight,
                    height: !update?0:datar.height,
                    length: !update?0:datar.length,
                    width: !update?0:datar.width,
                    image: '',
                }}

                validationSchema={colisShema}
                onSubmit={(values,actions) => {
                    
                    if(image.length>5){
                        return
                    }
                    let data=new FormData();

                    data.append("id_exped",values.id_exped);
                    if(!update){
                        data.append("id_store",datar.id_store);
                    }else{
                        data.append("id_colis",datar.id_colis);
                    }
                    data.append("tracking_number",values.tracking_number);
                    data.append("weight",values.weight);
                    data.append("height",values.height);
                    data.append("length",values.length);
                    data.append("width",values.width);
                    
                    for(let i=0;i!=image.length;i++)
                        data.append("image[]",image[i]);

                    data.append(!update?"add_colis":"update_colis",true);
                  
                    if(!update)
                        add_colis(data,actions);
                    else
                        update_colis(data);
                }}
            >
                {
                    (
                        { 
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors 
                        }
                    ) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="id_exped">
                                    <Form.Label>{intl.formatMessage({ id: 'ID_EXPED' })}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="id_exped"
                                        value={values.id_exped}
                                        onChange={handleChange}
                                        isInvalid={!!errors.id_exped}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.id_exped}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="tracking_number">
                                    <Form.Label>{intl.formatMessage({ id: 'TRACK_NUMBER' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tracking_number"
                                        value={values.tracking_number}
                                        onChange={handleChange}
                                        isInvalid={!!errors.tracking_number}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.tracking_number}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="weight">
                                    <Form.Label>{intl.formatMessage({ id: 'WEIGHT' })} ({intl.formatMessage({ id: 'KG' })})</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="weight"
                                        value={values.weight}
                                        onChange={handleChange}
                                        isInvalid={!!errors.weight}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.weight}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="length">
                                    <Form.Label>{intl.formatMessage({ id: 'LENGTH' })} ({intl.formatMessage({ id: 'CM' })})</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="length"
                                        value={values.length}
                                        onChange={handleChange}
                                        isInvalid={!!errors.length}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.length}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="width">
                                    <Form.Label>{intl.formatMessage({ id: 'WIDTH' })} ({intl.formatMessage({ id: 'CM' })})</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="width"
                                        value={values.width}
                                        onChange={handleChange}
                                        isInvalid={!!errors.width}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.width}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="height">
                                    <Form.Label>{intl.formatMessage({ id: 'HEIGHT' })} ({intl.formatMessage({ id: 'CM' })})</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="height"
                                        value={values.height}
                                        onChange={handleChange}
                                        isInvalid={!!errors.height}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.height}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="image">
                                    <Form.Label>{intl.formatMessage({ id: 'IMAGES' })} ( {intl.formatMessage({ id: 'IMAGES5' })} )</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="image"
                                        ref={refFile}
                                        value={image.length!=0?values.image:null}
                                        disabled={update&&prevImages}
                                        multiple
                                        accept="image/*"
                                        onChange={(e)=>{handleChange(e);setImage([...e.target.files]);}}
                                        isInvalid={image.length>5}
                                    />
                                  
                                    {
                                        image.length>5&&(
                                            <div class="alert alert-danger mt-2 p-2">
                                                <strong>Danger!</strong> The number of photos does not exceed 5
                                            </div>
                                        )
                                    }


                                    {
                                        image &&image.length!=0?
                                        (
                                            
                                            <ShowImages
                                                image={image}
                                                deleteImage={deleteImage}
                                                update={update}
                                            />
                                            
                                            
                                        ):""
                                    }
                                    

                                    {
                                        prevImages&&
                                        (
                                            
                                            <ShowImages
                                                image={datar.images.map((el,index)=>{
                                                    return {id:el.id_image,name:el.name}
                                                })}
                                                update={update}
                                                deleteImage={null}

                                            />
                                        )
                                    }



                                    
                                    

                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.image}
                                    </Form.Control.Feedback>
                                    <div className="d-flex justify-content-center">

                                    
                                    {
                                        update?(
                                            values.image!=""?(
                                                <PreviewImages file={image} />
                                            ):
                                            (
                                                datar.image!=""&&(
                                                    <img src={ApiConfig.url+"images/"+datar.image}
                                                    alt=""
                                                    style={{maxHeight:"300px"}}
                                                    className="img-thumbnail mt-2"
                                                    height={300}/>
                                                )
                                            )
                                        )
                                        :
                                        (
                                            <PreviewImages file={image} />
                                        )
                                    } 
                                    </div>*/}
                                    
                                </Form.Group>
                            </Col>
                            <Row>
                            <Col className="d-flex justify-content-end mt-3">
                                <Button type="submit" disabled={loding}>
                                    {loding ? spanLoding:""}
                                    {
                                        update?intl.formatMessage({ id: 'SAVE' }):intl.formatMessage({ id: 'SUBMIT' })
                                    }
                                </Button>
                            </Col>
                        </Row>
                        </Row>
                    </Form>

                    )}

            </Formik>
        </>
    );
}
export default AddColis;