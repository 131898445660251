import { useState, useContext, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import StoreService from '../Service/StoreService';
import StatService from '../Service/StatService';
import Loader from '../Views/Loader';
import { StateContext } from "../Context/";
import { Toast, ToastStyle } from "../Views/Toast"
import GoogleMapHome from "../Views/GoogleMapHome"
import Box from './Box'

import { CgUserList } from "react-icons/cg"
import { IoStorefrontOutline } from "react-icons/io5"
import { GoPackage } from "react-icons/go"
import { useNavigate } from "react-router-dom"
import { useIntl } from 'react-intl';
import State from "./State"
import BankState from "./BankState"
import RechargeState from "./RechargeState"

import { AiOutlineDollarCircle, AiFillDollarCircle } from "react-icons/ai"
import { GiAirplaneArrival } from "react-icons/gi"
import { IoCodeWorkingSharp } from "react-icons/io5"

import { MdOutlineErrorOutline, MdSend } from "react-icons/md"
import { BsPatchCheckFill } from "react-icons/bs"
import ApiConfig from '../Config/ApiConfig';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    indexAxis: 'x',
    elements: {
        bar: {
            borderWidth: 1,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Bourse d\'Alger',
        },
    },
};



function Home(props) {

    const intl = useIntl();
    const [data, setData] = useState(null);
    const { user } = useContext(StateContext);
    const navigate = useNavigate()
    const [blackMarket, setBlackMarket] = useState(null)


    const getData = () => {
        StatService.getStat(user)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                let ob = res.data.data.blackMarket;
                setBlackMarket(
                    Object.keys(res.data.data.blackMarket).
                        filter(el => el != "record_no" && el != "create_date_time").
                        map((el, key) => {
                            let c = el.split("_")[0];
                            return {
                                currency: c.toUpperCase(),
                                ...key % 2 == 0 ? {
                                    buy: res.data.data.blackMarket[c + "_buy"],
                                    sale: res.data.data.blackMarket[c + "_sell"]
                                }
                                    : {}
                            }
                        }).
                        filter((el, key) => key % 2 == 0)
                );
            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData();
    }, [])
    const colorsB = [
        "linear-gradient(45deg, #4b5b6e, #ba91ff)",
        "linear-gradient(45deg, rgb(235 80 144), rgb(191 20 215))"
    ]
    const states = [
        intl.formatMessage({ id: 'ATT_PAIE' }),
        intl.formatMessage({ id: 'ATT_VALID' }),
        intl.formatMessage({ id: 'PAIE_ACC' }),
        intl.formatMessage({ id: 'PAIE_REJ' }),
    ]
    const container = data && <Container>

        <Row>
            <Col lg={6} md={12}>

                <Container className="p-0 m-0" style={{ width: "100%" }}>
                    <Row className="p-0">
                        {
                            user && user.rol == 2 &&
                            <Col lg={6} md={6} sm={12} className="p-2">
                                <Box
                                    icon={<CgUserList style={{ color: "#fd847b" }} />}
                                    name={intl.formatMessage({ id: 'USERS' })}
                                    number={data.users}
                                    link={"/users"}
                                    lastNumber={120}
                                    bgcolor={"linear-gradient(45deg,#fc9b71,#fd847b)"}
                                />
                            </Col>
                        }

                        <Col lg={6} md={6} sm={12} className="p-2">
                            <Box
                                icon={<IoStorefrontOutline style={{ color: "#c25fcb" }} />}
                                name={intl.formatMessage({ id: 'ENTREPOT' })}
                                number={data.stores}
                                link={user.rol != 0 ? "/store" : "#"}
                                lastNumber={12}
                                bgcolor={"linear-gradient(45deg,#de4bb5,#c25fcb)"}
                            />
                        </Col>
                        <Col lg={6} md={6} sm={12} className="p-2">
                            <Box
                                icon={<GoPackage style={{ color: "#288c65" }} />}
                                name={"Colis"}
                                number={data.colis}
                                lastNumber={250}
                                link={"/colis"}
                                bgcolor={"linear-gradient(45deg,#30935d,#288c65)"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} >

                            {
                                data == null ?
                                    <Loader /> :
                                    <GoogleMapHome
                                        navigate={navigate}
                                        user={user}
                                        markers={
                                            data.store.map(
                                                (el, key) => {
                                                    return el
                                                }
                                            )
                                        }
                                    />
                            }
                            <h6 className='text-end'>
                                Entrepots Locations
                            </h6>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm={12} className="pt-3">
                            <State
                                text={intl.formatMessage({ id: 'COLIS_ARR' })}
                                icon={<GiAirplaneArrival style={{ color: "#a34ad2" }} />}
                                bgcolor={"linear-gradient(0deg,#a34ad2,#e2afff)"}
                                value={data.colisStates.ca.length}
                                link={"/colis/a"}
                            />

                            <State
                                text={intl.formatMessage({ id: 'COLIS_PREP' })}
                                icon={<IoCodeWorkingSharp style={{ color: "#f07267" }} />}
                                bgcolor={"linear-gradient(0deg,#f07267,#feb593)"}
                                value={data.colisStates.ccp.length}
                                link={"/colis/b"}
                            />
                            <State
                                text={intl.formatMessage({ id: 'COLIS_ATT_PAIE' })}
                                icon={<AiOutlineDollarCircle style={{ color: "#1094e5" }} />}
                                bgcolor={"linear-gradient(0deg,#f07267,#feb593)"}
                                value={data.colisStates.cap.length}
                                link={"/colis/c"}
                            />
                            <State
                                text={intl.formatMessage({ id: 'COLIS_ATT_VALID' })}
                                icon={<AiFillDollarCircle style={{ color: "#1094e5" }} />}
                                bgcolor={"linear-gradient(0deg,#f07267,#feb593)"}
                                value={data.colisStates.cav.length}
                                link={"/colis/d"}
                            />
                            <State
                                text={intl.formatMessage({ id: 'COLIS_PAIE_ACC' })}
                                icon={<AiFillDollarCircle style={{ color: "#33cb78" }} />}
                                bgcolor={"linear-gradient(0deg,#f07267,#feb593)"}
                                value={data.colisStates.cpa.length}
                                link={"/colis/e"}
                            />
                            <State
                                text={intl.formatMessage({ id: 'COLIS_REJ' })}
                                icon={<MdOutlineErrorOutline style={{ color: "#cf1313" }} />}
                                bgcolor={"linear-gradient(0deg,#f07267,#feb593)"}
                                value={data.colisStates.cpr.length}
                                link={"/colis/f"}
                            />
                            <State
                                text={intl.formatMessage({ id: 'COLIS_ENV' })}
                                icon={<MdSend style={{ color: "#1094e5" }} />}
                                bgcolor={"linear-gradient(0deg,#33cb78,#82fab7)"}
                                value={data.colisStates.ce.length}
                                link={"/colis/h"}
                            />
                            <State
                                text={intl.formatMessage({ id: 'COLIS_REC' })}
                                icon={<BsPatchCheckFill style={{ color: "#33cb78" }} />}

                                bgcolor={"linear-gradient(0deg,#33cb78,#82fab7)"}
                                value={data.colisStates.cr.length}
                                link={"/colis/i"}
                            />





                        </Col>


                    </Row>
                </Container>
            </Col>
            <Col lg={6} md={12}>
                <Container className="p-0 m-0" style={{ width: "100%" }}>
                    <Row className="p-0">
                        {
                            data.banks.map((el, key) => {
                                return <Col sm={6} className="p-0">
                                    <BankState
                                        bg={colorsB[key]}
                                        title={"Taux " + el.name}
                                        image={ApiConfig.urlBankImages + el.logo}
                                        cd={JSON.parse(el.currency_data)}
                                    />
                                </Col>
                            })
                        }
                    </Row>
                    <Row>

                        <h4 className="border-bottom mt-3 pb-2">Recharges </h4>
                        <Col sm={6}>
                            <RechargeState
                                text={states[0]}
                                icon={<AiOutlineDollarCircle style={{ color: "#1094e5" }} />}

                                value={data.shippingStates.ap.length}
                                link={"#"}
                            />
                        </Col>
                        <Col sm={6}>
                            <RechargeState
                                text={states[1]}
                                icon={<AiFillDollarCircle style={{ color: "#1094e5" }} />}

                                value={data.shippingStates.av.length}
                                link={"#"}
                            />
                        </Col>
                        <Col sm={6}>
                            <RechargeState
                                text={states[2]}
                                icon={<AiFillDollarCircle style={{ color: "#33cb78" }} />}
                                value={data.shippingStates.pa.length}
                                link={"#"}
                            />
                        </Col>
                        <Col sm={6}>
                            <RechargeState
                                text={states[3]}
                                icon={<MdOutlineErrorOutline style={{ color: "#cf1313" }} />}
                                value={data.shippingStates.pr.length}
                                link={"#"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-2">
                            {
                                data && <Bar options={options} responsive={true} data={
                                    {
                                        labels: blackMarket.map((el) => el.currency),

                                        datasets: [
                                            {
                                                label: 'Achat',
                                                fill:true,
                                                data: blackMarket.map((el) => el.buy),
                                                borderColor: 'rgb(255, 99, 132,0)',
                                                backgroundColor: 'rgba(255,0,0, 0.5)',
                                            },
                                            {
                                                label: 'Vente',
                                                data: blackMarket.map((el) => el.sale),
                                                borderColor: 'rgb(53, 162, 235,0)',
                                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            },
                                        ],
                                    }
                                } />
                            }
                        </Col>
                    </Row>
                    {/* <Row>
                        <h4>Bourse d'Alger</h4>
                        <Col>
                            <table className="table table-sm table-dark">

                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>Achat</td>
                                        <td>Vente</td>
                                    </tr>
                                    {
                                        blackMarket && blackMarket.map((el, key) => {
                                            return <tr>
                                                <td>{el.currency}</td>
                                                <td>{el.buy}</td>
                                                <td>{el.sale}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row> */}
                </Container>
            </Col>
        </Row>

        {/* {
                                data && <Bar options={options} responsive={true} data={
                                    {
                                        labels:blackMarket.map((el) => el.currency),

                                        datasets: [
                                            {
                                                label: 'Achat',
                                                fill:true,
                                                data: blackMarket.map((el) => el.buy),
                                                borderColor: 'rgb(255, 99, 132)',
                                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                            },
                                            {
                                                label: 'Vente',
                                                data: blackMarket.map((el) => el.sale),
                                                borderColor: 'rgb(53, 162, 235)',
                                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            },
                                        ],
                                    }
                                } />
                            } */}


    </Container>
    return (
        data ?
            container : <Loader />
    );
}

export default Home;