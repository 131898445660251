import {useState,useEffect,useContext} from 'react';
import { Link } from 'react-router-dom';
import { Container,Col,Row ,Form} from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { ShippingTable } from '../Views/Table';
import ShippingService from '../Service/ShippingService';
import Loader from '../Views/Loader';
import {Toast,ToastStyle} from "../Views/Toast"
import Modal from "../Views/Modal"
import PreviewImages from "../Colis/PreviewImages"
import styled from "styled-components"
import {StateContext} from "../Context/";
import ApiConfig from '../Config/ApiConfig';

const Upload=styled.div`
img{
    max-height:200px
}

`

const stylebtn={
    minWidth:"100px"
}
function ListShipping(props) {
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const [action,setAction]=useState(null);
    const [data,setData]=useState(null);
    const [image,setImage]=useState(null)
    const {user } = useContext(StateContext);
    
    const getData=()=>{
        ShippingService.getShippings()
        .then(res=>{
            setData(res.data.data);
        }).catch(err=>{
            setData([])
            Toast.error(err.message,ToastStyle);
        })
    }
  
    useEffect(()=>{
        getData();
    },[])


    const showModalF=(type,ob)=>{
        
        setAction({type,data:ob});
        setShowModal(true)
    }
    const hideModallF=()=>{
        setImage(null)
        setShowModal(false)
        //setAction(null)
        
    }
    const [loding,setLoding]=useState(false);
    const [disable,setDisable]=useState(false);
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
   
    
    const handleSubmitAdd=(e)=>{
        sendProof()
    }
    const sendProof=()=>{
        let data=new FormData();
        data.append("id_shipping",action.data.id_shipping);
        data.append("image",image);
        data.append("imagefor",action.type);
        setLoding(true)
        ShippingService.sendProof(data)
        .then(res=>{
           if(res.data.error==""){
                Toast.success("send success",ToastStyle);
                getData()
                hideModallF() 
           }else{
                Toast.error(res.data.error,ToastStyle);
           }
           setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
        
    return (
        <>
           
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <Link to="/shipping/add" className="btn btn-primary">
                        Recharger
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                {
                    data===null ?  <Loader no={true} /> :
                    <ShippingTable
                    showModalF={showModalF}
                    user={user}
                    data={
                        data.map((el,key)=>{
                            return {
                                id_shipping:el.id_shipping,
                                fname:el.fname,
                                lname:el.lname,
                                shipping_info:el.shipping_info,
                                currency_to:el.currency_to,
                                amount:el.amount,
                                logo:el.logo,
                                img_user_confirm:el.img_user_confirm,
                                img_admin_confirm:el.img_admin_confirm,
                                state:el.state,
                                action:{
                                    data:el
                                }
                            }
                        })
                    }/>
                }
                </Col>
            </Row>
            
            <Modal
                show={showModal}
                showbtn={false}
                title={"Télécharger une preuve "}
                onHide={hideModallF}
            >
                
                <>
                    <div>
                        <Form.Group controlId="logo">
                            <Form.Label>Preuve photo</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e)=>{setImage(e.target.files[0]);setDisable(true);setTimeout(()=>{setDisable(false)},10);}}
                            />
                            {
                                image&&<Upload className="mt-1 d-flex justify-content-center align-items-center" style={{height:"200px"}}>
                                    <PreviewImages file={image}  />
                                </Upload>||
                                !image&&action&&action.data.image&&<Upload className="mt-1 d-flex justify-content-center align-items-center" style={{height:"200px"}}>
                                    <img src={ApiConfig.urlShippingImages+action.data.image}/>
                                </Upload>
                            }
                        </Form.Group>
                    </div>
                    <div className='d-flex justify-content-end mt-2'>
                        <button className='btn btn-success me-1' style={stylebtn} disabled={loding ||disable||image==null} onClick={handleSubmitAdd}>
                            {loding ? spanLoding:""}

                            upload
                        </button>
                        <button className='btn btn-danger' style={stylebtn} onClick={hideModallF}>
                            {intl.formatMessage({ id: 'CLOSE' })}
                        </button>
                    </div>
                </>
            </Modal>
            
        </>
    );
}

export default ListShipping;