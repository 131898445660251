
import { Container, } from 'react-bootstrap';
import { useLocation} from 'react-router-dom';
import StoreList from './StoreList';
import AddStore from './AddStore';
import Settins from './Settings';
function Store(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/store" : return <StoreList/>;
            case "/store/add" : return <AddStore update={false}/>;
            case "/store/edit" : return <AddStore update={true}/>;
            case "/store/settings" : return <Settins/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Store;