import {useState,useEffect,useContext} from 'react';
import { UsersTable } from '../Views/Table';
import { Container,Col,Row } from 'react-bootstrap';
import UserService from '../Service/UserService';
import {StateContext} from "../Context/";
import {Toast,ToastStyle} from "../Views/Toast"
import Loader from '../Views/Loader';
import Modal from "../Views/Modal"
import { useIntl } from 'react-intl';


const stylebtn={
    minWidth:"100px"
}
function Users(props) {
    const [data,setData]=useState(null);
    const [store,setStore]=useState(null);
    const {user } = useContext(StateContext);
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState(null);
    const getData=()=>{
        UserService.getAll(user)
        .then(res=>{
            setData(res.data.data);
            setStore(res.data.store);
        }).catch(err=>{
            Toast.error(err.message,ToastStyle);
        })
    }
    useEffect(()=>{
        getData();
    },[])

    const [storeid,setStoreid]=useState(null);

    const updateChange=(e)=>{
        setStoreid(e.target.value)
    }
    const changeRolUser=(id_user,rol)=>{
        UserService.changeRolUser(id_user,rol)
        .then(res=>{
            getData();
        }).catch(err=>{
            Toast.error(err.message,ToastStyle);
        })
    }

    const addStore=(add_store)=>{

        setLoding(true)
        UserService.AddStoreToUser(action.id_user,storeid, add_store)
        .then(res=>{
            getData();
            setLoding(false)
            hideModallF()
        }).catch(err=>{
            Toast.error(err.message,ToastStyle);
            setLoding(false)
            hideModallF()
        })
    }

    const setShowModalF=(action,id_user)=>{
        setAction(
            {
                action,
                id_user
            }
        )
        setShowModal(true)
    }
    const hideModallF=()=>{
        setStoreid(null)
        setShowModal(false)
    }
    const [loding,setLoding]=useState(false);
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    
    const intl = useIntl();

    return (
        <Container>
            <Row>
                <Col>
                {
                    data===null ?  <Loader no={true} /> :
                    <UsersTable
                    setShowModal={setShowModalF}
                    changeRolUser={changeRolUser}
                    user={user}
                    data={data.map(el=>{
                        return[
                            el.id_user,el.id_exped,el.fname,el.lname,
                            el.birth_date,el.gender,el.email,el.phone,el.rol,el.edit_date,el.add_date,{id_store:el.id_store,name:el.name}
                        ]

                    })}
                    
                    />
                }
                    
                </Col>
            </Row>
            {data&&<Modal
                show={showModal}
                showbtn={false}
                title={
                    action&&(
                        action.action=="delete"?"Voulez-vous supprimer?":"Ajouter un Entrepot"
                    )
                }
                onHide={hideModallF}
            >
                {
                    action&&(
                        <>
                        {
                            action.action=="delete"?(
                            <div className='d-flex justify-content-end'>
                                <button className='btn btn-danger me-1' style={stylebtn} disabled={loding} onClick={()=>addStore(false)}>
                                    {loding ? spanLoding:""}
                                    {intl.formatMessage({ id: 'YES' })}
                                </button>
                                <button className='btn btn-success' style={stylebtn} onClick={hideModallF}>{intl.formatMessage({ id: 'NO' })}</button>
                            </div>
                            ):
                            (
                            <>
                            <ul className="list-group">
                            {
                                store.map((el,key)=>{
                                    return <li className="list-group-item " style={{border:"none"}}>
                                        <input type="radio" class="form-check-input me-2" onChange={updateChange} name="optradio" value={el.id_store} ></input>
                                        {el.name}
                                        
                                        </li>
                                  
                                })
                            }
                            </ul>
                            <div className='d-flex justify-content-end mt-2'>
                                <button className='btn btn-success me-1' style={stylebtn} disabled={storeid==null||loding} onClick={()=>addStore(true)}>
                                    {loding ? spanLoding:""}
                                    {intl.formatMessage({ id: 'ADD' })}
                                </button>
                                <button className='btn btn-danger' style={stylebtn} onClick={hideModallF}>Annuler</button>
                            </div>
                            </>
                            )
                        }
                        </>
                    )
                }
            </Modal>}
        </Container>
    );
}
export default Users;



