
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  // apiKey: "AIzaSyARwV0u8mdUqVB76_CbM7Gow48UQZqPP8Q",
  // authDomain: "exped-f18ee.firebaseapp.com",
  // projectId: "exped-f18ee",
  // storageBucket: "exped-f18ee.appspot.com",
  // messagingSenderId: "119303193189",
  // appId: "1:119303193189:web:989a58de629f7af7e83c8c",
  // measurementId: "G-38SRB9V3ZW"


  apiKey: "AIzaSyC7z7ivwWO1VaQ7pL9DgRYo-bgd46MLFFo",
  authDomain: "exped-53970.firebaseapp.com",
  projectId: "exped-53970",
  storageBucket: "exped-53970.appspot.com",
  messagingSenderId: "759105741320",
  appId: "1:759105741320:web:ac7d77fecae9c43e9ee7bd",
  measurementId: "G-JD1JD9J7T8"



};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
