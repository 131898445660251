import React from 'react';
import BanksList from "./BanksList"
import AddBank from "./AddBank"
import {Container} from "react-bootstrap"
import { useLocation} from 'react-router-dom';

function Bank(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/tools" : return <BanksList/>;
            case "/tools/bank/add" : return <AddBank update={false}/>;
            case "/tools/bank/edit" : return <AddBank update={true}/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container className="p-0 m-0" style={{width:"100%"}}>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Bank;