
import { Container } from 'react-bootstrap';
import { useLocation} from 'react-router-dom';
import ListShipping from "./ListShipping"
import AddShipping from "./AddShipping"
import HowTo from './HowTo';
import Calculator from "./Calculator"
function Shipping(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/shipping" : return <ListShipping/>;
            case "/shipping/add" : return <AddShipping update={false}/>;
            case "/shipping/edit" : return <AddShipping update={true}/>;
            case "/shipping/how_to" : return <HowTo/>;
            case "/shipping/calculator" : return <Calculator/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Shipping;