import { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useIntl } from 'react-intl';
import StoreService from "../Service/StoreService"
import { Toast, ToastStyle } from "../Views/Toast"
import Select from 'react-select';
import ApiConfig from '../Config/ApiConfig';
import Loader from "../Views/Loader"
import styled from 'styled-components';
import images from '../../assets/images';
const DivC = styled.div`
padding-top:80px;
padding-bottom:80px;
background-color:#f5f5f5;
color:#111;
img{
    max-width:300px;
    width:100%;
}
.form-control{
    background-color:#FFF!important;
    height:50px!important;
}
.labelx{
    height:50px!important;
    width:50px!important;
    color: #fff;
    text-align:center;
    background-color:#222;
    line-height:50px;
}
.css-1pahdxg-control{
    box-shadow: none;
    border:1px solid #AAA5!important;
    height:50px;
}

.css-9gakcf-option{
    background-color:var(--main-color)!important;   
}
.css-1n7v3ny-option {
    background-color:#10a1f50e!important;  
}
.css-b62m3t-container{
    min-width: 140px;
    
}
.css-1s2u09g-control{
    height:50px;
}
.css-1s2u09g-control,.form-control{
    border-radius:0;
    border:1px solid #AAA5!important;
}

button{
    width:100%;
    height:50px;
    border-radius:0;
}

`


function Calc(props) {
    const [wv, setWv] = useState({
        we: "",
        l: "",
        h: "",
        w: ""
    })
    const [data, setData] = useState(null);
    const [store, setStore] = useState(null);
    const [price, setPrice] = useState(null);
    const [eur, setEur] = useState(null);
    const [country, setCountry] = useState(null);
    const [tc, setTc] = useState(null)
    const updateVal = (e) => {
        let value = e.target.value.replaceAll(",", ".");
        if (isNaN(value)) return;
        switch (e.target.id) {
            case "we": setWv({ ...wv, we: value }); break;
            case "w": setWv({ ...wv, w: value }); break;
            case "l": setWv({ ...wv, l: value }); break;
            case "h": setWv({ ...wv, h: value }); break;
        }
    }

    const getData = () => {
        StoreService.Calculator()
            .then(res => {
                setData(res.data.data.map(el => {
                    return { country: el.country, price_data: JSON.parse(el.price_data) }
                }));
                setTc(res.data.transport.map(el => {
                    return { id: el.id_tc, logo: el.logo }
                }))
                setEur(parseFloat(res.data.parameter.eur_to_dinar));

            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    const buttonDisable = () => {
        return !store || !country || (wv.we == 0 && (wv.l * wv.h * wv.w == 0))
    }
    useEffect(() => {
        getData();
    }, [])
    const intl = useIntl();
    const handleSubmitLogin = (e) => {
        e.preventDefault();
        let ob = data.find(el => el.country == store.label);
        console.log(ob)
        console.log(country)
        let transport = ob.price_data.find(el => el.name == country.label).transport;
        console.log(transport)
        let u = (wv.l * wv.h * wv.w) / 5000.0;
        let r = (wv.we > u) ? wv.we : u;

        let values = [];

        let k = transport.map(item => {
            item.calc.map(el => {
                if (r > parseFloat(el.from) && r <= parseFloat(el.to))
                    values.push({ id: item.id, from: item.duration_from, to: item.duration_to, price: el.price, poid: r })
            })
        })
        setPrice(values)
    }
    const getDinar = (v) => {
        let x = v % 1000;
        let y = Math.ceil(x / 100) * 100 + Math.floor(v / 1000) * 1000;
        return y;
    }
    return (
        <DivC>

            <Container>
            <Row>
                <Col lg={6} md={12} className="d-flex justify-content-center align-items-start">
                <img src={images.imgc} alt="" />
                </Col>
                <Col lg={6} md={12} >
                    {
                        data ?

                            <Form onSubmit={handleSubmitLogin}>
                                <Container>
                                    <Row className="pt-2 pb-2">
                                        <h1 className="title-section">
                                            Calculez les frais d'expédition
                                        </h1>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="mb-3" controlId="from">
                                                <Form.Label>Sélectionnez un entrepot:</Form.Label>
                                                <Select
                                                    value={store}
                                                    defaultValue={store}
                                                    onChange={setStore}
                                                    isSearchable={true}
                                                    options={data.map((el, key) => {
                                                        return {
                                                            label: el.country,
                                                            value: el.country,
                                                            price_data: el.price_data
                                                        }
                                                    })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="mb-3" controlId="to">
                                                <Form.Label>{intl.formatMessage({ id: 'TO' })}</Form.Label>
                                                <Select
                                                    value={country}
                                                    defaultValue={country}
                                                    onChange={setCountry}
                                                    isSearchable={true}
                                                    options={!store ? [] : store.price_data.map((el, key) => {
                                                        return {
                                                            label: el.name,
                                                            value: el.name
                                                        }
                                                    })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>Dimensions</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={6}>
                                            <Form.Group className="mb-3 d-flex" controlId="we">
                                                
                                                <Form.Control type="text" value={"" + wv.we} onChange={updateVal} 
                                                placeholder={intl.formatMessage({ id: 'WEIGHT' })}
                                                className="flex-grow-1"/>
                                                <div className="labelx">{intl.formatMessage({ id: 'KG' })}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={6}>
                                            <Form.Group className="mb-3 d-flex" controlId="l">
                                                
                                                <Form.Control type="text" value={"" + wv.l} onChange={updateVal} 
                                                placeholder={intl.formatMessage({ id: 'LENGTH' })}
                                                className="flex-grow-1"/>
                                                <div className="labelx">{intl.formatMessage({ id: 'CM' })}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={6}>
                                            <Form.Group className="mb-3 d-flex" controlId="w">
                                                
                                                <Form.Control type="text" value={"" + wv.w} onChange={updateVal} 
                                                placeholder={intl.formatMessage({ id: 'WIDTH' })}
                                                className="flex-grow-1"/>
                                                <div className="labelx">{intl.formatMessage({ id: 'CM' })}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={6}>
                                            <Form.Group className="mb-3 d-flex" controlId="h">
                                                
                                                <Form.Control type="text" value={"" + wv.h} onChange={updateVal} 
                                                placeholder={intl.formatMessage({ id: 'HEIGHT' })}
                                                className="flex-grow-1"/>
                                                <div className="labelx">{intl.formatMessage({ id: 'CM' })}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex justify-content-end">
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="wv" >
                                                <Form.Label>{intl.formatMessage({ id: 'VOL_WEIGHT' })} ({intl.formatMessage({ id: 'KG' })})</Form.Label>
                                                <Form.Control type="number" disabled={true} value={wv.w * wv.h * wv.l / 5000} placeholder={intl.formatMessage({ id: 'VOL_WEIGHT' })}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="d-flex justify-content-end">
                                            <Button type="submit" disabled={true == buttonDisable()}>
                                                Estimer les couts d'expédition 
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="mt-3 mb-3">
                                            {price && <table className="table">

                                                <tbody>
                                                    <tr>
                                                        <th>Mode de livraison</th>
                                                        <th>Délai de livraison</th>
                                                        <th>Poids</th>
                                                        <th>Prix total</th>
                                                        <th>Prix en dinar</th>
                                                    </tr>
                                                    {
                                                        price.map(el => {
                                                            let logo = tc.find(item => item.id == el.id).logo
                                                            return <tr>
                                                                <td><img src={ApiConfig.urlTransportCompani + logo} height='25' alt="" /></td>
                                                                <td>{el.from} à {el.to} jours</td>
                                                                <td>{el.poid} Kg</td>
                                                                <td>{el.price} EUR</td>
                                                                <td>{getDinar(el.price * eur)} DZD </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>}
                                        </Col>
                                    </Row>
                                    {!props.hideTitle && <Row>
                                        <Col>
                                            <h5>{intl.formatMessage({ id: 'NOTES' })}</h5>
                                            <h6>Smartphones are prohibited at Chronopost</h6>
                                            <p>
                                                Flammable products are prohibited at Chronopost and DHL: toiletries, non-radioactive drugs
                                                (including aerosols), hair spray, deodorant, perfume, cologne,
                                                nail polish remover, etc. and drugs containing alcohol.
                                                <Link to="/calculator/notes">(Complete list)</Link>
                                            </p>
                                        </Col>
                                    </Row>
                                    }
                                </Container>
                            </Form> :
                            <Loader no={true} />
                    }
                </Col>
            </Row>
            </Container>
        </DivC>

    );
}

export default Calc;