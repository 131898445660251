import React from 'react';
import styled from "styled-components"
import {FiSettings} from "react-icons/fi"
import {Link} from "react-router-dom"

const DivC=styled.div`
/* height: 120px; */
border-radius: 4px;
overflow:hidden;
position: relative;
.icon svg{
    color:#333;

}
.tw{
    color:#333!important;
}
.icon{
    background-color: #FFF;
    /* width: fit-content; */
    border-radius: 50%;
}
.icon svg{
    font-size: 32px;
}
h4{
    font-size: 18px;
    font-weight: 800;
}
.fw-500{
    font-weight: 500!important;
}
.badgee{
    font-size: 14px;
}

`

function State({text,number,bgcolor,icon,value,link}) {
    return (
        <Link to={link}>
            <DivC className='p-1 mt-1 '>
                <div className='d-flex align-items-center border-bottom'>
                    <div className="icon me-2 ms-2">{icon}</div>
                    
                    <div className='tw flex-grow-1 fw-500'>
                        {text}
                    </div>
                    <span class="badgee tw me-3">{value}</span>
                </div>

                {/* <div className=" d-flex p-1 flex-column flex-grow-1">
                    <div className="icon">{icon}</div>
                    <h5 className='tw flex-grow-1 d-flex  align-items-center fw-500'>
                        {text}
                    </h5>
                </div>
                
                <div className="d-flex  align-items-end">
                    <h4 className="tw p-0 m-0">{value}</h4>
                </div> */}
            </DivC>
        </Link>
    );
}

export default State;