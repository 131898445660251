


import styled from "styled-components"
import PreviewImages from "./PreviewImages"
import {Col,Row} from 'react-bootstrap';
import {MdClear,MdDelete} from "react-icons/md"

import ApiConfig from "../Config/ApiConfig";
const DivC=styled.div`
border: 1px dotted #555;
border-radius: 10px;
display: flex;
flex-wrap: wrap;
justify-content: center;
.item1{
    border:1px solid #DDD;
    border-radius: 10px;
    height:200px;
    width: 200px;
    position: relative;
    overflow: hidden;
    padding: 0!important;
    overflow: hidden;
    cursor: pointer;
}
.item1 img{
    /* height:100%; */
    margin: 0!important;
    padding: 0!important;
    transition:filter .3s;
}
.item1:hover img{
    filter: blur(4px);
}
.item1:hover .btnE{
    top: 10px;
}
.btnE{
    position: absolute;
    top:-50px;
    right:10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color:#da0000;
    font-size: 22px;
   
    transition: top .3s;
}
`
function ShowImages({image,deleteImage,update}) {
    

    
    return (
        <DivC className='p-3 m-0 mt-2 '>
                {
                    
                    image.map(
                        (item, index) => {
                            return (
                                <div key={index} className="item1 m-2 p-0 d-flex justify-content-center align-items-center" lg={3} md={4} sm={6}>
                                    
                                    {
                                        !update?
                                        <>
                                            <PreviewImages file={item}  />
                                            <MdClear className="btnE"  onClick={() => deleteImage(index)}/>
                                        </>
                                        :
                                        <>
                                            <img src={ApiConfig.urlColisImages+""+item.name}
                                                alt={""}
                                                className="imgU"
                                            />
                                            <MdDelete className="btnE"  onClick={() => deleteImage(index)}/>
                                        </>
                                    }
                                    
                                </div>
                            );
                        }
                    )
                   

                }   
            
        </DivC>
    );
}

export default ShowImages;