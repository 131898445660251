import React, { useState, useEffect } from 'react';

import { Col, Row, Form, Container, Button, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import { Toast, ToastStyle } from "../Views/Toast"
import { MdOutlineDelete } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import ShippingService from "../Service/ShippingService"
import { useIntl } from 'react-intl';
import AddShipping from '../Shipping/AddShipping';
import PreviewImages from "../Colis/PreviewImages"
import styled from "styled-components"
import ApiConfig from '../Config/ApiConfig';
import Select from 'react-select';
const Upload = styled.div`
img{
    max-height:200px
}

`



const storeShema = Yup.object().shape({

    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
});

function AddBank({ update }) {
    const [loding, setLoding] = useState(false);
    const location = useLocation()
    const intl = useIntl();

    const datar = location.state;
    const [shipping_data, setShipping_data] = useState(!update ? [{ item: "Email", value: "" }] : datar.shipping_data)
    const [logo, setLogo] = useState(null)

    const currency = datar.currency.map((el, key) => { return { label: el.code, value: el.code, sale: "0", buy: "0" } })


    const [currency_data, setCurrency_data] = useState(
        !update ? null :
            datar.currency_data
    )
    const [visaData, setVisaData] = useState(
        !update ? { delivery_time: 0, price: 0 } :
            datar.visa_data
    );

    const clear = (actions) => {
        setTimeout(() => {
            actions.setSubmitting(false)
            actions.resetForm();
            setLogo(null);
            setShipping_data([{ item: "Email", value: "" }])
            setCurrency_data(null)
        }, 200);
    }
    const add_bank = (ob, actions) => {
        setLoding(true)
        ShippingService.addBank(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("Add success", ToastStyle);
                    clear(actions)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    const update_bank = (ob) => {
        setLoding(true)
        ShippingService.updateBank(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("update success", ToastStyle);
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }


    const update_shipping = (e, index, item) => {
        setShipping_data(shipping_data.map((el, key) => {
            if (item)
                return key == index ? { item: e.target.value, value: el.value } : el
            else
                return key == index ? { item: el.item, value: e.target.value } : el
        }))

    }
    const updateCurrencyPrice = (e, index, vent) => {
        setCurrency_data(currency_data.map((el, key) => {
            if (vent)
                return key == index ? { ...el, sale: e.target.value } : el
            else
                return key == index ? { ...el, buy: e.target.value } : el
        }))
    }
    const addShipping = (index) => {
        let tab = [...shipping_data];
        tab.splice(index + 1, 0, { item: "", value: "" })

        setShipping_data(tab)
    }
    const deleteShipping = (index) => {
        if (shipping_data.length > 1)
            setShipping_data(
                shipping_data.filter((el, key) => {
                    return (key != index);
                }))
    }

    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )


    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <Container className="p-0 m-0" style={{ width: "100%" }}>
            <Row className="">
                <Col className="d-flex justify-content-end">
                    <Link to="/tools" className="btn btn-default">
                        {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
            </Row>
            <Formik
                initialValues={{
                    name: !update ? '' : datar.name,
                }}

                validationSchema={storeShema}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("name", values.name);
                    data.append("currency_data", JSON.stringify(
                        currency_data.map((el, key) => {
                            return {
                                currency: el.value,
                                sale: el.sale,
                                buy: el.buy
                            }
                        })
                    ));
                    data.append("shipping_data", JSON.stringify(shipping_data));
                    data.append("visa_data", JSON.stringify(visaData));
                    data.append("logo", logo);
                    data.append(!update ? "add_bank" : "update_bank", true);
                    if (update)
                        data.append("id_bank", datar.id_bank);
                    if (!update)
                        add_bank(data, actions)
                    else
                        update_bank(data)
                }}
            >
                {
                    (
                        {
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors
                        }
                    ) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="p-0 m-0">
                                <Col sm={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>{intl.formatMessage({ id: 'NAME' })}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Monnaie</Form.Label>
                                        <Select
                                            value={currency_data}
                                            defaultValue={update && currency_data}
                                            onChange={setCurrency_data}
                                            closeMenuOnSelect={false}
                                            // onChange={update_currency}
                                            isMulti={true}
                                            isSearchable={true}

                                            options={currency}
                                        />

                                        <Container className="p-0 m-0 " style={{ width: "100%" }}>
                                            {
                                                currency_data && currency_data.map((el, key) => {
                                                    return <Row className="p-0 m-0">
                                                        <Col sm={12} className="p-0">
                                                            {el.label}
                                                        </Col>
                                                        <Col className="p-0 me-1">
                                                            <InputGroup className="mt-1">
                                                                <InputGroup.Text >Vendre</InputGroup.Text>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={el.sale}
                                                                    onChange={(e) => updateCurrencyPrice(e, key, true)}
                                                                />
                                                                <InputGroup.Text >DA</InputGroup.Text>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col className="p-0">
                                                            <InputGroup className="mt-1">
                                                                <InputGroup.Text >Achat</InputGroup.Text>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={el.buy}
                                                                    onChange={(e) => updateCurrencyPrice(e, key, false)}
                                                                />
                                                                <InputGroup.Text >DA</InputGroup.Text>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                })
                                            }
                                        </Container>
                                    </Form.Group>
                                </Col>
                                <div className='p-4 m-3'></div>
                                <Col sm={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>{intl.formatMessage({ id: 'SHIPPING_REQ' })}</Form.Label>
                                        <>
                                            {
                                                shipping_data.map((el, key) => {
                                                    return <div className='d-flex align-items-center mt-1'>
                                                        <input className="form-control" value={el.item} onChange={(e) => update_shipping(e, key, true)} />
                                                        <span className="btn btn-default btn-sm ms-1 border" onClick={() => deleteShipping(key)}><MdOutlineDelete /></span>
                                                        <span className="btn btn-default btn-sm ms-1 border" onClick={() => addShipping(key)}><FiPlus /></span>
                                                    </div>
                                                })
                                            }
                                        </>
                                    </Form.Group>
                                </Col>

                                <Col sm={12}>
                                    <Form.Group controlId="">
                                        <>
                                            {
                                                shipping_data.map((el, key) => {
                                                    return <InputGroup className="mt-1">
                                                        <InputGroup.Text id="basic-addon1">{el.item}</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={el.value}
                                                            onChange={(e) => update_shipping(e, key, false)}
                                                        />
                                                    </InputGroup>
                                                })
                                            }
                                        </>
                                    </Form.Group>
                                </Col>

                                <div className='p-4 m-3'></div>
                                <Col sm={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Visa information</Form.Label>
                                        <Container className="p-0 m-0 " style={{ width: "100%" }}>
                                            <Row className="p-0 m-0">

                                                <Col className="p-0 me-1" md={12} >
                                                    <InputGroup className="mt-1">
                                                        <InputGroup.Text >Heure de livraison</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={visaData.delivery_time}
                                                            onChange={(e) => setVisaData({ ...visaData, delivery_time: e.target.value })}
                                                        />
                                                        <InputGroup.Text >jours</InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                                <Col className="p-0" md={12} >
                                                    <InputGroup className="mt-1" >
                                                        <InputGroup.Text >Prix</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={visaData.price}
                                                            onChange={(e) => setVisaData({ ...visaData, price: e.target.value })}
                                                        />
                                                        <InputGroup.Text >EUR</InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form.Group>
                                </Col>


                                <Col sm={12}>
                                    <Form.Group controlId="logo">
                                        <Form.Label>Logo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="logo"
                                            accept="image/*"
                                            onChange={(e) => { handleChange(e); setLogo(e.target.files[0]); }}

                                        />
                                        {
                                            logo ? <Upload className="mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                                <PreviewImages file={logo} />
                                            </Upload> : (
                                                update &&
                                                <Upload className="mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                                    <img src={ApiConfig.urlBankImages + datar.logo} />
                                                </Upload>
                                            )
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end mt-3">
                                    <Button type="submit" disabled={loding}>
                                        {loding ? spanLoding : ""}
                                        {
                                            update ? intl.formatMessage({ id: 'SAVE' }) : intl.formatMessage({ id: 'SUBMIT' })
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                    )}

            </Formik>
        </Container>
    );
}

export default AddBank;