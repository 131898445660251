import React from 'react';
import styled from "styled-components"
import {FiSettings} from "react-icons/fi"
import {Link} from "react-router-dom"

const DivC=styled.div`

.tw{
    color:#333!important;
}
.icon{
    height:100%!important
}
.icon svg{
    font-size:45px!important;
}

`

function RechargeState({text,number,bgcolor,icon,value,link}) {
    return (
        <Link to={link}>
            <DivC className=' mt-1 '>
                <div className='d-flex border-bottom '>

                    <div className="icon me-2 ms-2 align-self-center  d-flex">
                        {icon}
                    </div>
                    
                    <div className='d-flex flex-grow-1 flex-column'>
                        <div className='tw fw-500'>
                            {text}
                        </div>
                        <h1  className=' flex-grow-1  text-center tw'>{value}</h1>
                    </div>
                </div>

            </DivC>
        </Link>
    );
}

export default RechargeState;