import MUIDataTable, { TableCell } from "mui-datatables";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BiEditAlt } from "react-icons/bi"
import { MdOutlineContentCopy, MdDelete, MdSend, MdEdit, MdSettings, MdOutlineInfo } from "react-icons/md"
import useClipboard from 'react-hook-clipboard'
import { toast } from "react-toastify"
import { IoLocationSharp } from "react-icons/io5"
import { FiPlus, FiUpload } from "react-icons/fi"
import { FaDollarSign } from "react-icons/fa"
import { HiBadgeCheck } from "react-icons/hi"
import ApiConfig from "../Config/ApiConfig";
import CollectImages from "./CollectImages";
import ImageModal from "./ImageModal";
import Switch from "react-switch";
import { useIntl } from 'react-intl';
import { FaExchangeAlt } from "react-icons/fa";
import Tootlip from '../Views/Tootlip';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();





const getOptios = (intl) => {
    return {
        textLabels: {
            body: {
                noMatch: intl.formatMessage({ id: 'TAB_NOMATCH' }),
                toolTip: intl.formatMessage({ id: 'TAB_TOOLTIP' }),
                columnHeaderTooltip: column => `${intl.formatMessage({ id: 'TAB_COL_TOOLTIP' })} ${column.label}`
            },
            pagination: {
                next: intl.formatMessage({ id: 'TAB_NEXT' }),
                previous: intl.formatMessage({ id: 'TAB_PREV' }),
                rowsPerPage: intl.formatMessage({ id: 'TAB_ROWSPERPAGE' }),
                displayRows: intl.formatMessage({ id: 'TAB_DISPLAY_ROW' }),
            },
            toolbar: {
                search: intl.formatMessage({ id: 'TAB_SEARCH' }),
                downloadCsv: intl.formatMessage({ id: 'TAB_DOWNCSV' }),
                print: intl.formatMessage({ id: 'TAB_PRINT' }),
                viewColumns: intl.formatMessage({ id: 'TAB_VCOL' }),
                filterTable: intl.formatMessage({ id: 'TAB_FILTER' }),
            },
            filter: {
                all: intl.formatMessage({ id: 'TAB_ALL' }),
                title: intl.formatMessage({ id: 'TAB_FILTITLE' }),
                reset: intl.formatMessage({ id: 'TAB_RESET' }),
            },
            filterType: intl.formatMessage({ id: 'TAB_FILTYPE' }),
            responsive: intl.formatMessage({ id: 'TAB_RESPONSIVE' }),
            viewColumns: {
                title: intl.formatMessage({ id: 'TAB_VIEWTITLE' }),
                titleAria: intl.formatMessage({ id: 'TAB_VIEWAREA' }),
            },
            selectedRows: {
                text: intl.formatMessage({ id: 'TAB_SELROWTEXT' }),
            },
        },
        selectableRows: false,
        selectableRowsHeader: false,
        responsive: 'standard',
    }
}
export const StoreTable = (props) => {
    const navigate = useNavigate();
    const intl = useIntl();
    return <MUIDataTable
        title={intl.formatMessage({ id: 'STORE_LIST' })}
        columns={[
            {
                label: intl.formatMessage({ id: 'ID' }),
                name: "id_store",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'NAME' }),
                name: "name",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'COUNTRY' }),
                name: "country",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'STATEC' }),
                name: "state",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'CITY' }),
                name: "city",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ZIP_CODE' }),
                name: "zip_code",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ADDRESS_LINE' }) + " 1",
                name: "address_line1",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ADDRESS_LINE' }) + " 2",
                name: "address_line2",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'COMPANY' }),
                name: "company",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'EMAIL' }),
                name: "email",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'PHONE' }),
                name: "phone",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'LOCATION' }),
                name: "location",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div><button className="btn btn-warning"><IoLocationSharp /></button></div>
                    )
                }
            },
            {
                label: "Disable",
                name: "disable",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex justify-content-center flex-column">
                            {value == 0 ? "Disabled" : "Active"}
                            {props.editable && <Switch
                                onChange={() => props.changeStateStore(tableMeta.rowData[0], value)}
                                checked={value == 1 ? true : false}
                            />}
                        </div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ACTION' }),
                name: "action",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex align-items-center">

                            {
                                props.editable && <>
                                    <button className="btn btn-success btn-sm me-2" onClick={() => {
                                        let ar = tableMeta.rowData;
                                        navigate("/store/edit", {
                                            state: {
                                                id_store: ar[0],
                                                name: ar[1],
                                                country: ar[2],
                                                state: ar[3],
                                                city: ar[4],
                                                zip_code: ar[5],
                                                address_line1: ar[6],
                                                address_line2: ar[7],
                                                company: ar[8],
                                                email: ar[9],
                                                phone: ar[10],
                                                location: ar[11],
                                                countries: props.country
                                            }
                                        })
                                    }}><BiEditAlt /></button>
                                    <button onClick={() => {
                                        let ar = tableMeta.rowData;
                                        navigate("/store/settings", {
                                            state: {
                                                id_store: value.id_store,
                                                country: props.country,
                                                price_data: JSON.parse(value.price_data),
                                                transport: props.transport
                                            }
                                        })
                                    }} className="btn btn-secondary btn-sm me-2 ">
                                        <MdSettings />
                                    </button>
                                </>
                            }

                            <button onClick={() => {
                                let ar = tableMeta.rowData;
                                navigate("/colis/add", {
                                    state: {
                                        id_store: ar[0]

                                    }
                                })
                            }} className="btn btn-warning btn-sm">
                                <FiPlus />
                            </button>
                        </div>
                    )
                }
            },
        ]}
        options={getOptios(intl)}
        {...props}
    />
}




export const ShippingTable = (props) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const states = [
        intl.formatMessage({ id: 'ATT_PAIE' }),
        intl.formatMessage({ id: 'ATT_VALID' }),
        intl.formatMessage({ id: 'RECHARGE_DONE' }),
        intl.formatMessage({ id: 'PAIE_REJ' }),
    ]
    return <MUIDataTable
        title={"Historique"}
        columns={[
            {
                label: intl.formatMessage({ id: 'ID' }),
                name: "id_shipping",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'FNAME' }),
                name: "fname",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'LNAME' }),
                name: "lname",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: "Exigences d'expédition",
                name: "shipping_info",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex flex-column">
                            {
                                JSON.parse(value).map((el, key) => {
                                    return <div>
                                        <strong>{el.item} : </strong>
                                        {el.value}
                                    </div>
                                })
                            }
                        </div>
                    )
                }
            },

            {
                label: "Devise",
                name: "currency_to",
                options: {
                    filter: false,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">
                            {value}
                        </div>
                    )
                }
            },
            {
                label: "Montant",
                name: "amount",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">
                            <strong>{value} {tableMeta.rowData[4]}</strong>
                        </div>
                    )
                }
            },
            {
                label: "Preuve de paiement",
                name: "img_user_confirm",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex justify-content-center align-items-center">
                            {
                                value == "" ? (<div className="d-flex flex-column">
                                    <button className="btn btn-secondary btn-sm"
                                        onClick={() => props.showModalF("img_user_confirm", { id_shipping: tableMeta.rowData[0], image: value })}>
                                        <FiUpload />
                                    </button>
                                    Preuve de versement de votre part
                                </div>) :
                                    <>
                                        <ImageModal src={ApiConfig.urlShippingImages + value}/>
                                        {/* <img src={ApiConfig.urlShippingImages + value} alt="" height="30" /> */}
                                        {tableMeta.rowData[9] != 2 && <button className="btn btn-danger btn-sm ms-1"
                                            onClick={() => props.showModalF("img_user_confirm", { id_shipping: tableMeta.rowData[0], image: value })}>
                                            <FaExchangeAlt />
                                        </button>}
                                    </>
                            }
                        </div>
                    )
                }
            },
            {
                label: "PREUVE DE RECHARGEMENT",
                name: "img_admin_confirm",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex justify-content-center align-items-center" >
                            {
                                props.user.rol != 0 && (
                                    value == "" ? (
                                        <button className="btn btn-secondary btn-sm"
                                            onClick={() => props.showModalF("img_admin_confirm", { id_shipping: tableMeta.rowData[0], image: value })}>
                                            <FiUpload />
                                        </button>
                                    ) :
                                        <>
                                            
                                            <div style={{maxWidth:"100px",overflow:"hidden"}} className="me-1">
                                            
                                            <ImageModal src={ApiConfig.urlShippingImages + value}/>
                                            </div>
                                            <button className="btn btn-danger btn-sm"
                                                onClick={() => props.showModalF("img_admin_confirm", { id_shipping: tableMeta.rowData[0], image: value })}>
                                                <FaExchangeAlt />
                                            </button>
                                        </>
                                )
                            }
                            {
                                props.user.rol == 0 && (
                                    value == "" ? ""
                                        :
                                        <ImageModal src={ApiConfig.urlShippingImages + value}/>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                label: "Type de Recharge ",
                name: "logo",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex justify-content-center">
                            <img src={ApiConfig.urlBankImages + value} alt="" height="25" />
                        </div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'STATE' }),
                name: "state",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div >
                            {states[value]} {value == 2 && <HiBadgeCheck className="text-success" style={{ fontSize: "20px" }} />}
                        </div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ACTION' }),
                name: "action",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div >
                            {
                                value.data.state != 2 && <button className="btn btn-success btn-sm" onClick={() => {
                                    let ar = tableMeta.rowData;
                                    navigate("/shipping/edit", { state: value.data })
                                }}>
                                    <BiEditAlt />
                                </button>
                            }
                        </div>
                    )
                }
            },
        ]}
        options={getOptios(intl)}
        {...props}
    />
}


export const BankTable = (props) => {
    const navigate = useNavigate();
    const intl = useIntl();
    return <MUIDataTable
        title={"banks list"}
        columns={[
            {
                label: intl.formatMessage({ id: 'ID' }),
                name: "id_bank",
                options: {
                    filter: false,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'NAME' }),
                name: "name",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: "Monnaie",
                name: "currency_data",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">
                            {
                                JSON.parse(value).map(el => {
                                    return el.currency;
                                }).join("-")
                            }
                        </div>
                    )
                }
            },
            {
                label: "Données d'expédition",
                name: "shipping_data",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">
                            {
                                JSON.parse(value).map(el => {
                                    return el.item;
                                }).join("-")
                            }
                        </div>
                    )
                }
            },
            {
                label: "Logo",
                name: "logo",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex align-items-center">
                            <img src={ApiConfig.urlBankImages + "" + value} height="25" alt="" />
                        </div>
                    )
                }
            },
            {
                label: "Disable",
                name: "disable",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex justify-content-center flex-column">
                            {value == 0 ? "Disabled" : "Active"}
                            <Switch
                                onChange={() => props.changeStateBank(tableMeta.rowData[0], value)}
                                checked={value == 1 ? true : false}
                            />
                        </div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ADD_DATE' }),
                name: "add_date",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'EDIT_DATE' }),
                name: "edit_date",
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                label: "Action",
                name: "action",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <button className="btn btn-success  btn-sm" onClick={
                            () => {
                                let ar = tableMeta.rowData;
                                let currency_data = JSON.parse(value.currency_data)

                                let shipping_data = JSON.parse(value.shipping_data)
                                navigate("/tools/bank/edit", {
                                    state: {
                                        id_bank: value.id_bank,
                                        name: value.name,
                                        currency_data: currency_data.map((el, key) => {
                                            return {
                                                label: el.currency,
                                                value: el.currency,
                                                sale: el.sale,
                                                buy: el.buy,
                                            }
                                        }),
                                        shipping_data,
                                        visa_data: JSON.parse(value.visa_data),
                                        logo: value.logo,
                                        currency: props.currency
                                    }
                                })
                            }
                        }><MdEdit /></button>
                    )
                }
            }
        ]}
        options={getOptios(intl)}
        {...props}
    />
}














export const UsersTable = (props) => {
    const intl = useIntl();
    const handleClipboardWriteSuccess = clipboardContent => {
        toast.success('Copy text', {
            position: "bottom-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const handleClipboardWriteError = error => {

    }

    const [clipboard, copyToClipboard] = useClipboard()
    return <MUIDataTable
        title={intl.formatMessage({ id: 'USERS_LIST' })}

        columns={[
            {
                name: intl.formatMessage({ id: 'ID' }),
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'ID_EXPED' }),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex align-items-center">
                            <span className="badge bg-dark fw-800 badgeF">{value}</span>
                            <button className="btn btn-default" onClick={
                                () => copyToClipboard(value,
                                    handleClipboardWriteSuccess,
                                    handleClipboardWriteError
                                )}><MdOutlineContentCopy /></button>
                        </div>

                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'FNAME' }),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'LNAME' }),
                options: {
                    display: false,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'BIRTH_DATE' }),
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'GENDER' }),
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        value == 0 ? <span className="badge bg-primary badgeF">{intl.formatMessage({ id: 'MALE' })}</span> :
                            (value == 1 ? <span className="badge bg-danger badgeF">{intl.formatMessage({ id: 'FEMALE' })}</span> : "")
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'EMAIL' }),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'PHONE' }),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            ...props.user.rol == 2 ? [
                {
                    name: intl.formatMessage({ id: 'ROL' }),
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <div className="d-flex justify-content-center flex-column">
                                {value == 0 ? "User" : "Admin"}
                                <Switch
                                    onChange={() => props.changeRolUser(tableMeta.rowData[0], value)}
                                    checked={value == 1 ? true : false}
                                />
                            </div>
                        )
                    }
                }] : [],
            {
                name: intl.formatMessage({ id: 'EDIT_DATE' }),
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'ADD_DATE' }),
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            ...props.user.rol == 2 ? [
                {
                    name: intl.formatMessage({ id: 'RESBONSABLE_FOR' }),
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <div className="d-flex justify-content-center align-items-center">
                                {
                                    tableMeta.rowData[8] == 1 && (

                                        tableMeta.rowData[11].id_store ? (
                                            <>
                                                {tableMeta.rowData[11].name} <button className="btn btn-danger btn-sm ms-1" onClick={() => props.setShowModal("delete", tableMeta.rowData[0])} ><MdDelete /></button>
                                            </>
                                        ) :
                                            (
                                                <>
                                                    <button className="btn btn-success btn-sm" onClick={() => props.setShowModal("add", tableMeta.rowData[0])}><FiPlus /></button>
                                                </>
                                            )
                                    )
                                }

                            </div>
                        )
                    }
                }] : []
        ]}
        options={getOptios(intl)}
        {...props}
    />
}











export const ColisTable = (props) => {
    const intl = useIntl();
    const navigate = useNavigate()
   

    const states = [
        intl.formatMessage({ id: 'COLIS_REC' }),
        intl.formatMessage({ id: 'COLIS_PREP' }),
        intl.formatMessage({ id: 'COLIS_ATT_PAIE' }),
        intl.formatMessage({ id: 'COLIS_ATT_VALID' }),
        intl.formatMessage({ id: 'COLIS_PAIE_ACC' }),
        intl.formatMessage({ id: 'COLIS_REJ' }),
        intl.formatMessage({ id: 'COLI_EN_ROUT' }),
        intl.formatMessage({ id: 'COLIS_ARR' }),
    ]
    
    return <MUIDataTable
        title={intl.formatMessage({ id: 'COLIS_LIST' })}

        columns={[
            {
                label: intl.formatMessage({ id: 'ID' }),
                name: "id_colis",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ID_EXPED' }),
                name: "id_exped",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'TRACK_NUMBER' }),
                name: "tracking_number",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },

            {
                label: intl.formatMessage({ id: 'STORE_NAME' }),
                name: "store_name",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'WEIGHT' }),
                name: "weight",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'LENGTH' }),
                name: "length",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'WIDTH' }),
                name: "width",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'HEIGHT' }),
                name: "height",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                name: intl.formatMessage({ id: 'VOL_WEIGHT' }) + "(" + intl.formatMessage({ id: 'KG' }) + ")",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{
                            tableMeta.rowData[5] * tableMeta.rowData[6] * tableMeta.rowData[7] / 5000

                        }</div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'IMAGES' }),
                name: "images",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>
                            {
                                value.length != 0 ?
                                    <CollectImages images={value} />
                                    :
                                    intl.formatMessage({ id: 'NO_IMAGES' })
                            }

                        </div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'STATE' }),
                name: "state",
                options: {
                    filter: true,
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex align-items-center">

                            {
                                props.user.rol != 0 &&
                                <button
                                    className="btn btn-default border  btn-sm me-1"
                                    onClick={() => props.showModalF("change_state", { id_colis: tableMeta.rowData[0], states, state: tableMeta.rowData[11].state })}>
                                    <FaExchangeAlt />
                                </button>
                            }
                            {value}
                            {tableMeta.rowData[11].state == 1 && <h5 className="btn btn-default m-0 p-0"><MdOutlineInfo onClick={() => props.showModalF("info")} /></h5>}

                        </div>
                    )
                }
            },
            {
                label: intl.formatMessage({ id: 'ACTION' }),
                name: "action",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex align-items-center">
                            {
                                (props.user.rol == 1 || props.user.rol == 2) && (
                                    <>
                                        <button className="btn btn-success  btn-sm" onClick={
                                            () => {
                                                let ar = tableMeta.rowData;
                                                console.log(tableMeta)
                                                navigate("/colis/edit", {
                                                    state: {
                                                        id_colis: ar[0],
                                                        id_exped: ar[1],
                                                        tracking_number: ar[2],
                                                        weight: ar[4],
                                                        length: ar[5],
                                                        width: ar[6],
                                                        height: ar[7],
                                                        images: ar[9]
                                                    }
                                                })
                                            }
                                        }><MdEdit /></button>
                                        {
                                            value.state == 1 &&
                                            <button
                                                className="ms-1 btn btn-warning d-flex align-items-center  btn-sm"
                                                onClick={() => props.showModalF("demend_pay", { id_colis: tableMeta.rowData[0] })}>
                                                {intl.formatMessage({ id: 'REQUEST_PAY' })} <FaDollarSign className="ms-1" />
                                            </button>
                                        }
                                        {
                                            value.state == 3 &&
                                            <button
                                                className="ms-1 btn btn-warning d-flex align-items-center  btn-sm"
                                                onClick={() => props.showModalF("info_pay", { colis: value })}>
                                                valider <FaDollarSign className="ms-1" />
                                            </button>
                                        }
                                        {
                                            value.state == 4 &&
                                            <button
                                                className="ms-1 btn btn-success d-flex align-items-center  btn-sm"
                                                onClick={() => props.showModalF("colis_send", { colis: value })}>
                                                {intl.formatMessage({ id: 'SEND' })} <MdSend className="ms-1" />
                                            </button>
                                        }
                                    </>
                                )
                            }
                            {
                                props.user.rol == 0 && (
                                    <>
                                        {
                                            value.state == 0 &&
                                            <button
                                                className="btn btn-primary d-flex align-items-center btn-sm"
                                                onClick={() => props.showModalF("send", tableMeta.rowData[0])}
                                            >
                                                {intl.formatMessage({ id: 'SEND' })} <MdSend className="ms-1" />
                                            </button>
                                        }
                                        {
                                            value.state == 2 &&
                                            <button
                                                className="btn btn-warning d-flex align-items-center btn-sm"
                                                onClick={
                                                    () => {
                                                        let ar = tableMeta.rowData;
                                                        console.log(tableMeta)
                                                        navigate("/colis/pay", {
                                                            state: {
                                                                colis: value
                                                            }
                                                        })
                                                    }
                                                }
                                            >
                                                {intl.formatMessage({ id: 'PAY' })} <FaDollarSign className="ms-1" />
                                            </button>
                                        }
                                        {
                                            value.state == 3 &&
                                            <button
                                                className="ms-1 btn btn-warning d-flex align-items-center  btn-sm"
                                                onClick={() => props.showModalF("info_pay", { colis: value })}>
                                                Affiche <FaDollarSign className="ms-1" />
                                            </button>
                                        }
                                        {
                                            value.state == 6 &&
                                            <button
                                                className="ms-1 btn btn-primary d-flex align-items-center  btn-sm"
                                                onClick={() => props.showModalF("colis_recu", { colis: value })}>
                                                j'ai reçu
                                            </button>
                                        }

                                    </>
                                )
                            }
                        </div>
                    )
                }
            }
        ]}
        options={getOptios(intl)}
        // data={
        //     props.data.map((el,key)=>{
        //         return {...el,state:states[el.state]}
        //     })
        // }
        {...props}
    />
}







export const VisaTable = (props) => {
    const intl = useIntl();

    return <MUIDataTable
        title={"Suivi des cartes"}

        columns={[
            {
                name: "id_visa",
                label: intl.formatMessage({ id: 'ID' }),
                options: {
                    display: true,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="text-center">{value}</div>
                    )
                }
            },
            {
                name: "logo",
                label: "Type de cart",
                options: {
                    filter: false,
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex align-items-center">
                            <img src={ApiConfig.urlBankImages + "" + value} height="25" alt="" />
                        </div>
                    )
                }
            },
            {
                name: "info",
                label: "info",
                options: {
                    filter: false,
                    display: true,
                    customBodyRender: (values, tableMeta, updateValue) => (
                        <div className="d-flex flex-column">
                            <div className="d-flex">
                                <strong>{intl.formatMessage({ id: 'FNAME' })} : </strong> 
                                 {values.fname}
                            </div>
                            <div className="d-flex">
                                <strong>{intl.formatMessage({ id: 'LNAME' })} : </strong>
                                {values.lname}
                            </div>
                            <div className="d-flex">
                                <strong>{intl.formatMessage({ id: 'EMAIL' })} : </strong>
                                {values.email}
                            </div>
                            <div className="d-flex">
                                <strong>{intl.formatMessage({ id: 'PHONE' })} : </strong>
                                {values.phone}
                            </div>
                        </div>
                    )
                }
            },
            {
                name: "image_confirm",
                label: "reçu de paiement",
                options: {
                    filter: false,
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div className="d-flex justify-content-center">
                            
                            <ImageModal src={ApiConfig.urlVisaPay + value}/>
                        </div>
                    )
                }
            },
            {
                name: "add_date",
                label: intl.formatMessage({ id: 'EDIT_DATE' }),
                options: {
                    filter: false,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>{value}</div>
                    )
                }
            },
            {
                name: "state",
                label: intl.formatMessage({ id: 'STATE' }),
                options: {
                    filter: true,
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>
                            {value}

                        </div >
                    )
                }
            },

            {
                name: "action",
                label: intl.formatMessage({ id: 'ACTION' }),
                options: {
                    filter: false,
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <div>
                            {
                                (props.user.rol == 1 || props.user.rol == 2) && (
                                    value.state == 1 && <>
                                        <button
                                            className="btn btn-warning d-flex align-items-center btn-sm"
                                            onClick={
                                                () => props.showModalF("valid", {
                                                    id_visa: value.id_visa,
                                                    image_confirm: value.image_confirm
                                                })
                                            }
                                        >
                                            valid <FaDollarSign className="ms-1" />
                                        </button>
                                    </> ||
                                    value.state == 2 && <>
                                        <button
                                            className="btn btn-success d-flex align-items-center btn-sm"
                                            onClick={
                                                () => props.showModalF("cart_arr", {
                                                    id_visa: value.id_visa
                                                })
                                            }
                                        >
                                            Carte reçue
                                        </button>

                                    </>
                                    ||
                                    value.state == 4 && <>
                                        <button
                                            className="btn btn-success d-flex align-items-center btn-sm"
                                            onClick={
                                                () => props.showModalF("cart_send", {
                                                    id_visa: value.id_visa,
                                                    address:value.address
                                                })
                                            }
                                        >
                                            Envoyer
                                        </button>

                                    </>
                                )
                            }
                            {
                                (props.user.rol == 0) && (
                                    value.state == 0 && <>
                                        <button
                                            className="btn btn-warning d-flex align-items-center btn-sm"
                                            onClick={
                                                () => props.showModalF("pay", {
                                                    id_visa: value.id_visa,
                                                    visa_data:JSON.parse(value.visa_data)
                                                })
                                            }
                                        >
                                            {intl.formatMessage({ id: 'PAY' })} <FaDollarSign className="ms-1" />
                                        </button>
                                    </> ||
                                    value.state == 2 && <>
                                        <p>
                                            <MdOutlineInfo />
                                            Dès réception de la carte, nous vous informerons
                                        </p>
                                    </> ||
                                    value.state == 3 && <>
                                        <button
                                            className="btn btn-warning d-flex align-items-center btn-sm"
                                            onClick={
                                                () => props.showModalF("pay", {
                                                    id_visa: value.id_visa,
                                                    visa_data:JSON.parse(value.visa_data)
                                                })
                                            }
                                        >
                                            {intl.formatMessage({ id: 'PAY' })} <FaDollarSign className="ms-1" />
                                        </button>
                                    </>||
                                    value.state == 5 && <>
                                        <button
                                            className="btn btn-success d-flex align-items-center btn-sm"
                                            onClick={
                                                () => props.showModalF("cart_res", {
                                                    id_visa: value.id_visa
                                                })
                                            }
                                        >
                                            J'ai reçu
                                        </button>

                                    </>
                                )
                            }
                        </div>
                    )
                }
            },
        ]}
        options={getOptios(intl)}
        {...props}
    />
}
