import { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useIntl } from 'react-intl';
import StoreService from "../Service/StoreService"
import { Toast, ToastStyle } from "../Views/Toast"
import Select from 'react-select';
import ApiConfig from '../Config/ApiConfig';
import Loader from "../Views/Loader"
import styled from "styled-components"
const DivC = styled.div`
video{
    width:100%;
    height: auto;
}
`
function HowTo(props) {


    return (

        <DivC>
            <h3>Comment expédier le colis ?</h3>
            <hr />
            <video  controls>
                <source src={ApiConfig.url+"/files/01100.mp4"} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
            </video>

        </DivC>

    );
}

export default HowTo;