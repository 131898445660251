import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
export const Toast=toast;
export const ToastStyle={
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
}
