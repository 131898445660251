
import { useState, useContext, useRef, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components';
import Stepper from 'react-stepper-horizontal';
import ShippingService from "../Service/ShippingService"
import Loader from '../Views/Loader';
import { Toast, ToastStyle } from "../Views/Toast"
import ApiConfig from '../Config/ApiConfig';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
const DivC = styled.div`
min-height:400px;
.bt{
    min-width: 100px;
}
.list-group-item{
    border:none
}
`
function AddShipping({ update }) {
    const intl = useIntl();
    const location = useLocation()

    const datar = location.state;
    console.log(datar)
    const [step, setStep] = useState(0);
    const [banks, setBanks] = useState(null);
    const [bank, setBank] = useState(null)
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [amount, setAmount] = useState(0);
    const [devis, setDevis] = useState(0);
    const [isure, setIsure] = useState(false)
    const [apiConvert, setApiConvert] = useState(null);
    const [loding, setLoding] = useState(false);
    const [paymentBank, setPaymentBank] = useState(null)


    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const getData = () => {
        ShippingService.getBanks(false)
            .then(res => {
                let bs = res.data.data.banks
                if (update) {
                    let b = bs.filter(el => el.id_bank == datar.id_bank)[0]
                    setBank({
                        ...b,
                        currency_data: JSON.parse(b.currency_data),
                        shipping_data: JSON.parse(datar.shipping_info),
                        exped_data: JSON.parse(b.shipping_data)
                    })
                    setFname(datar.fname)
                    setLname(datar.lname)
                    setAmount(datar.amount)
                    setDevis(JSON.parse(b.currency_data).filter(el => el.currency == datar.currency_to)[0])
                }
                setBanks(bs);

            }).catch(err => {
                setBanks([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData()
    }, [])
    const addSipping = (ob) => {
        setLoding(true)
        ShippingService.addShipping(ob)
            .then(res => {
                if (res.data.error == "") {
                    Toast.success("Ajouté avec succès", ToastStyle);
                    let d = res.data.data;


                    setPaymentBank({
                        name: d.name,
                        shipping_data: JSON.parse(d.shipping_data),
                        logo: d.logo
                    })
                    setStep(4)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    const updateSipping = (ob) => {
        setLoding(true)
        ShippingService.updateShipping(ob)
            .then(res => {
                if (res.data.error == "") {
                    Toast.success("Modifié avec succès", ToastStyle);
                    let d = res.data.data;


                    setPaymentBank({
                        name: d.name,
                        shipping_data: JSON.parse(d.shipping_data),
                        logo: d.logo
                    })
                    setStep(4)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }

    const handleSubmitAdd = (e) => {
        e.preventDefault();

        let ob = {
            id_bank: bank.id_bank,
            fname,
            lname,
            shipping_info: JSON.stringify(bank.shipping_data),
            currency: devis.currency,
            amount,
            id_shipping: datar ? datar.id_shipping : 0
        };
        if (!update)
            addSipping(ob);
        else {
            updateSipping(ob)
        }
    }
    const updateBank = (index) => {
        setBank(
            {
                ...banks[index],
                currency_data: JSON.parse(banks[index].currency_data),
                shipping_data: JSON.parse(banks[index].shipping_data).map((el, item) => {
                    return { item: el.item, value: "" }
                }),
                exped_data: JSON.parse(banks[index].shipping_data)
            }
        );
    }
    const updateShippingData = (e, index) => {
        setBank(
            {
                ...bank,
                shipping_data: bank.shipping_data.map((el, key) => {
                    return { ...el, value: index == key ? e.target.value : el.value }
                })
            }
        )
    }
    const updateDevis = (d) => {
        setDevis(d);

    }
    const updateVal = (e) => {
        switch (e.target.id) {
            case "fname": setFname(e.target.value); break;
            case "lname": setLname(e.target.value); break;
            case "amount": setAmount(e.target.value);
        }
    }

    const validInfo = () => {
        if (fname == "" || lname == "")
            return true
        let ob = { ...bank.shipping_data };
        let tab = Object.keys(ob).filter((el, key) => ob[el] == "");
        return tab.length != 0;

    }
    return (
        <>


            <DivC className="row">
                <Col> 
                    <div className="mb-5">
                        <Stepper
                            steps={[
                                { title: 'Type de recharge ' },
                                { title: 'Renseignements personnels' },
                                { title: "Devise d'expédition" },
                                { title: 'Sauvegarder' },
                            ]}
                            size={50}
                            activeStep={step}
                            completeColor="#29d155 "
                            completeTitleColor="#444"

                            completeBarColor="#29d155"
                            defaultBarColor="#AAA"
                            defaultBarStyle="dotted"
                            activeColor="#616161"
                            circleFontSize={24}
                            
                        />
                    </div>
                    <Form onSubmit={handleSubmitAdd} className="pt-3">
                        {
                            step == 0 && <Container>
                                {!banks ? <Loader no={true} /> :
                                    <Row>
                                        <Col sm={12}>
                                            <ul className="list-group">
                                                {
                                                    banks.map((el, key) => {
                                                        return <li className="list-group-item d-flex align-items-center p-3">

                                                            <input type="radio" className="form-check-input me-3" name="bank" checked={bank && bank.id_bank == el.id_bank} onChange={() => updateBank(key)} />

                                                            <img src={ApiConfig.urlBankImages + el.logo} alt="" height="30" className="ms-3" />
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </Col>
                                        <Col sm={12} className='mt-4 d-flex justify-content-end'>
                                            <button class="btn btn-primary  bt" disabled={bank == null} onClick={() => { setStep(1) }}>Next</button>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                        }
                        {
                            step == 1 && <Container>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" controlId="fname">
                                            <Form.Label>{intl.formatMessage({ id: 'FNAME' })}</Form.Label>
                                            <Form.Control type="text" value={fname} onChange={updateVal} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" controlId="lname">
                                            <Form.Label>{intl.formatMessage({ id: 'LNAME' })}</Form.Label>
                                            <Form.Control type="text" value={lname} onChange={updateVal} />
                                        </Form.Group>
                                    </Col>
                                    {
                                        bank.shipping_data.map((el, key) => {

                                            return <Col sm={12} md={6}>
                                                <Form.Group className="mb-3" controlId={el.item}>
                                                    <Form.Label>{el.item}</Form.Label>
                                                    <Form.Control type="text" value={bank.shipping_data[key].value} onChange={(e) => updateShippingData(e, key)} />
                                                </Form.Group>
                                            </Col>
                                        })
                                    }
                                    <Col sm={12} className='mt-4 d-flex justify-content-end'>
                                        <button class="btn btn-primary bt" onClick={() => setStep(0)}>Previous</button>
                                        <button class="btn btn-primary ms-3 bt" onClick={() => setStep(2)} disabled={true == validInfo()}>Next</button>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            step == 2 && <Container>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="amount">
                                            <Form.Label>Montant:</Form.Label>
                                            <Form.Control type="text" value={amount} onChange={updateVal} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} lg={6}>

                                        <Form.Group className="mb-3" controlId="currency">
                                            <Form.Label>Devise d'expédition:</Form.Label>
                                            {
                                                bank.currency_data.map((el, key) => {
                                                    return <Form.Check type="radio" name="currency" label={el.currency} checked={el.currency == devis.currency} onChange={() => updateDevis(el)} />
                                                })
                                            }
                                        </Form.Group>
                                        <hr className="mt-4" />
                                        <h1 style={{ fontSize: "60px" }}>
                                            {devis && parseFloat(amount * devis.buy).toFixed(2)} DA
                                        </h1>
                                        <hr className="mt-4" />
                                    </Col>

                                    <Col sm={12} className='mt-4 d-flex justify-content-end'>
                                        <button class="btn btn-primary bt" onClick={() => setStep(1)}>Previous</button>
                                        <button class="btn btn-primary ms-3 bt" onClick={() => setStep(3)}>Next</button>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            step == 3 && <Container>
                                <Row>
                                    <Col>
                                        <ul class="list-group">
                                            <li class="list-group-item p-1">
                                                <strong>Type de recharge :</strong> {bank.name}
                                            </li>
                                            <li class="list-group-item p-1">
                                                <strong>Nom :</strong> {lname}
                                            </li>
                                            <li class="list-group-item p-1">
                                                <strong>Prénom :</strong> {fname}
                                            </li>
                                            {
                                                bank.shipping_data.map((el, key) => {
                                                    return <li class="list-group-item p-1">
                                                        <strong>{el.item} :</strong> {el.value}
                                                    </li>
                                                })
                                            }
                                            <li class="list-group-item p-1">
                                                <strong>Montant de l'expédition :</strong> {amount} {devis.currency}
                                            </li>
                                            <hr />
                                            <li class="list-group-item p-1">
                                                <strong>Montant en (DA) :</strong> {devis && parseFloat(amount * devis.buy).toFixed(2)} DA
                                            </li>
                                            <hr />
                                            <li class="list-group-item p-1">
                                                <div class="alert alert-warning p-2">
                                                    <strong>Attention!</strong>  Veuillez vérifier les informations avant l'envoi.
                                                </div>
                                            </li>
                                        </ul>


                                    </Col>
                                    <Col sm={12} className='mt-4 d-flex justify-content-end'>
                                        <button class="btn btn-primary bt" onClick={() => setStep(2)}>Previous</button>
                                        <button class="btn btn-primary ms-3 bt" disabled={loding}>

                                            {loding ? spanLoding : ""}
                                            {
                                                update ? intl.formatMessage({ id: 'SAVE' }) : intl.formatMessage({ id: 'SUBMIT' })
                                            }
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        }

                        {
                            step == 4 && <Container>
                                <Row>
                                    <Col>

                                        <div class="alert alert-success p-2">
                                            <strong>Envoyé avec succès : </strong>Votre commande est en attente de paiement
                                        </div>
                                        <div>Veuillez envoyer l'argent sur ce compte et faire nous envoyer la preuve versement depuis l’historique.</div>
                                        {
                                            paymentBank && <div class="p-4">
                                                <div className="">
                                                    <img src={ApiConfig.urlBankImages + paymentBank.logo} alt="" height="70" />
                                                </div>
                                                <div className="mt-2">
                                                    {
                                                        paymentBank.shipping_data.map((el, key) => {
                                                            return <div key={key}>
                                                                <strong>{el.item} : </strong>
                                                                {el.value}
                                                            </div>
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Container>

                        }

                    </Form>

                </Col>
            </DivC>
        </>
    );
}

export default AddShipping;