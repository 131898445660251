import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
 
import axios from 'axios';

const DivC=styled.div`
ul{
    list-style-type: disc;
}
.sub-m{
    list-style-type: circle;
}
ul li{
    color: #5e5873;
font-size: 0.857rem;
}

`
function Notes(props) {
    const intl = useIntl();

   
    return (
        <DivC>
            <h4>{intl.formatMessage({ id: 'NOTES_TITLE' })}</h4>
            <h6>{intl.formatMessage({ id: 'NOTES_SUBTITLE' })}:</h6>
            <ul>
                <li>Stamps</li>
                <li>American stamps</li>
                <li>American stamps</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Uncancelled / unused US stamps</li>
                    </ul>
                </li>

                <li>Lithium batteries</li>
                <li>Lithium ion, lithium and lithium Metal batteries</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Lithium batteries packed with material (such as power tools) or more than 2 devices containing a lithium pile - item.</li>
                    </ul>
                </li>

                
                <li>E-Juice</li>
                <li>Liquid used in electronic cigarette devices and vaping devices</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>E-Juice - This item cannot be shipped to your country</li>
                        <li>E-liquid - This item cannot be shipped to your country</li>
                    </ul>
                </li>

                <li>Cigarette smoking or vaping device in the form of a cigarette containing vaporized and inhaled liquid, used to simulate smoking. Electronic cigarettes and related supplies and accessories, including refill packs.</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Electronic Cigarettes</li>
                    </ul>
                </li>


                <li>Art and Antiques</li>
                <li>Art, collectibles, antiques</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Works of art, collectibles, etc. - Works of art, collectibles, etc. worth more than 5,000 €</li>
                        <li>Figures - Figures valued at € 5,000</li>
                        <li>Paintings Sculptures - Paintings OR OR Sculptures worth 5,000 € €</li>
                    </ul>
                </li>

                <li>souvenirs - souvenirs worth more than 5,000 € of alcohol, beer and wine on all prohibited products Are the carriers</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Wine - This item cannot be shipped to your country</li>
                        <li>Liquer - This item cannot be shipped to your country</li>
                        <li>Beer - This item cannot be shipped to your country</li>
                    </ul>
                </li>

                <li>Agricultural articles grown on farms or in gardens or articles used in the cultivation of soil; planting; raise and harvest plants, crops, fruits and vegetables.</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Seeds</li>
                        <li>joined</li>
                        <li>Wheat</li>
                        <li>Soybeans</li>
                        <li>Floor</li>
                        <li>Grain</li>
                        <li>Food</li>
                    </ul>
                </li>

                <li>Animals</li>
                <li>Animals from animals</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Live animals</li>
                        <li>Dead Animals</li>
                        <li>Parts of</li>
                        <li>fabric animals</li>
                        <li>Animal furs</li>
                        <li>Animal skin</li>
                        <li>Biorisks</li>
                    </ul>
                </li>

                <li>Biological substances which may contain contagions or other dangerous substances.</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Body fluids</li>
                        <li>Cell cultures</li>
                        <li>Laboratory reagents</li>
                        <li>Medical Specimens</li>
                        <li>Tissue samples</li>
                        <li>Tear gas</li>
                        <li>Human remains</li>
                    </ul>
                </li>

                <li>Chemicals</li>
                <li>Raw chemicals, but also certain adhesives, pastes, precursors and chemicals, sealants</li>
                <li className="mb-3"><strong>Examples: Carbonic</strong>
                    <ul className='sub-m'>
                        <li>Adhesives</li>
                        <li>Sealants</li>
                        <li>The precursors of chemical weapons</li>
                        <li>Peroxide</li>
                        <li>Crazy Glue Adhesive</li>
                        <li>Disinfectants</li>
                        <li>Corrosive</li>
                        
                    </ul>
                </li>

                <li>Acids or bases which can cause and cause corrosion or the consumption of substance A</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Hydrochloric acid</li>
                        <li>Sulfuric acid</li>
                        <li>Laundry</li>
                        <li>Some Cleansers</li>
                        <li>Cosmetic derma-skin</li>
                        <li>Battery fluid</li>
                        <li>Tinctures</li>
                        <li>Iodine</li>
                    </ul>
                </li>

                <li>Perfume / Cologne</li>
                <li>Perfumes, colognes and scented / aromatic liquids are based on essential oils or extracts of flowers and spices, to give a pleasant scent.</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Perfume - item may not this shipment be Your Pay</li>
                        <li>Cologne - item may not this shipment Your Pay To Be</li>
                    </ul>
                </li>

                <li>Counterfeit</li>
                <li>Items not produced by the original manufacturer in violation of copyright / trademark laws</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Coups de grace</li>
                        <li>Pirated goods</li>
                        <li>Imitation products</li>
                    </ul>
                </li>    

                <li>Drug smuggling</li>
                <li>Drug related items restricted or prohibited by US law</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Narcotics</li>
                        <li>Illegal drugs</li>
                        <li>Drug paraphernalia</li>
                        <li>Medication devices</li>
                    </ul>
                </li>

                <li>ECCN regulated</li>
                <li>Items requiring an ECCN (Export Control Classification Number). Often related to airplane or aerospace parts or computer parts. Telecommunications devices, etc ...</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Large microprocessors (by Like those who used Satellites and Space Agencies Agencies)</li>
                        <li>Aircraft parts</li>
                        <li>Aerospace Parts</li>
                    </ul>
                </li>

                <li>Explosives</li>
                <li>Explosive articles subject to combustion OR</li>
                <li className="mb-3"><strong>Examples:</strong>
                    <ul className='sub-m'>
                        <li>Examples</li>
                        <li>Class 1 dangerous goods</li>
                        <li>Rockets</li>
                        <li>Gunpowder</li>
                        <li>Matches</li>
                        <li>Fireworks</li>
                        <li>Flashbangs</li>
                        <li>Smoke grenades</li>
                        <li>Ammunition / cartridges</li>
                        <li>Detonators</li>
                        <li>Fuses</li>
                        <li>Primers</li>
                        <li>Airbag inflators</li>
                    </ul>
                </li>
             </ul>

            
        </DivC>
    );
}
export default Notes;