import axios from "axios";
import ApiConfig from "./ApiConfig.js";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))
const  http = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  ApiConfig.content_type
  }
})
const  httpUpload = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  "multipart/form-data"
  }
})

const  http3 = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  "application/json"
  }
})
const  http4 = axios.create({
  baseURL: "http://www.forexalgerie.com",
  headers: {
    "Content-type": "application/x-www-form-urlencoded",
    "Origin":"http://www.forexalgerie.com"
  }
})

const req={
  http,
  httpUpload,
  http3,
  http4
}

export default req;