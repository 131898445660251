import {useState} from 'react';
import {Container,Row,Col} from "react-bootstrap"
import {FiUser,FiLock} from "react-icons/fi"
import {FaLocationArrow}from "react-icons/fa"
import {IoShareSocialOutline,IoLocationOutline,IoLocationSharp} from "react-icons/io5"
import {TiLocationArrowOutline} from "react-icons/ti"
import General from "./General"
import Info from "./Info"
import ChangePassword from "./ChangePassword"
import DeliveryAddress from "./DeliveryAddress"
import ExpedAddress from "./ExpedAddress"
import styled from "styled-components"
import { useIntl } from 'react-intl';


const DivC=styled.div`
.list-group .list-group-item{
    cursor: pointer;
    border: none;
    border-radius: 5px;
}
.list-group-item.active{
    background: linear-gradient(45deg,#0eb334,#4ef981)!important;
}
.list-group-item:active{
    background: linear-gradient(45deg,#0eb334,#4ef981)!important;
}

`
function Profile(props) {
    const [step,setStep]=useState(1);

    const changeStep=(v)=>{
        setStep(v);
    }
    
    const intl = useIntl();
    
    return (

        <DivC className="container p-0 pt-3">
            <h3>{intl.formatMessage({ id: 'PROFILE' })}</h3>
            <hr/>
            <Row>
                <Col lg={4} md={6} sm={12}>
                    <div className="list-group">
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==1?"active":""}`} onClick={()=>changeStep(1)}>
                            {
                                step!=1?
                                <TiLocationArrowOutline className="me-3"/>:
                                <FaLocationArrow className="me-3"/>
                            }
                            {intl.formatMessage({ id: 'YOUR_ADDRESS_EXPED' })}
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==2?"active":""}`} onClick={()=>changeStep(2)}>
                            {    
                                step!=2?
                                <IoLocationOutline className="me-3"/>:
                                <IoLocationSharp className="me-3"/>
                            }
                            {intl.formatMessage({ id: 'YOUR_DELEVERY_ADDRESS' })}
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==3?"active":""}`} onClick={()=>changeStep(3)}>
                            <FiUser className="me-3"/>
                            {intl.formatMessage({ id: 'PERSONEL_INFO' })}
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==4?"active":""}`} onClick={()=>changeStep(4)}>
                            <FiLock className="me-3"/>
                            {intl.formatMessage({ id: 'CHANGE_PASS' })}
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==5?"active":""}`} onClick={()=>changeStep(5)}>
                            <IoShareSocialOutline className="me-3"/>
                            {intl.formatMessage({ id: 'SOCIAL_MEDIA' })}
                        </div>
                    </div> 
                </Col>
                <Col lg={8} md={6} sm={12}>
                {
                    step==1&&<ExpedAddress/>
                }
                {
                    step==2&&<DeliveryAddress/>
                }
                {
                    step==3&&<General/>
                }
                {
                    step==4&&<ChangePassword/>
                }
                {
                    step==5&&<Info/>
                }
                </Col>
            </Row>
        </DivC>
    );
}

export default Profile;