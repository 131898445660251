import {useState} from 'react';
import GalleryImageModal from '../Views/GalleryImageModal';
import styled from "styled-components"
import ApiConfig from "../Config/ApiConfig";
const DivC=styled.div`
position: relative;
top:0;
height: 30px;
.ims{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border:1px solid #FFF;
    box-shadow: 0px 0px 0px 1px #0003;
    overflow: hidden;
    position: absolute;
    top: 0;
    cursor: pointer;
    background-color: #FFF;
    transition: transform .3s;
}
.ims.plus{
    top:3px;
    width: 24px;
    height: 24px;
    font-weight: bold;
    /* box-shadow: none!important; */
}
.ims img{
    height: 100%;
    width: auto;
    transition: transform .3s;
}
.ims:not(.plus):hover{
    z-index: 100;
    transform: scale(1.4);
    box-shadow: 0px 0px 0px 1px #0009;
   
}

`
function CollectImages({images}) {
    const [showModal,setShowModal]=useState(false);
    const [indexR,setIndexR]=useState(0);
    const [dataImages,setDataImages]=useState(images.map((el,index)=>{
        return {
            original:ApiConfig.urlColisImages+""+el.name,
            thumbnail:ApiConfig.urlColisImages+""+el.name
        };
    }));
    
    const showModalF=(index)=>{
        setDataImages(dataImages);
        setIndexR(index)
        setShowModal(true)
    }
    const hideModalF=(image)=>{
        setDataImages(dataImages);
        setShowModal(false)
    }
    return (
        <DivC>
            {
                images.map((el,index)=>{
                    return <div className={`ims d-flex justify-content-center align-items-center ${index==3&&"plus"}`} key={index}  
                            style={
                                {
                                    left:(index*10+(index==3?5:0))+"px",
                                    visibility:(index>3?"hidden":"")
                                }
                            } 
                            onClick={()=>showModalF(index)}
                            >
                               
                            {
                                index<3?<img src={ApiConfig.urlColisImages+""+el.name}/>:
                                (index==3?"+"+(images.length-3):"")
                            }
                    </div>
                })
            }
            {
                showModal&&<GalleryImageModal 
                images={dataImages} 
                index={indexR}
                hideModal={hideModalF}/>
            }
        </DivC>
    );
}

export default CollectImages;