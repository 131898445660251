import React from 'react';
import DeliveryAddressList from "./DeliveryAddressList"
import AddDeliveryAddress from "./AddDeliveryAddress"
import {Container} from "react-bootstrap"
import { useLocation} from 'react-router-dom';

function DeliveryAddress(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/profile" : return <DeliveryAddressList/>;
            case "/profile/delivery/add" : return <AddDeliveryAddress update={false}/>;
            case "/profile/delivery/edit" : return <AddDeliveryAddress update={true}/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default DeliveryAddress;