import { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import Sidebar from './SideBar';
import Navbar from './Navbar';
import Loader from '../Views/Loader';
import UserService from '../Service/UserService';
import { Toast, ToastStyle } from "../Views/Toast"
import { useIntl } from 'react-intl';
import md5 from 'md5-hash'
import { DispatchContext, logout, StateContext } from "../Context/"

function Layout({ children }) {
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const { islogin, user } = useContext(StateContext);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [content, setContent] = useState(<Loader />);
  const [loding, setLoding] = useState(true);
  const [servervalid, setServervalid] = useState(false);
  const handleCollapsed = (value) => {
    setCollapsed(value);
  };



  const intl = useIntl();



  const dispatch = useContext(DispatchContext);
  const Logout = () => {
    UserService.logoutUser(user)
      .then(res => {

      }).catch(err => {

      })
  }

  const logoutUser = () => {
    Logout();
    logout(dispatch);
    navigate("/");
  }
  useEffect(() => {
    let userS = JSON.parse(window.localStorage.getItem("user", 0));
 
    if (Object.keys(userS).length != 0 && md5(userS.rol+"exped123"+userS.id+userS.session) != userS.token) {
      logoutUser();
    }
  })

  const Dashboard = <div className={`app `}>
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      rtl={intl.formatMessage({ id: 'DIR' })}
      handleToggleSidebar={handleToggleSidebar}
    />
    <main>
      <Navbar
        collapsed={collapsed}
        handleCollapsed={handleCollapsed}
        handleToggleSidebar={handleToggleSidebar}
      />
      <div className="m-2 content p-2 " style={{ position: "relative",borderRadius:"3px",boxShadow:"0 0 2px 0 #0003" }}>
        {children}
      </div>
    </main>
  </div>

  const validLogin = () => {

    UserService.validLogin(user)
      .then(res => {
        if (res.data.error !== "") {
          navigate("/login")
        }
        setLoding(false);
      }).catch(err => {
        Toast.error(err.message, ToastStyle);
      })
  }
  useEffect(() => {
    if (!islogin) {

      navigate("/login")
    } else {
      if (servervalid)
        setLoding(false)
      validLogin()
    }

  }, [])
  return (
    <>{loding ? content : Dashboard}</>
  );
}

export default Layout;