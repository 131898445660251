
import { useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { CgUserList } from "react-icons/cg"
import images from "../../assets/images"
import Calc from './Calc';
import CalculatorS from "../Shipping/Calculator"
import CommentF from './CommentF';
import Tarif from './Tarif';

import "./animate.css";
const DivC = styled.div`
height:100vh ;
  .main-section {
    position: relative;
    padding: 40px 15px 0;
    margin: 20px auto;
    text-align: center;

}
.head-section {
    max-width: 850px;
    margin: auto;
}
.top-title-section {
    font-size: 14px;
    color: var(--main-color);
    margin-bottom: 5px;
    text-transform: uppercase;
}
.title-section {
    font-size: 38px;
    font-weight: 900;
    letter-spacing: .4px;
    margin-bottom: 15px;
}
.description-section {
    color: var(--third-color);
    letter-spacing: 1px;
}
.features{
    position: relative;
    display: flex;
    margin-top: 45px;
}
.startimg{
  width: 200px!important;
}
.startimg img{
    height:auto;
    max-width:80px
}
.features .startimg{
    width: 200px!important;
}

.features img {
    /* margin-right: 20px; */
}

.section4{
    padding:80px 0;
}
.features .info {
    text-align: left;
}

.features .info h5 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
    color: var(--second-color);
}
.features .info p {
    font-size: 16px;
    line-height:18px;
    color: var(--third-color);
    margin: 0;
}
.features .info h5:after {
    content: "";
    background-color: var(--main-color);
    width: 3px;
    height: 3px;
    display: inline-block;
    margin-left: 2px;
}

/**************************************************************************************** */
.about .row {
    text-align: left;
    display: flex;
    align-items: center;
}
.about img {
    width: 92%;
    margin: 0 4%;
}
.about p {
    font-size: 18px;
    color: var(--third-color);
}
.progress-section{
    width: 55%;
}
.progress{
    height: 10px;
    border-radius: 0!important;
}
.progress-bar{
    background-color: var(--main-color);
    border-radius: 0!important;
}
.procedure{
    background-image: url("assets/images/customer-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 40px;
}
.procedure .row {
    align-items: center;
    position: relative;
}
.procedure img {
    width: 70%;
}
.procedure .top-title-section, .procedure .title-section {
    text-align: left;
}
.procedure_steps {
    background-color: var(--fourth-color);
    padding: 15px;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 13px 11px 30px 0px #a4a4a445;
    position: relative;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 94%;
}
.procedure_steps img {
    width: 50% !important;
    height: auto;
}
.procedure_steps h5 {
    font-size: 16px;
    letter-spacing: 0.6px;
    margin: 0;
    margin-top: 20px;
    color: var(--second-color);
}
.procedure_steps:before {
    content: "1";
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--main-color);
    width: 55px;
    height: 47px;
    color: var(--fourth-color);
    border-radius: 10px 3px 32px 2px;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    padding-right: 6px;
}
.procedure .col-lg-6:nth-child(1) .procedure_steps:before{
    content: "1";
}
.procedure .col-lg-6:nth-child(2) .procedure_steps:before{
    content: "2";
}
.procedure .col-lg-6:nth-child(3) .procedure_steps:before{
    content: "3";
}
.procedure .col-lg-6:nth-child(4) .procedure_steps:before{
    content: "4";
}
.contact .top-title-section, .contact .title-section, .contact .description-section, .contact h4 {
    text-align: left;
}
.contact .row {
    display: flex;
    align-items: center;
}
.contact h4 {
    font-weight: 800;
    margin-bottom: 22px;
}
.info-contact{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.icon-contact {
    color: var(--main-color)!important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-contact svg {
    fill: none;
    stroke: var(--main-color);
    stroke-width: 1.5;
    width: 65%;
    height: 65%;
}
.info-contact p {
    margin: 0;
    color: #EEE;;
    font-size: 15px;
    text-align: left;
}

/************************************************************************************/ 
.contact-form{
    /* margin: 0px 45px 0px 15px; */
    padding: 35px;
    background-color: #ffffff;
    box-shadow: 0px 10px 50px 0px rgb(53 82 99 / 9%);
}
.form-control{
    background: #f3f5f6;
    padding: 16px 18px;
    border: none;
    width: 100%;
    color: var(--third-color);
    max-width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 15px;
}
textarea.form-control{
    resize: none;
    height: 150px;
}
.contact .main-btn{
    width: 100%;
    display: block;
    text-align: center;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
}
.contact span{
    font-size: 28px;
    margin-bottom: 15px;
    display: block;
    text-align: left;
    font-weight: 800;
}
.section5{
    padding:60px 0;
}
.section6{
    padding-top:60px;
    padding-bottom:60px;
}
.section5{
    background-color:#f5f5f5
}
.section5 img,.section6 img{
    max-width:300px;
    width:100%;

}

`
function Exped(props) {
    const [showMenu, setShowMenu] = useState(false);
    const [calcS, setCalcS] = useState(true);
    const divScroll = (e) => {
        console.log(e.target)
    }
    return (
        <DivC onScroll={divScroll}>
            {/* <div className="loader done" id="loader"><span className="load"></span></div> */}




            <nav className="navbar fixed-top navbar-expand-xl main_navbar">
                <div className="container">

                    <a className="navbar-brand" href="#">
                        <img src={images.logo} alt="" />

                    </a>
                    <button className="navbar-toggler collapsed" type="button" onClick={() => setShowMenu(!showMenu)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`navbar-collapse collapse ${showMenu ? "show" : ""}`} id="main_nav" >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">Accueil</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#commentmarche">Comment ça marche</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#calculator">Calculateur</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Suivi des colis</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#contact">Contact</a>
                            </li>

                        </ul>
                        <div className="authentication">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">
                                        Connexion
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/register">
                                        Inscription
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>




            <div className="container-fluid wrapper d-flex align-items-center" style={{ backgroundImage: 'url(' + images.main_back + ')' }}>
                <div className="container">
                    <Row>
                        <Col sm={6}>
                            <div className="wrapper_content">
                                <h1 className="text-dark">Achetez en France et en Europe. Nous livrons dans le monde entier !</h1>
                                <p className="text-dark">Nous offrons un entrepôt à la pointe de la technologie et entièrement sécurisé.</p>
                                <a href="#" className="btn main-btn">C'est parti !</a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>



            <CommentF />
            <Tarif />


            <Container className="main-section section4 p-2" style={{overflow:"hidden"}}>
                <Row className="head-section">
                    <h3 className="top-title-section">NOS FONCTIONNALITÉS</h3>
                    <h2 className="title-section">Pourquoi nous Choisir!.</h2>
                    <p className="description-section">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam.</p>
                </Row>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <div className="features d-flex">
                            <div className='startimg  '>
                                <img src={images.im1} alt="" />
                            </div>

                            <div className="info">
                                <h5>Fret aérien</h5>
                                <p>Les meilleures solutions pour les circonstances inattendues dans lesquelles la vitesse est une priorité absolue.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <div className="features d-flex">
                            <div className='startimg '>
                                <img src={images.im3} alt="" />
                            </div>
                            <div className="info">
                                <h5>Fret maritime</h5>
                                <p>La meilleure alternative pour transporter de grandes quantités de fret à des prix compétitifs.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <div className="features d-flex">
                            <div className='startimg '>
                                <img src={images.im6} alt="" />
                            </div>
                            <div className="info">
                                <h5>Fret routier</h5>
                                <p>Nous offrons des services de fret routier fiables en coopération avec notre solide réseau de partenaires.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <div className="features d-flex">
                            <div className='startimg '>
                                <img src={images.im2} alt="" />
                            </div>
                            <div className="info">
                                <h5>expédier partout</h5>
                                <p>Les chaînes d'approvisionnement internationales impliquent des réglementations difficiles.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <div className="features d-flex">
                            <div className='startimg '>
                                <img src={images.im4} alt="" />
                            </div>
                            <div className="info">
                                <h5>Entreposage</h5>
                                <p>Nous offrons un entrepôt à la pointe de la technologie et entièrement sécurisé, afin que votre entreprise soit toujours protégée.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <div className="features d-flex">
                            <div className='startimg '>
                                <img src={images.im5} alt="" />
                            </div>
                            <div className="info">
                                <h5>courtage en douane</h5>
                                <p>nous assurons un traitement plus rapide des documents douaniers et des marchandises, par ordre de priorité.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


















            <div className=" d-flex justify-content-center section5" >
                <Container className=" main-section about  p-4" >
                    <Row>
                        <Col sm={12} md={6} className="d-flex justify-content-center align-items-center">
                            <img src={images.about_company} alt="" />
                        </Col>
                        <Col sm={12} md={6} >
                            <h3 className="top-title-section">À propos de la société</h3>
                            <h2 className="title-section">Nous vous donnons le Contrôle total de Votre envoi.</h2>
                            <p>Aliquam tincidunt sapien et nisi feugiat, nec lacinia ante fringilla. Nullam vestibulum venenatis ante et porttiteory Fusce sit amet bib endum erat. Cras commodo nibh dui Integer eget tincid magna. Aliquam gravida, auglaoreet pulvinar, est lacus fringilla non</p>
                            <p>cursus risus lacus nec lacus. Nam elementum odio risus id bibendum neque sagittis ut. Integer a ipsum nibhonec sed dui arcu.</p>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Container className=" about stock section6 ">
                <Row >
                    <Col sm={12} md={6} >
                        <h3 className="top-title-section">À propos de la société</h3>
                        <h2 className="title-section">Nous déplaçons vos Produits de valeur en toute Sécurité</h2>
                        <p>Curabitur faucibusy tellueu semper nunc finibus plasuspen potent. Praesent vsem in semv utate interdum Etiam egete nibh bibendum, consequat libero.</p>
                        <div className="progress-section">
                            <h6 className="d-flex mt-2">Livré à temps <span className="ms-auto">99%</span></h6>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "99%" }} aria-valuenow="99" aria-valuemin="0" aria-valuemax="99"></div>
                            </div>
                            <h6 className="d-flex mt-2">Biens commerciaux <span className="ms-auto">80%</span></h6>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="80"></div>
                            </div>
                            <h6 className="d-flex mt-2">Livré à temps <span className="ms-auto">99%</span></h6>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "99%" }} aria-valuenow="99" aria-valuemin="0" aria-valuemax="99"></div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className="d-flex justify-content-center align-items-center">
                        <img src={images.why_choose} alt="" />
                    </Col>
                </Row>
            </Container>





























            <Calc hideTitle />



            {/* <Container  id="calculator" style={{paddingTop:"80px"}}>
                <Row>
                    <Col>
                        <h2 className="title-section text-center">Calculez les frais d’expédition</h2>
                    </Col>
                </Row>
                {/* <Row className="pt-2 pb-2">
                    
                    <Col md={12} className="d-flex align-items-center justify-content-center"> 
                        <div className="d-flex align-items-center">
                            <Form.Check
                                type={"radio"}
                                className="me-2"
                                checked={calcS}
                                onChange={(e) => {setCalcS(true)}}
                            />
                            <h5>
                                Calculateur de frais d'expédition
                            </h5>
                        </div>
                        <div className="d-flex align-items-center ms-2"> 
                            <Form.Check
                                type={"radio"}
                                className="me-2"
                                checked={!calcS}
                                onChange={(e) => {setCalcS(false)}}
                            />
                            <h5>
                                Calculateur de frais d'envoi 
                            </h5>
                        </div>
                    </Col>
                    
                </Row> 
                <Row>
                    <Col>
                    <Calc hideTitle/>
                        {/* {calcS?<CalculatorS hideTitle/>:<Calculator hideTitle/>} 
                    </Col>
                </Row>
            </Container> */}










            <div className="bg-dark d-flex justify-content-center pb-5">
                <div className="container main-section contact m-0" id="contact">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                            <div className="contact-form">
                                <span>Besoin de Faire une enquête?</span>
                                <div className="row">
                                    
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-4">
                                            <input type="text" className="form-control" id="name" placeholder="Nom complet" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-4">
                                            <input type="text" className="form-control" id="name" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-4">
                                            <input type="text" className="form-control" id="name" placeholder="Sujet" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-4">

                                            <textarea className="form-control" id="message" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-4">
                                            <button type="button" className="btn main-btn"> Soumettre message </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 col-xs-12 text-light">
                            <h3 className="top-title-section">CONTACTEZ-NOUS</h3>
                            <h2 className="title-section">Comment Pouvons-nous vous Aider</h2>
                            <p className="description-section text-light">Nous sommes expérimentés dans la gestion des formalités et des documents nécessaires à vos importations et exportations. Nous travaillons avec toutes les gares internationales pour garantir que votre chargement arrivera en toute sécurité sans aucun retard.</p>
                            <h4>Support</h4>
                            <div className="info-contact text-light">
                                <div className="icon-contact"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></div>
                                <p>111 Avenue Victor Hugo, 75016 Paris</p>
                            </div>
                            <div className="info-contact text-light">
                                <div className="icon-contact"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></div>
                                <p>contact@exped.wego-box.com</p>
                            </div>
                            <div className="info-contact text-light">
                                <div className="icon-contact"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                </div>
                                <p>01.87.66.19.29</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <footer>
                <Container className="p-4">
                    <Row>
                        <Col  md={6} sm={12}>
                            Mentions légals | Plan de site | Conditions générales de vente
                        </Col>
                        <Col  md={6} sm={12} className="text-end">
                            Copyright © 2022 Creapuls. All Rights Reserved.
                        </Col>
                    </Row>
                    {/* <Row className="row footer">
                        <img src={images.favicon} alt="" />
                        <p>Copyright © 2021 Creapuls. All Rights Reserved.</p>
                        <div className="socials-icon">
                            <a href="https://www.facebook.com/exped.wegobox/" target={"_blank"} className="social-icon facebook"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 1 24 24"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a>
                            <a href="#" target={"_blank"} className="social-icon instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 1 24 24"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a>
                            <a href="#" target={"_blank"} className="social-icon twitter"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 1 24 24"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a>
                            <a href="#" target={"_blank"} className="social-icon linked-in"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 1 24 24"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a>
                            <a href="#" target={"_blank"} className="social-icon telegram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 30 30"><path d="M2.1,14.6C8.9,12,28.5,4,28.5,4l-3.9,22.6c-0.2,1.1-1.5,1.5-2.3,0.8l-6.1-5.1l-4.3,4l0.7-6.7l13-12.3l-16,10  l1,5.7l-3.3-5.3l-5-1.6C1.5,15.8,1.4,14.8,2.1,14.6z"></path></svg></a>
                            <a href="#" target={"_blank"} className="social-icon whatsapp"><svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 30 30"><path className="st0" d="M8.3,26.7c-0.8-0.5-4.7,2.4-5.3,1.7c-0.6-0.6,2-4.7,1.5-5.4C2.9,20.8,2,18.1,2,15.2C2,7.8,8,1.7,15.5,1.7   S29,7.8,29,15.2s-6,13.5-13.5,13.5c-1,0-1.9-0.1-2.8-0.3"></path><path d="M12.7,8.4l1.2,3c0.1,0.2,0,0.4-0.2,0.5c-0.5,0.3-1.2,1-0.4,2.1c1,1.4,3.7,4.9,5.9,2.6c0.1-0.1,0.3-0.2,0.5-0.1   l3.2,1.4c0.2,0.1,0.3,0.4,0.2,0.6c-0.7,1.4-3.4,5.1-10.1-0.7c-6.8-5.9-2.4-8.8-0.9-9.5C12.3,8,12.6,8.1,12.7,8.4z"></path></svg></a>
                        </div>
                    </Row> */}
                </Container>
            </footer>




        </DivC>
    );
}

export default Exped;