import React, { useReducer, createContext, useEffect,useState } from "react";
import useLocalStorage from "./useLocalStorage";
import md5 from 'md5-hash'
const initialState = {
  islogin:false,
  user:{},
  lang:"en"
};

export const StateContext = createContext();
export const DispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOGIN":
        return {
          ...state,
          user: {
            ...action.payload.user,
            token:md5(action.payload.user.rol+"exped123"+action.payload.user.id+action.payload.user.session)
          }
    };

    case "TOGGLE_LOGIN_STATE":
        return {
          ...state,
          islogin: true
    };
    
    case "TOGGLE_LOGOUT":
      return {
        ...state,
        user:{},
        islogin: false
    };
    case "CHANGE_LANG":
      return {
        ...state,
        lang:action.payload.lang
    };
    case "CHANGE_COLIS":
      return {
        ...state,
        globalStates:{
          ...state.globalStates,
          colis:action.payload.colis
        }
    };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};


export const loginUser=(dispatch,user) =>{
  return dispatch({
    type: "TOGGLE_LOGIN",
    payload: {
      user: user
    }
  }),dispatch({type: "TOGGLE_LOGIN_STATE"});
}
export const updateUserContext=(dispatch,user) =>{
  return dispatch({
    type: "TOGGLE_LOGIN",
    payload: {
      user: {
        ...user
      }
    }
  });
}

export const logout=(dispatch) =>{
  return dispatch({
    type: "TOGGLE_LOGOUT"
  });
}
export const changeLang=(dispatch,lang) =>{
  return dispatch({
    type: "CHANGE_LANG",
    payload: {
      lang: lang
    }
  });
}
export const changeColis=(dispatch,colisData) =>{
  return dispatch({
    type: "CHANGE_COLIS",
    payload: {
      colis:colisData
    }
  });
}

//"_1997_"+rol+"-exped-$$"  md5
const rols=[
  "08dc08c127e8290e1e2a574a307bff7a",
  "acd0532d202d27c621ba5ef3fdabd5a1",
  "e47ceef0bd1d5ba1302f5d6d4f3fecf1"
]

const ExpedProvider = ({ children }) => {
  const [userdata, setuserdata] = useLocalStorage("user",{});
  const [islogin, setislogin] = useLocalStorage("islogin",false);
  const [lang, setLang] = useLocalStorage("lang","en");
  const [globalStates, setGlobalStates] = useState({colis:null});
  const info = {
    islogin:islogin,
    user: {
      ...userdata
    },
    lang:lang,
    globalStates
  };

  const [state, dispatch] = useReducer(reducer, info);

  useEffect(() => {
    setuserdata(state.user);
    setislogin(state.islogin);
    setLang(state.lang)
  }, [state.user,state.islogin,state.lang,state.globalStates]);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default ExpedProvider;