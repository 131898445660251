import React from 'react';
import styled from "styled-components"
import ImageGallery from 'react-image-gallery';
import {MdClear} from "react-icons/md"
const DivC=styled.div`

position: fixed;
top: 0;
left: 0;
right:0;
height: 100vh;
z-index:9999;
background-color: #000A;
.gl{
    max-height: 100%!important;
}
.btnE{
    position: absolute;
    top:10px;
    right:10px;
    width: 50px;
    height: 50px;
    color: #fff;
    background-color: #ff3f39;
    font-size: 22px;
    cursor: pointer;
    z-index: 1000;
}
.btnE:hover{
    background-color: #e91e17;;
}
`

function GalleryImageModal(props) {
    return (
        <DivC> 
            <MdClear className="btnE "  onClick={props.hideModal}/>
            <ImageGallery 
            startIndex={props.index}
            className="gl" 
            items={props.images}
            showPlayButton={false} />
        </DivC>
    );
}

export default GalleryImageModal;