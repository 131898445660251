
import React from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { Container, Row, Col,Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import images from '../../assets/images';
import {FcCheckmark}from "react-icons/fc"
const DivC = styled.div`
padding-top:80px;
padding-bottom:80px;
background-color: #2b2b2b;
color:#000;
overflow: hidden;
h2{
    font-weight: 900;
}

p{
    font-size: 14px;
    text-align: center;
    line-height:14px;
}
.c-green{
    color:var(--main-color)
}
.tarif{
    position: relative;
    background-color:#FFF;
    width: 100%;
    max-width: 300px;
    box-shadow: 0px 0px 20px 0px #6663;
}
.tarif ul{
    list-style-type: none;
    padding:0;
    margin:0;
}
.tarif-header{
    height:160px;
    position:relative;
    padding:12px;
    background:linear-gradient(45deg,#e5e4e4,#fefefe,#e5e4e4);
    text-align:center;
}
.tarif-header img{
    height:70%
}
.tarif-header h1{
    font-size:24px;
    font-weight: 800;
}
.tarif-header h3{
    font-size:19px;
    font-weight: 600;
    text-transform: uppercase;
}

.tarif-body{
    padding:20px;
    position:relative;
    background-color:#FFF;
}
.tarif-primeum .tarif-body{
    /* padding-top:30px; */
}

.tarif-body h3{
    font-size: 18px;
    font-weight: 800;
    margin-top:20px;
}
.tarif-body li{
    font-size: 14px;
    font-weight: 600;
    margin-bottom:12px;
    display:flex;
}
.tarif-body li svg{
    font-size: 16px!important;
}
.tarif-primeum{
    transform:translate(-8px,-20px);
    padding-bottom:30px
}
.tarif-primeum .tarif-header{
    background:linear-gradient(45deg,#ecaf5c,#f8df8b,#ecaf5c)
}
.tarif button{
    width:80%;
    background-color:var(--main-color);
    color: #FFF;;
    border-radius:0;
}
.path1{
    position:absolute;
    left:0%;
    height:50%;
    top:50%;
    transform:translate(-40%,-50%)
}
.path2{
    position:absolute;
    right:0%;
    height:50%;
    top:50px;
    transform:translateX(40%)
}
.path1 img,.path2 img{
    height:100%
}
.logo-left{
    position:absolute;
    top:75px;
    left:0;
    transform:translateX(-70%);
    height:40%
}
.logo-right{
    position:absolute;
    top:40px;
    right:0;
    transform:translateX(67%);
    height:40%
}
.circle10{
    position:absolute;
    top:0;
    right:0;
    transform:translate(50%,-50%);
    height:100px;
    width:100px;
    box-shadow: 0px 0px 20px 0px #6661;
    border-radius:50%
}
@media screen and (max-width: 850px){
    
    .tarifs{
        flex-direction: column;
        align-items: center!important;
    }
    .tarif-primeum{
        transform:none;
        top:60px;
        margin-bottom:30px;
    }

    
}

.ant{animation:ant .3s}
@keyframes ant{
    from{
        transform:translateY(50px);
    }
    to{
        transform:none
    }
}
`

function Tarif() {
  return (
    <DivC id="tarif">
        <Container>
            <Row>
                <Col>
                    <h2 className="text-center text-light">
                       Tarifs
                    </h2>
                    <p className="text-center text-light">Choisissez l'offre qui vous correspond le mieux</p>
                </Col>
            </Row>
            <Row className="mt-3">
               <ScrollAnimation offset={0} animatePreScroll={false} initiallyVisible={false} animateIn="ant" className="col-12 d-flex justify-content-center align-items-start tarifs">
                    <div className="tarif">
                        <div className="path1">
                            <img src={images.path1} alt="" />
                        </div>
                        <div className="tarif-header d-flex flex-column ">
                            <div className="flex-grow-1 d-flex  align-items-center justify-content-center">
                                <img src={images.star1} alt="" />
                            </div>
                            <h1>
                                Accés Standard
                            </h1>
                            <h3 >
                                Gratuit
                            </h3>
                        </div>
                        <div className="tarif-body">
                            <ul>
                                <li><div className="me-3"><FcCheckmark/></div><span> Nombre de colis illimité.</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>stockage gratuit pendant 20 jours (puis France : 1.50€ HT/colis/jour)</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>Paiment par paypal et carte</span></li>
                                <h3>Options :</h3>
                                <li><div className="me-3"><FcCheckmark/></div><span>Ouverture du colis et verification avec photos à 8€ HT/colis (France)</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>Groupage de vos colis à 1.50€ HT/colis (France)</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>Extraction de la facture originale à 4€ HT par colis (France)</span></li>
                            </ul>
                            <div className='d-flex justify-content-center'>
                                <button className="btn ">
                                    Créer mon compte
                                </button>
                            </div>
                        </div>
                        <img src={images.logo_left} alt="" className="logo-left"/>
                    </div>
                    <div className="tarif tarif-primeum  align-items-center">
                        <div className="path2">
                            <img src={images.path1} alt="" />
                        </div>
                        <div className="tarif-header d-flex flex-column ">
                            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                            <img src={images.star2} alt="" />
                            </div>
                            <h1>
                                Accés Premium
                            </h1>
                            <h3 >
                                a partir de 3.25€/mois
                            </h3>
                        </div>
                        <div className="tarif-body">
                            <ul>
                                <li><div className="me-3"><FcCheckmark/></div><span>Nombre de colis illimité.</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>stockage gratuit pendant 60 jours (puis France : 1.50€ HT/colis/jour)</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>Paiment par paypal et carte</span></li>
                                <h3>Options :</h3>
                                <li><div className="me-3"><FcCheckmark/></div><span>Ouverture du colis et verification avec photos à 8€ HT/colis (France)</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>Groupage de vos colis à 1.50€ HT/colis (France)</span></li>
                                <li><div className="me-3"><FcCheckmark/></div><span>Extraction de la facture originale à 4€ HT par colis (France)</span></li>
                            </ul>
                            <div className='d-flex justify-content-center mt-3'>
                                <button className="btn ">
                                    Je m'abonne
                                </button>
                            </div>
                        </div>
                        <img src={images.logo_right} alt="" className="logo-right"/>
                        <img src={images.circle10} alt="" className="circle10"/>
                    </div>
               
               </ScrollAnimation>
            </Row>
        </Container>
    </DivC>
  )
}

export default Tarif