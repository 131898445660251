import req from "../Config/http-common";
import ApiConfig from "../Config/ApiConfig";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))

const  addStore =  (ob) => {
    console.log(ob)
    return req.http3.post("/store/",{
        session:getSession(),
        ...ob,
        add_store:"true"
    });
};

const  updateStore =  (ob) => {
  
    return req.http3.post("/store/",{
        session:getSession(),
        ...ob,
        update_store:"true"
    });
};
const  updatePriceData =  (ob) => {
  
    return req.http3.post("/store/",{
        session:getSession(),
        ...ob,
        update_price_data:"true"
    });
};


const  getAll =  (user) => {
  
    return req.http3.post("/store/",{
        session:getSession(),
        get_all:true
    });
};
const  getAllColis =  (user) => {
  
    return req.http3.post("/store/",{
        session:getSession(),
        get_all_colis:true
    });
};
const addColis=(ob)=>{
    ob.append("session",getSession());
    return req.httpUpload.post("/store/",ob);
}
const updateColis=(ob)=>{
    ob.append("session",getSession());
    return req.httpUpload.post("/store/",ob);
}

const sendColis=(id_colis)=>{
    return req.http3.post("/store/",{
        id_colis,
        session:getSession(),
        send_colis:true
    });
}

const requestPay=(id_colis)=>{
    return req.http3.post("/store/",{
        id_colis,
        session:getSession(),
        request_pay:true
    });
}
const getPrice=(w)=>{
    return req.http3.post("/store/price.php",{
        weight:w,
        session:getSession(), 
        get_price:true
    });
}

const changeState=(ob)=>{
    return req.http3.post("/store/",{
        id_colis:ob.id_colis,
        new_state:ob.new_state,
        session:getSession(),
        change_state:true,
        
    });
}
const changeStateStore=(id_store,state)=>{
    return req.http3.post("/store/",{
        id_store,
        state,
        change_state_store:true,
        session:getSession()
    });
}
const  Calculator =  () => {
  
    return req.http3.post("/store/",{
        session:getSession(),
        calculator:true
    });
};

const paimentInfo=(id_colis)=>{
    return req.http3.post("/store/",{
        id_colis,
        session:getSession(),
        info_for_paiment:true
    });
}


const StoreService = {
    addStore,
    getAll,
    updateStore,
    addColis,
    getAllColis,
    updateColis,
    sendColis,
    changeState,
    requestPay,
    getPrice,
    updatePriceData,
    changeStateStore,
    Calculator,
    paimentInfo
};
export default StoreService;