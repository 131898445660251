import req from "../Config/http-common";
import ApiConfig from "../Config/ApiConfig";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))

const  getBanks =  (all) => {
    return req.http3.post("/shipping/",{
        get_banks:true,
        all,
        session:getSession()
    });
};
const addBank=(ob)=>{
    ob.append("session",JSON.stringify(getSession()));
    return req.httpUpload.post("/shipping/",ob);
}
const updateBank=(ob)=>{
    ob.append("session",JSON.stringify(getSession()));
    return req.httpUpload.post("/shipping/",ob);
}


const  getTranportCompani =  () => {
    return req.http3.post("/shipping/",{
        get_transport_compani:true,
        session:getSession()
    });
};
const addTranportCompani=(ob)=>{
    ob.append("session",JSON.stringify(getSession()));
    return req.httpUpload.post("/shipping/",ob);
}
const updateTranportCompani=(ob)=>{
    ob.append("session",JSON.stringify(getSession()));
    return req.httpUpload.post("/shipping/",ob);
}



const changeStateBank=(id_bank,state)=>{
    
    return req.http3.post("/shipping/",{
        id_bank,
        state,
        change_state_banks:true,
        session:getSession()
    });
}
const addShipping=(ob)=>{
    
    return req.http3.post("/shipping/",{
        ...ob,
        id_user:getSession().id,
        add_shipping:true,
        session:getSession()
    });
}
const updateShipping=(ob)=>{
    
    return req.http3.post("/shipping/",{
        ...ob,
        id_user:getSession().id,
        update_shipping:true,
        session:getSession()
    });
}
const getShippings=()=>{
    return req.http3.post("/shipping/",{
        get_shippings:true,
        session:getSession()
    });
}

const ApiConvert=()=>{
    return req.http3.post("/shipping/convert2.php")
}
const sendProof=(ob)=>{
    ob.append("session",JSON.stringify(getSession()));
    ob.append("send_proof",true);
    return req.httpUpload.post("/shipping/",ob);
}

const ShippingService = {
    sendProof,
    getBanks,
    addBank,
    updateBank,
    changeStateBank,
    ApiConvert,
    addShipping,
    updateShipping,
    getShippings,
    getTranportCompani,
    addTranportCompani,
    updateTranportCompani
};
export default ShippingService;