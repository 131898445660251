import {useState} from 'react';
import {Container,Row,Col} from "react-bootstrap"
import {FaDollarSign} from "react-icons/fa"
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import {TiLocationArrowOutline} from "react-icons/ti"
import styled from "styled-components"
import { useIntl } from 'react-intl';
import Bank from "./Bank"
import {MdSettings} from "react-icons/md"
import TransportCompani from './TransportCompani';
import GeneralParamater from './GeneralParamater';
import {IoNotificationsOutline,IoNotifications} from "react-icons/io5"
import ManageNotifications from './ManageNotifications';
const DivC=styled.div`
.list-group .list-group-item{
    cursor: pointer;
    border: none;
    border-radius: 5px;
}
.list-group-item.active{
    background: linear-gradient(45deg,#0eb334,#4ef981)!important;
}
.list-group-item:active{
    background: linear-gradient(45deg,#0eb334,#4ef981)!important;
}

`
function Tools(props) {
    const [step,setStep]=useState(1);
    const navigate=useNavigate()
        
    const changeStep=(v)=>{
        navigate("/tools")
        setStep(v);
    }
    
    const intl = useIntl();
    
    return (

        <DivC className="container p-0 pt-3">
            <h3>{intl.formatMessage({ id: 'TOOLS' })}</h3>
            <hr/>
            <Row>
                <Col lg={3} md={4} sm={12}>
                    <div className="list-group">
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==1?"active":""}`} onClick={()=>changeStep(1)}>
                            
                            <FaDollarSign className="me-3"/>
                            
                            {intl.formatMessage({ id: 'BANK_MANAG' })}
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==2?"active":""}`} onClick={()=>changeStep(2)}>
                            
                            <FaDollarSign className="me-3"/>
                            
                            Entreprises de transport
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==3?"active":""}`} onClick={()=>changeStep(3)}>
                            
                            <MdSettings className="me-3"/>
                            
                            Paramètre général
                        </div>
                        <div href="#" className={`mb-1 list-group-item list-group-item-action ${step==4?"active":""}`} onClick={()=>changeStep(4)}>
                            <IoNotifications className="me-3"/>
                            Manage notifications
                        </div>
                    </div> 
                </Col>
                <Col lg={9} md={8} sm={12} >
                {
                    step==1&&<Bank/>
                }
                {
                    step==2&&<TransportCompani/>
                }
                {
                    step==3&&<GeneralParamater/>
                }
                {
                    step==4&&<ManageNotifications/>
                }
                
                </Col>
            </Row>
        </DivC>
    );
}

export default Tools;