
import {useState,useContext,useEffect} from 'react';
import { StoreTable } from '../Views/Table';
import { Container,Col,Row } from 'react-bootstrap';
import { Link ,useNavigate} from 'react-router-dom';
import Loader from '../Views/Loader';
import {StateContext} from "../Context/";
import {Toast,ToastStyle} from "../Views/Toast"
import UserService from '../Service/UserService';
import CardAddress from "../Views/CardAddress"
import Modal from "../Views/Modal"
import { useIntl } from 'react-intl';
const stylebtn = {
    minWidth: "100px"
}

function DeliveryAdreesList(props) {
    const [data,setData]=useState(null);
    const {user } = useContext(StateContext);
     const [country,setCountry]=useState(null);
    const navigate=useNavigate()
    const getData=()=>{
        UserService.getAllDeliveryAddress(user)
        .then(res=>{
            setData(res.data.data);
            setCountry(res.data.country);
        }).catch(err=>{
            setData([])
            Toast.error(err.message,ToastStyle);
        })
    }
    useEffect(()=>{
       getData()
    },[])
    const intl = useIntl();



    const [showModal, setShowModal] = useState(false);
    const [item, setItem] = useState(null);
    const showModalF = (ob) => {
        setItem(ob);
        setShowModal(true)
    }
    const deleteNow = () => {
        setLoding(true)
        UserService.deleteDeliveryAddress(item)
        .then(res => {
           if(res.data.error==""){
                Toast.success("delete success",ToastStyle);
                getData(true);
                hideModallF()
           }else{
                Toast.error(res.data.error, ToastStyle);
           }
           setLoding(false)
        }).catch(err=>{
            setLoding(true)
            Toast.error(err.message, ToastStyle);
        });
        
    }
    const hideModallF = () => {
        setShowModal(false);
        setItem(null)
    }
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    return (
        <>{data==null ?  <Loader no={true} /> :
        <>
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <button onClick={()=>navigate("/profile/delivery/add",{state:{...user,countries:country}})} className="btn btn-primary">
                    {intl.formatMessage({ id: 'ADD' })}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col className="container">
                <Row>
                {
                   
                   data.map(el=>{
                       return <Col md={12} lg={6}>
                        <CardAddress data={el} countries={country} hasDelete={data.length>1} showModalF={showModalF}/>   
                        </Col>
                   })
                }
                </Row>
                </Col>
            </Row>


            <Modal
                show={showModal}
                showbtn={false}
                title={"Voulez-vous supprimer ?"}
                onHide={hideModallF}

            >
                



                <div className='d-flex justify-content-end mt-2'>
                    <button className='btn btn-success me-1' style={stylebtn} onClick={() => deleteNow()} >
                        {loding ? spanLoding : ""}
                        {intl.formatMessage({ id: 'YES' })}
                    </button>
                    <button className='btn btn-danger' style={stylebtn} onClick={() => hideModallF()}>

                        {intl.formatMessage({ id: 'NO' })}
                    </button>
                </div>
            </Modal>
        </>
        }
        </>
    );
}

export default DeliveryAdreesList;