import {useState} from 'react';
import { Container } from 'react-bootstrap';

function Info(props) {
    return (
        <Container>
            <h4>Info</h4>
        </Container>
    );
}

export default Info;