import {useState,useContext,useEffect} from 'react';
import { StoreTable } from '../Views/Table';
import { Container,Col,Row } from 'react-bootstrap';
import { Link ,useNavigate} from 'react-router-dom';
import Loader from '../Views/Loader';
import {StateContext} from "../Context/";
import {Toast,ToastStyle} from "../Views/Toast"
import ShippingService from "../Service/ShippingService"
import { useIntl } from 'react-intl';
import {BankTable} from "../Views/Table"

function ListBank(props) {
    const [data,setData]=useState(null);
    const {user } = useContext(StateContext);
    const navigate=useNavigate()
    const getData=()=>{
        ShippingService.getBanks(true)
        .then(res=>{
            setData(res.data.data);
        }).catch(err=>{
            setData([])
            Toast.error(err.message,ToastStyle);
        })
    }
    useEffect(()=>{
      getData()
    },[])
    const intl = useIntl();
    const changeStateBank=(id,state)=>{
        ShippingService.changeStateBank(id,state==0?1:0)
        .then(res=>{
            if(res.data.error==""){
                getData()
            }else{
                Toast.error(res.data.error,ToastStyle);
            }

        }).catch(err=>{
            Toast.error(err.message,ToastStyle);
        })
    }
    return (
        data?<>
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <button onClick={()=>navigate("/tools/bank/add",{state:{user,currency:data.currency}})} className="btn btn-primary">
                    {intl.formatMessage({ id: 'ADD' })}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col className="container p-0">
                    <BankTable
                            changeStateBank={changeStateBank}
                            data={data.banks.map(el=>{
                                return {...el,action:el}
                            })}
                            currency={data.currency}
                        />
                        
                </Col>
            </Row>
        </>:
        <Loader no={true} />
    );
}

export default ListBank;