import React from 'react';
import styled from 'styled-components';


const DivC=styled.div`
height: 100vh;


.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 80px;
  height: 80px;
}
.path {
    stroke: #0eb334;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

`
function Loader(props) {
    return (
        <DivC className="d-flex justify-content-center align-items-center" style={{height:`${props.no?"auto":"100vh"}`}}>
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </DivC>
    );
}

export default Loader;