import React ,{useState}from 'react'
import { GoogleMap,Marker } from '@react-google-maps/api';

function GoogleMapStore(props) {


  
    const [marker,setMArker]=useState([])
    setTimeout(
        ()=>{
            if(props.storeLocation){
                setMArker([props.storeLocation])
            }
        },200
    )
 
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center={ lat: 36.74276055469744, lng: 3.0644041165314775 }
    return <GoogleMap

            mapContainerStyle={containerStyle}
            defaultCenter={center}
            onClick={(e)=>{
                props.setStoreLocation(
                    {
                        lat:e.latLng.lat(),
                        lng:e.latLng.lng()
                    }
                )
              
            }}
            center={props.storeLocation?props.storeLocation:center}
            zoom={8}
      >
         {
             marker.map((el,index)=>{
                 return <Marker
                 key={index}
                        position={
                            el
                        }
                    />
             })
           
            
           
              
          }
        
      </GoogleMap>;
}

export default GoogleMapStore