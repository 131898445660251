import React, { useContext } from 'react';

import {CgUserList} from "react-icons/cg"
import {GoPackage,GoTools} from "react-icons/go"
import {BsCalculator,BsPatchCheckFill} from "react-icons/bs"
import {VscAccount} from "react-icons/vsc"
import {FaDollarSign,FaListAlt,FaCcVisa} from "react-icons/fa"
import {BiSlideshow} from "react-icons/bi"
import { useIntl } from 'react-intl';
import {GrVisa} from "react-icons/gr"
import {AiOutlineDollarCircle,AiFillDollarCircle,AiOutlineDashboard} from "react-icons/ai"
import {GiAirplaneArrival,GiBank} from "react-icons/gi"
import {IoCodeWorkingSharp,IoStorefrontOutline,IoNotificationsOutline} from "react-icons/io5"

import {MdOutlineErrorOutline,MdSend,MdPayment} from "react-icons/md"
import { StateContext } from '../Context';


export const  SidebarData=() =>{
    const intl = useIntl();
    const { user,globalStates } = useContext(StateContext);
    const states=[
        {
            label:"Colis dans l’entrepôt",
            action:"a",
            icon:<GiAirplaneArrival/>
        },
        {
            label:intl.formatMessage({ id: 'COLIS_PREP' }),
            action:"b",
            icon:<IoCodeWorkingSharp/>
        },
        {
            label:intl.formatMessage({ id: 'COLIS_ATT_PAIE' }),
            action:"c",
            icon:<AiOutlineDollarCircle/>
        },
        {
            label:intl.formatMessage({ id: 'COLIS_ATT_VALID' }),
            action:"d",
            icon:<AiFillDollarCircle/>
        },
        {
            label:intl.formatMessage({ id: 'COLIS_PAIE_ACC' }),
            action:"e",
            icon:<AiFillDollarCircle/>
        },
        {
            label:intl.formatMessage({ id: 'COLIS_REJ' }),
            action:"f",
            icon:<MdOutlineErrorOutline/>
        },
        {
            label:intl.formatMessage({ id: 'COLI_EN_ROUT' }),
            action:"h",
            icon:<MdSend/>
        },
        {
            label:intl.formatMessage({ id: 'COLIS_ARR' }),
            action:"i",
            icon:<BsPatchCheckFill/>
        },
    ]
    return {
        "superAdmin":[
            {
                title: intl.formatMessage({ id: 'DASHBOARD' }),
                path: '/dashboard',
                icon: <AiOutlineDashboard/>,
                cName: 'nav-text',
                type:"home",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'ENTREPOT' }),
                path: '/store',
                icon: <IoStorefrontOutline />,
                cName: 'nav-text',
                type:"warehouse",
                subItem:[]
            },
            
            {
                title: "Outils d'expédition",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },
            {
                title: "Expédition de colis",
                path: '/colis',
                icon: <GoPackage />,
                cName: 'nav-text',
                type:"colis",
                subItem:[
                    {
                        title: "Tous les colis",
                        path: '/colis',
                        icon: <FaListAlt/>,
                        cName: 'nav-text',
                        type:"shipping",
                        suffix:<span className="badge white">{globalStates?(globalStates.colis?"("+globalStates.colis.length+")":""):""}</span>,
                        subItem:[]
                    },
                    ...states.map((el,key)=>{
                        let y="";
                        let x=globalStates?(globalStates.colis?globalStates.colis:null):null;
                        if(x)
                            y="("+globalStates.colis.filter(item=>item.state==key).length+")"
                        return  {
                            title: el.label  ,
                            path: '/colis/'+el.action,
                            icon: el.icon,
                            cName: 'nav-text',
                            type:"shipping",
                            suffix:<span className="badge white">{y}</span>,
                            subItem:[]
                        }
                    })
                ]
            },
            {
                title: "Comment expédier ?",
                path: '/colis/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'CALCULATOR' }),
                path: '/colis/calculator',
                icon: <BsCalculator />,
                cName: 'nav-text',
                type:"calc",
                subItem:[]
            },

            {
                title: "Outils financiers",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },

            {
                title: "Demander une carte",
                path: '/visa',
                icon: <GrVisa />,
                cName: 'nav-text',
                type:"visa",
                subItem:[
                    {
                        title: "Demande de carte",
                        path: '/visa/request',
                        icon: <FaCcVisa/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                    {
                        title: "Suivi de carte",
                        path: '/visa/trac',
                        icon: <FaCcVisa/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                ]
            },
            {
                title: "Recharges",
                path: '/shipping',
                icon: <FaDollarSign/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[
                    {
                        title: "Historique de recharge",
                        path: '/shipping',
                        icon: <FaListAlt/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                    {
                        title: "Recharger",
                        path: '/shipping/add',
                        icon: <FaDollarSign/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    }
                    
                ]
            },
            {
                title: "Comment demander ?",
                path: '/visa/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: "Comment recharger ?",
                path: '/shipping/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'CALCULATOR' }),
                path: '/shipping/calculator',
                icon: <BsCalculator />,
                cName: 'nav-text',
                type:"calc",
                subItem:[]
            },
            {
                title: "INFORMATIONS",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },
            {
                title: intl.formatMessage({ id: 'USERS' }),
                path: '/users',
                icon: <CgUserList />,
                cName: 'nav-text',
                type:"users",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'NOTIFICATIONS' }),
                path: '/notifications',
                icon: <IoNotificationsOutline />,
                cName: 'nav-text',
                type:"not",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'TOOLS' }),
                path: '/tools',
                icon: <GoTools/>,
                cName: 'nav-text',
                type:"",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'PROFILE' }),
                path: '/profile',
                icon: <VscAccount/>,
                cName: 'nav-text',
                type:"account",
                subItem:[]
            }
            
          
        ],







        "admin":[
            {
                title: intl.formatMessage({ id: 'DASHBOARD' }),
                path: '/dashboard',
                icon: <AiOutlineDashboard/>,
                cName: 'nav-text',
                type:"home",
                subItem:[]
            },
            {
                title: "Outils d'expédition",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },

            {
                title: "Expédition de colis",
                path: '/colis',
                icon: <GoPackage />,
                cName: 'nav-text',
                type:"colis",
                subItem:[
                  

                    {
                        title: "Tous les colis",
                        path: '/colis',
                        icon: <FaListAlt/>,
                        cName: 'nav-text',
                        type:"shipping",
                        suffix:<span className="badge white">{globalStates?(globalStates.colis?"("+globalStates.colis.length+")":""):""}</span>,
                        subItem:[]
                    },
                    ...states.map((el,key)=>{
                        let y="";
                        let x=globalStates?(globalStates.colis?globalStates.colis:null):null;
                        if(x)
                            y="("+globalStates.colis.filter(item=>item.state==key).length+")"
                        return  {
                            title: el.label  ,
                            path: '/colis/'+el.action,
                            icon: el.icon,
                            cName: 'nav-text',
                            type:"shipping",
                            suffix:<span className="badge white">{y}</span>,
                            subItem:[]
                        }
                    })
                ]
            },
            {
                title: "Comment expédier ?",
                path: '/colis/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'CALCULATOR' }),
                path: '/colis/calculator',
                icon: <BsCalculator />,
                cName: 'nav-text',
                type:"calc",
                subItem:[]
            },

            {
                title: "Outils financiers",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },

            {
                title: "Demander une carte",
                path: '/visa',
                icon: <GrVisa />,
                cName: 'nav-text',
                type:"visa",
                subItem:[
                    {
                        title: "Demande de carte",
                        path: '/visa/request',
                        icon: <FaCcVisa/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                    {
                        title: "Suivi de carte",
                        path: '/visa/trac',
                        icon: <FaCcVisa/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                ]
            },
            {
                title: "Recharges",
                path: '/shipping',
                icon: <FaDollarSign/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[
                    {
                        title: "Historique de recharge",
                        path: '/shipping',
                        icon: <FaListAlt/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                    {
                        title: "Recharger",
                        path: '/shipping/add',
                        icon: <FaDollarSign/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    }
                    
                ]
            },
            {
                title: "Comment demander ?",
                path: '/visa/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: "Comment recharger ?",
                path: '/shipping/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'CALCULATOR' }),
                path: '/shipping/calculator',
                icon: <BsCalculator />,
                cName: 'nav-text',
                type:"calc",
                subItem:[]
            },
            {
                title: "INFORMATIONS",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },
            {
                title: intl.formatMessage({ id: 'USERS' }),
                path: '/users',
                icon: <CgUserList />,
                cName: 'nav-text',
                type:"users",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'NOTIFICATIONS' }),
                path: '/notifications',
                icon: <IoNotificationsOutline />,
                cName: 'nav-text',
                type:"not",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'PROFILE' }),
                path: '/profile',
                icon: <VscAccount/>,
                cName: 'nav-text',
                type:"account",
                subItem:[
                   
                ]
            },
          
        ],










        "user":[
            {
                title: intl.formatMessage({ id: 'DASHBOARD' }),
                path: '/dashboard',
                icon: <AiOutlineDashboard/>,
                cName: 'nav-text',
                type:"home",
                subItem:[]
            },

            {
                title: "Outils d'expédition",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },
            {
                title: "Expédition de colis",
                path: '/colis',
                icon: <GoPackage />,
                cName: 'nav-text',
                type:"colis",
                subItem:[
                    {
                        title: "Tous les colis",
                        path: '/colis',
                        icon: <FaListAlt/>,
                        cName: 'nav-text',
                        type:"shipping",
                        suffix:<span className="badge white">{globalStates?(globalStates.colis?"("+globalStates.colis.length+")":""):""}</span>,
                        subItem:[]
                    },
                    ...states.map((el,key)=>{
                        let y="";
                        let x=globalStates?(globalStates.colis?globalStates.colis:null):null;
                        if(x)
                            y="("+globalStates.colis.filter(item=>item.state==key).length+")"
                        return  {
                            title: el.label  ,
                            path: '/colis/'+el.action,
                            icon: el.icon,
                            cName: 'nav-text',
                            type:"shipping",
                            suffix:<span className="badge white">{y}</span>,
                            subItem:[]
                        }
                    })
                ]
            },
            {
                title: "Comment expédier ?",
                path: '/colis/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'CALCULATOR' }),
                path: '/colis/calculator',
                icon: <BsCalculator />,
                cName: 'nav-text',
                type:"calc",
                subItem:[]
            },

            {
                title: "Outils financiers",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },

            {
                title: "Demander une carte",
                path: '/visa',
                icon: <GrVisa />,
                cName: 'nav-text',
                type:"visa",
                subItem:[
                    {
                        title: "Demande de carte",
                        path: '/visa/request',
                        icon: <FaCcVisa/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                    {
                        title: "Suivi de carte",
                        path: '/visa/trac',
                        icon: <FaCcVisa/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                ]
            },
            {
                title: "Recharges",
                path: '/shipping',
                icon: <FaDollarSign/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[
                    {
                        title: "Historique de recharge",
                        path: '/shipping',
                        icon: <FaListAlt/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    },
                    {
                        title: "Recharger",
                        path: '/shipping/add',
                        icon: <FaDollarSign/>,
                        cName: 'nav-text',
                        type:"shipping",
                        subItem:[]
                    }
                    
                ]
            },
            {
                title: "Comment demander ?",
                path: '/visa/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: "Comment recharger ?",
                path: '/shipping/how_to',
                icon: <BiSlideshow/>,
                cName: 'nav-text',
                type:"shipping",
                subItem:[]
            },
            {
                title: intl.formatMessage({ id: 'CALCULATOR' }),
                path: '/shipping/calculator',
                icon: <BsCalculator />,
                cName: 'nav-text',
                type:"calc",
                subItem:[]
            },
            {
                title: "INFORMATIONS",
                path: '#',
                icon: <GiBank/>,
                cName: 'nav-text',
                type:"title",
                subItem:[
                    
                ]
            },
            {
                title: intl.formatMessage({ id: 'NOTIFICATIONS' }),
                path: '/notifications',
                icon: <IoNotificationsOutline />,
                cName: 'nav-text',
                type:"not",
                subItem:[]
            },
           
            {
                title: intl.formatMessage({ id: 'PROFILE' }),
                path: '/profile',
                icon: <VscAccount/>,
                cName: 'nav-text',
                type:"account",
                subItem:[
                   
                ]
            },
          
        ]
    }
}