import React,{useState,useEffect} from 'react';

import {Col,Row ,Form,Container,Button} from 'react-bootstrap';
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import StoreService from "../Service/StoreService"
import {Toast,ToastStyle} from "../Views/Toast"
import GoogleMapStore from '../Views/GoogleMapStore';
import { useIntl } from 'react-intl';


const storeShema =(c)=> Yup.object().shape({

    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    country: Yup.string()
        .ensure()
      .required("country is required!") .required('Required')
    ,
    state: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    city: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    zip_code: Yup
      .number()
      .min(100, 'Too Short!')
      .max(99999999, 'Too Long!')
      .required('Required'),
    address_line1: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),  
    address_line2: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    company: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone:Yup
    .string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, {
      message: "Invalid number +12345678900"
    })
    .required('Required'),
});

function AddStore({update}) {
    const [loding,setLoding]=useState(false);
    const location =useLocation()

    const datar=location.state;
    const countries=!update?datar.country:datar.countries;
    console.log(countries)
    const clear=(actions)=>{
        setTimeout(() => {
            
            actions.setSubmitting(false)
            actions.resetForm();
            setStoreLocation(null)
           
        }, 200);
        
    }

    const intl = useIntl();
    const add_store=(ob,actions)=>{
        
        setLoding(true)
        StoreService.addStore(ob)
        .then(res=>{
            
            if(res.data.error===""){
                Toast.success("Add success",ToastStyle);
                clear(actions)
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const update_store=(ob,actions)=>{
        
        setLoding(true)
        StoreService.updateStore(ob)
        .then(res=>{
            
            if(res.data.error===""){
                Toast.success("update success",ToastStyle);
               // clear(actions)
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }


    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    
    const [storeLocation,setStoreLocation]=useState(
        datar&&datar.location?{
            lat:parseFloat(datar.location.split(",")[0]),
            lng:parseFloat(datar.location.split(",")[1])
        }:null
    )


   
    
 

    
    
    return (
        <>
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <Link to="/store" className="btn btn-default">
                        {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
            </Row>
            <Formik
                initialValues={{
                    name: !update?'':datar.name,
                    country: !update?'':datar.country,
                    state:!update?'':datar.state,
                    city:!update?'':datar.city,
                    zip_code:!update?'':datar.zip_code,
                    address_line1:!update?'':datar.address_line1,
                    address_line2:!update?'':datar.address_line2,
                    company:!update?'':datar.company,
                    email: !update?'':datar.email,
                    phone:!update?'':datar.phone
                }}

                validationSchema={storeShema(countries.map(el=>el.id_country))}
                onSubmit={(values,actions) => {
                    let p="";
                    if(storeLocation){
                        p=storeLocation.lat+","+storeLocation.lng;
                    }
                    if(!update)
                        add_store({...values,location:p},actions);
                    else
                        update_store({...datar,...values,location:p});
                }}
            >
                {
                    (
                        { 
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors 
                        }
                    ) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="name">
                                    <Form.Label>{intl.formatMessage({ id: 'NAME' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12}>
                                <Form.Group controlId="country">
                                    <Form.Label>{intl.formatMessage({ id: 'COUNTRY' })}</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        isInvalid={!!errors.country}
                                    /> */}
                                    <Form.Select 
                                         type="text"
                                         name="country"
                                         value={values.country}
                                         onChange={handleChange}
                                         isInvalid={!!errors.country}
                                    aria-label="Default select example">
                                        
                                        {
                                            update?<option value={values.country}>{values.country}</option>:
                                            <option></option>
                                        }
                                        {
                                            countries.map((el,key)=>{
                                                return <option value={el.name}>{el.name}</option>
                                            })
                                        }

                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.country}
                                    </Form.Control.Feedback>
                                    
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12}>
                                <Form.Group controlId="state">
                                    <Form.Label>{intl.formatMessage({ id: 'STATE' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        isInvalid={!!errors.state}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.state}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12}>
                                <Form.Group controlId="city">
                                    <Form.Label>{intl.formatMessage({ id: 'CITY' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12}>
                                <Form.Group controlId="zip_code">
                                    <Form.Label>{intl.formatMessage({ id: 'ZIP_CODE' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zip_code"
                                        value={values.zip_code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.zip_code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.zip_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="address_line1">
                                    <Form.Label>{intl.formatMessage({ id: 'ADDRESS_LINE' })} 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address_line1"
                                        value={values.address_line1}
                                        onChange={handleChange}
                                        isInvalid={!!errors.address_line1}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address_line1}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12}>
                                <Form.Group controlId="address_line2">
                                    <Form.Label>{intl.formatMessage({ id: 'ADDRESS_LINE' })} 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address_line2"
                                        value={values.address_line2}
                                        onChange={handleChange}
                                        isInvalid={!!errors.address_line2}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address_line2}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="company">
                                    <Form.Label>{intl.formatMessage({ id: 'COMPANY' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company"
                                        value={values.company}
                                        onChange={handleChange}
                                        isInvalid={!!errors.company}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.company}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="email">
                                    <Form.Label>{intl.formatMessage({ id: 'EMAIL' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="phone">
                                    <Form.Label>{intl.formatMessage({ id: 'PHONE' })}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                       <Row className="mt-3">
                            <Col lg={12} sm={12}>
                            <Form.Group controlId="phone">
                                <Form.Label>{intl.formatMessage({ id: 'LOCATION' })}</Form.Label>
                                <GoogleMapStore
                                    storeLocation={storeLocation}
                                    setStoreLocation={setStoreLocation}
                                >
                                   
                                    
                                </GoogleMapStore>
                            </Form.Group>
                           </Col>
                       </Row>
                        <Row>
                            <Col className="d-flex justify-content-end mt-3">
                                <Button type="submit" disabled={loding}>
                                    {loding ? spanLoding:""}
                                    {
                                        update?intl.formatMessage({ id: 'SAVE' }):intl.formatMessage({ id: 'SUBMIT' })
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    )}

            </Formik>
        </>
    );
}

export default AddStore;