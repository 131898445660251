import req from "../Config/http-common";
import ApiConfig from "../Config/ApiConfig";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))
const  getStat =  (ob) => {

    return req.http3.post("/home/",{
        info:true,
        session:getSession()
    });
};
const  updateNotMess =  (ob) => {

    return req.http3.post("/home/",{
        update_not_mess:true,
        data:ob,
        session:getSession()
    });
};
const  getNotMess =  (ob) => {

    return req.http3.post("/home/",{
        get_not_mess:true,
        session:getSession()
    });
};


const StatService = {
    getStat,
    updateNotMess,
    getNotMess

};
export default StatService;