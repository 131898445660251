import React from 'react';
import styled from "styled-components"
import {FiSettings} from "react-icons/fi"
import {Link} from "react-router-dom"
const DivC=styled.div`
height: 150px;
border-radius: 4px;
overflow:hidden;
cursor: pointer;
:hover .icon svg{
    animation:rt .5s
}
:hover .off{
    display:flex
}
:hover .on{
    transform:scale(0);
    opacity:0;
}
@keyframes rt{
    from{
        transform: rotate(7deg);
    }
    35%{
        transform: rotate(-5deg);
    }
    60%{
        transform: rotate(2deg);
    }
    100%{
        transform: rotate(0deg);
    }
}
@keyframes tr2{
    from{
        transform: translateY(30px);
        opacity: 0;
    }
    to{
        transform: translateY(-100%);
        opacity: 1;
    }
}
p,h1 ,small,.tw{
    color: #FFF;
}
.on{
    transition: all .3s;

}
.off{
    display: none;
    animation: tr2 .3s;
    transform: translateY(-100%);
}
p{
    font-size: 20px;
    font-weight: 800;
}
.icon{
    background-color: #FFF;
    border-radius:50%;
    box-shadow: 0px 0px 0px 2px #FFF inset;
}
.icon svg{
    font-size: 34px;
}
`
function Box({icon,name,number,lastNumber,link,bgcolor}) {
    return (
        <Link to={link}>
            <DivC style={{background:bgcolor}} className="p-3 d-flex flex-column">
                
                <div className="d-flex align-items-center">
                    <div className="icon me-2 p-2">
                        {icon} 
                    </div>
                    
                    <p className='m-0'>{name}</p>
                    <div className='flex-grow-1'></div>
                    
                </div>
                <div className="flex-grow-1 on">
                    <h1 className="mb-0 text-center ">{number} </h1>
                    <div className="mb-0 text-center ">
                        <small>{lastNumber} this week</small>
                    </div>
                </div>
                
                
                <h1 className="off flex-grow-1 tw  justify-content-center align-items-center"> <FiSettings/></h1>

            </DivC>
        </Link>
    );
}

export default Box;