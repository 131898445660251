import React ,{useState}from 'react'
import { GoogleMap,Marker,MarkerWithLabel,InfoWindow  } from '@react-google-maps/api';
import image from "../../assets/images/"
import styled from "styled-components"

const Info=styled.div`
ul{
    list-style-type: none;
    margin:0;
    padding:0;
}
`
function GoogleMapHome(props) {

    const [x,setX]=useState(props.markers)
    const [showWindow,setShowWindow]=useState(null)
    const [markers,setMarkers]=useState([])

    setTimeout(()=>{
        setMarkers(x)
    },200)
  
  
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center={ lat: 36.74276055469744, lng: 3.0644041165314775 }
    return <GoogleMap
          
            mapContainerStyle={containerStyle}
            defaultCenter={center}
            center={center}
            zoom={props.storeLocation?8:3}
        >
          {
            markers.map((el,index)=>{
                return<Marker
                    key={index}
                    
                    position={
                        {
                            lat:parseFloat(el.location.split(",")[0]),
                            lng:parseFloat(el.location.split(",")[1])
                        }
                    }
                    onClick={
                        (e)=>{
                            console.log(e)
                            setShowWindow(el);
                        }
                    }
                >
                   
                </Marker>

            })
            
          }
           {
                        showWindow&&(
                            <InfoWindow
                            onCloseClick={(e)=>{
                                setShowWindow(null)
                            }}
                            
                            position={ {
                                lat:parseFloat(showWindow.location.split(",")[0]),
                                lng:parseFloat(showWindow.location.split(",")[1])
                            }}
                            >
                            <Info >
                                <h5>
                                    {showWindow.name}
                                </h5>
                                <ul>
                                    <li>{showWindow.city}</li>
                                    <li>{showWindow.address_line1}</li>
                                    <li>{showWindow.phone}</li>
                                    {props.user.rol==2&&<li  className='d-flex justify-content-end' >
                                        <button onClick={()=>props.navigate("/store/edit",{state:showWindow})} className="btn btn-success pt-0 pb-0">
                                            edit
                                        </button>
                                    </li>}
                                </ul>
                                
                            </Info>
                        </InfoWindow>
                        )
                    }
          
        </GoogleMap>;
  }

export default GoogleMapHome