
import { Container } from 'react-bootstrap';
import { useLocation} from 'react-router-dom';
import VisaTrac from "./VisaTrac"
import VisaRequest from "./VisaRequest"
import VisaListRequest from "./VisaListRequest"
import HowTo from './HowTo';
function Visa(props) {
    const location=useLocation();

    const getView=(v)=>{
        switch(v){
            case "/visa":return <VisaListRequest/>
            case "/visa/request" : return <VisaRequest/>;
            case "/visa/trac" : return <VisaListRequest />;
            case "/visa/how_to" : return <HowTo />;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Visa;