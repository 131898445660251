import req from "../Config/http-common";
import ApiConfig from "../Config/ApiConfig";

const getSession=()=>JSON.parse(window.localStorage.getItem("user",0))

const  loginUser =  (email,password,type,data) => {
  
    return req.http3.post("/auth/",{
        type,
        data,
        email,
        password,
        login:"true"
    });
};
const  registerUser =  (ob) => {
    return req.http3.post("/auth/",{
        ...ob,
        register:"true"
    });
};
const  logoutUser =  (user) => {
    return req.http3.post("/auth/",{
        ...user,logout:"true"
    });
};
const  updateUser =  (user) => {
    return req.http3.post("/users/",{
        ...user,
        update_user:"true"
    });
};
const  updateUserPassword =  (user) => {
    return req.http3.post("/users/",{
        ...user,
        update_user_password:"true"
    });
};
const  validLogin =  (user) => {
  
    return req.http3.post("/auth/",{
        ...user,
        valid_login:true
    });
};
const  getAll =  (user) => {
  
    return req.http3.post("/users/",{
        session:getSession(),
        get_all:true
    });
};
const changeRolUser=(id_user,rol)=>{
   
    return req.http3.post("/users/",{
        id_user,
        rol,
        session:getSession(),
        change_rol:true
    });
}
const AddStoreToUser=(id_user,id_store,add)=>{
   
    return req.http3.post("/users/",{
        id_user,
        id_store,
        add,
        session:getSession(),
        add_store_to_user:add
    });
}

const getNotifications=(update_state,offset)=>{
   
    return req.http3.post("/users/",{
        session:getSession(),
        update_state,
        offset,
        notification:true
    });
}
const deleteNotifications=(ids)=>{
   
    return req.http3.post("/users/",{
        session:getSession(),
        ids,
        delete_notifications:true
    });
}
const  addDeliveryAddress =  (ob) => {
    console.log(ob)
    return req.http3.post("/users/",{
        ...ob,
        add_delivery_address:"true"
    });
};

const  updateDeliveryAddress =  (ob) => {
  
    return req.http3.post("/users/",{
        ...ob,
        update_delivery_address:"true"
    });
};
const  deleteDeliveryAddress =  (id) => {
  
    return req.http3.post("/users/",{
        session:getSession(),
        id,
        delete_delivery_address:"true"
    });
};
const  getAllDeliveryAddress =  (user) => {
  
    return req.http3.post("/users/",{
        ...user,
        get_all_delivery_address:true
    });
};



const UserService = {
    addDeliveryAddress,
    updateDeliveryAddress,
    deleteDeliveryAddress,
    getAllDeliveryAddress,
    registerUser,
    logoutUser,
    loginUser,
    validLogin,
    getAll,
    updateUser,
    updateUserPassword,
    changeRolUser,
    getNotifications,
    AddStoreToUser,
    deleteNotifications
};
export default UserService;