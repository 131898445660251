
import { Modal,Button } from "react-bootstrap";
import { useIntl } from 'react-intl';


function ExpedModal(props) {
  const intl = useIntl();

    return (
      <Modal
        size="md"
        {...props}
       
        aria-labelledby="contained-modal-title-vcenter"
        centered
        
      >
        <Modal.Header style={{background:"#111",color:"#CCC"}}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
        {
          props.showbtn&&
            <Modal.Footer>
            <button onClick={props.onHide} className="btn btn-danger">{intl.formatMessage({ id: 'CLOSE' })}</button>
          </Modal.Footer>
         
        }
      </Modal>
    );
  }
export default  ExpedModal