
import { Container } from 'react-bootstrap';
import { useLocation} from 'react-router-dom';

import Notes from '../Views/Notes';
import Calculator from './Calculator';
function Colis(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/calculator" : return <Calculator/>;
            case "/calculator/notes" : return <Notes/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Colis;