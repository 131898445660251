import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Container, Button } from 'react-bootstrap';
import ApiConfig from '../Config/ApiConfig';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import StoreService from "../Service/StoreService"
import { Toast, ToastStyle } from "../Views/Toast"
import ShowImages from './ShowImages';
import { useIntl } from 'react-intl';
import styled from "styled-components"
import { MdOutlineDelete } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import Select from 'react-select';
import PreviewImages from "../Colis/PreviewImages"
import PaimentService from '../Service/PaimentService';
import Loader from "../Views/Loader"

const DivC = styled.div`

.th th{
    /* font-weight: initial;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap; */
}
.table td{
    border-color:#FFF;
}
.table tr{
    border-color:#FFF;
}
.imgh img{
    max-height:200px
}
`




function Paiment({ update }) {
    const location = useLocation();
    const datar = location.state;
    const colis = datar.colis;
    const [addsuccess, setAddsuccess] = useState(false);
    const intl = useIntl();
    const [parameter, setParameter] = useState([]);
    const [price, setPrice] = useState(null);
    const [naturEnvoi, setNaturEnvoi] = useState(null);
    const [data, setData] = useState(null);
    const [country, setCountry] = useState(null);
    const [tc, setTc] = useState(null);
    const [ggg, setggg] = useState(0);
    const [transport, setTransport] = useState(null);
    const [insurance, setInsurance] = useState(null);
    const [askInsurance, setAskInsurance] = useState(null);
    const [paiment_mod, setPaiment_mod] = useState(null);
    const [paimentData, setPaimenData] = useState(null);
    const [article, setArticle] = useState([{ desc: "", weight: "", quantity: "", price: "" }])
    const [image, setImage] = useState(null)

    const getData = () => {
        StoreService.paimentInfo(colis.id_colis)
            .then(res => {

                setData(res.data);
                setTc(res.data.transport.map(el => {
                    return { id: el.id_tc, logo: el.logo, insurance: JSON.parse(el.insurance) }
                }))
                setPaimenData(res.data.banks)
                setParameter(res.data.parameter);
            }).catch(err => {
                setData([])
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData();
    }, []);
    const getPrice = () => {
        if (!data || !country) return null
        let ob = data.store;
        let pd = JSON.parse(ob.price_data)
        let transport = pd.find(el => el.name == country.value.country).transport;
        let u = (colis.length * colis.height * colis.width) / 5000.0;
        let r = (colis.weight > u) ? colis.weight : u;
        let values = [];
        let k = transport.map(item => {
            item.calc.map(el => {
                if (r > parseFloat(el.from) && r <= parseFloat(el.to))
                    values.push(
                        {
                            id: item.id,
                            from: item.duration_from,
                            to: item.duration_to,
                            price: el.price,
                            poid: r
                        }
                    )
            })
        })
        return values;
    }
    const updateTransport = (el) => {
        setTransport(el)
    }

    const addArticle = (index) => {

        let tab = [...article];
        tab.splice(index + 1, 0, { desc: "", weight: "", quantity: "", price: "" })

        setArticle(tab)
    }
    const delateArticle = (index) => {
        if (article.length > 1)
            setArticle(
                article.filter((el, key) => {
                    return (key != index);
                }))
    }
    const updateArticle = (val, index, item) => {
        setArticle(article.map((el, key) => {
            switch (item) {
                case "desc": return key == index ? { ...el, desc: val } : el;
                case "weight": return key == index ? { ...el, weight: val } : el;
                case "quantity": return key == index ? { ...el, quantity: val } : el;
                case "price": return key == index ? { ...el, price: val } : el;
            }
        }))
    }

    const handalSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("id_colis", colis.id_colis);
        data.append("id_address", country.value.id_au);
        data.append("id_tc", transport.id);
        data.append("id_bank", paiment_mod.id_bank);
        let dd = {
            nature_envoi: naturEnvoi.value,
            articles: article
        }
        data.append("duan_data", JSON.stringify(dd));
        data.append("insurance",insurance? JSON.stringify(insurance.value):"{}");
        data.append("image", image);
        if (!update) {
            data.append("add_paiment", true);
            addPaiment(data)
        }

    }
    const addPaiment = (ob) => {
        setLoding(true)
        PaimentService.addPaiment(ob)
            .then(res => {
                if (res.data.error === "") {
                    Toast.success("Add success", ToastStyle);
                    setAddsuccess(true)
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    const [loding, setLoding] = useState(false);
    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const disableButton = () => {
        let v = true;
        article.map((el) => {
            if (el.desc == "" || el.weight == "" || el.quantity == "" || el.price == "")
                v = false;
        })
        let d = country != null && transport != null && naturEnvoi != null && v && image != null && paiment_mod != null;
        return d;
    }
    const getDinar = (v) => {
        let x = v % 1000;
        let y = Math.ceil(x / 100) * 100 + Math.floor(v / 1000) * 1000;
        return y;
    }
    return (data != null ?
        <>
            <Row className="p-2">
                <Col className="d-flex justify-content-end">
                    <Link to="/colis" className="btn btn-default">
                        {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
            </Row>
            {
                addsuccess && <>

                    <div class="alert alert-success p-3">
                        <strong>Ajouté avec succès!</strong>Votre paiement est en cours de validation.
                    </div>
                </>
            }
            {
                !addsuccess &&
                <Form onSubmit={handalSubmit}>
                    <DivC className="row p-2">
                        <Col>
                            <h5>Informations sur le colis :</h5>
                        </Col>
                        <Col sm={12} className="table-responsive">
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <th><p className='p-0'>{intl.formatMessage({ id: 'ID' })}</p></th>
                                        <th ><p className='p-0'>{intl.formatMessage({ id: 'ID_EXPED' })}</p></th>
                                        <th><p className='p-0'>{intl.formatMessage({ id: 'WEIGHT' })}  ({intl.formatMessage({ id: 'KG' })})</p></th>
                                        <th><p className='p-0'>{intl.formatMessage({ id: 'LENGTH' })}  ({intl.formatMessage({ id: 'CM' })})</p></th>
                                        <th><p className='p-0'>{intl.formatMessage({ id: 'WIDTH' })}  ({intl.formatMessage({ id: 'CM' })})</p></th>
                                        <th><p className='p-0'>{intl.formatMessage({ id: 'HEIGHT' })}  ({intl.formatMessage({ id: 'CM' })})</p></th>
                                        <th><p className='p-0'>{intl.formatMessage({ id: 'VOL_WEIGHT' })} ({intl.formatMessage({ id: 'KG' })})</p></th>
                                    </tr>
                                    <tr>
                                        <td>{colis.id_colis}</td>
                                        <td>{colis.id_exped}</td>
                                        <td>{colis.weight}</td>
                                        <td>{colis.length}</td>
                                        <td>{colis.width}</td>
                                        <td>{colis.height}</td>
                                        <td>{colis.length * colis.height * colis.width / 5000}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="to">
                                <Form.Label>Address de livraison</Form.Label>
                                <Select
                                    value={country}
                                    defaultValue={country}
                                    onChange={setCountry}
                                    isSearchable={true}
                                    options={!data ? [] : data.address.map((el, key) => {
                                        return {
                                            label: el.name + " , " + el.country + " , " + el.state + " , " + el.city + " , " + el.zip_code + " , " + el.address_line1,
                                            value: el
                                        }
                                    })}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mode de livraison</Form.Label>
                                {getPrice() &&
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th><p className='p-0'>Délai de livraison</p></th>
                                                    <th><p className='p-0'>Poids</p></th>
                                                    <th><p className='p-0'>Prix total</p></th>
                                                    <th>Prix en dinar</th>
                                                </tr>
                                                {
                                                    getPrice().map(el => {
                                                        let logo = tc.find(item => item.id == el.id).logo
                                                        return <tr>
                                                            <td>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="select"
                                                                    onChange={() => updateTransport(el)}
                                                                />
                                                            </td>
                                                            <td><img src={ApiConfig.urlTransportCompani + logo} height='25' alt="" /></td>
                                                            <td>{el.from} à {el.to} jours</td>
                                                            <td>{el.poid} Kg</td>
                                                            <td>{el.price} EUR</td>
                                                            <td>{getDinar(el.price * parameter.eur_to_dinar)} DZD</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>}
                            </Form.Group>
                        </Col>
                        {
                            transport &&
                            <>
                                <Col sm={12}>
                                    <h5>Déclaration Douaniére :</h5>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Nature de l’envoi</Form.Label>
                                        <Select
                                            value={naturEnvoi}
                                            defaultValue={naturEnvoi}
                                            onChange={setNaturEnvoi}
                                            isSearchable={true}
                                            options={parameter.nature_envoi.map(el => { return { label: el, value: el } })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <h5>Contenu :</h5>
                                    <div className='table-responsive'>
                                        <table className="table p-1 table-sm ss">
                                            <tr>
                                                <td><p className='p-0'>Description</p></td>
                                                <td><p className='p-0'>Poids unitaire en Kg </p></td>
                                                <td><p className='p-0'>Quantité </p></td>
                                                <td><p className='p-0'>Valeur unitaire HT en EUR </p></td>
                                                <td></td>
                                            </tr>
                                            {
                                                article.map((el, key) => {
                                                    return <tr className='p-2'>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                value={el.desc}
                                                                onChange={(e) => updateArticle(e.target.value, key, "desc")}
                                                                placeholder="exp : jean, chemise"
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                value={el.weight}
                                                                onChange={(e) => updateArticle(e.target.value, key, "weight")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                value={el.quantity}
                                                                onChange={(e) => updateArticle(e.target.value, key, "quantity")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                value={el.price}
                                                                onChange={(e) => updateArticle(e.target.value, key, "price")}
                                                            />
                                                        </td>
                                                        <td className='d-flex align-items-center'>

                                                            {article.length>1&&<span className={`btn btn-default btn-sm ms-1 border ${article.length==1&&"disabled"}`}  
                                                            onClick={() => delateArticle(key)}><MdOutlineDelete /></span>}
                                                            <span className="btn btn-default btn-sm ms-1 border" onClick={() => addArticle(key)}><FiPlus /></span>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </table>
                                    </div>
                                </Col>
                                <Col sm={12} className="mt-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Assurance</Form.Label>
                                        <p>Voulez-vous souscrire à une assurance pour votre envoi ?</p>
                                        <Form.Check
                                            type="radio"
                                            name="ins"
                                            label="Oui"
                                            checked={askInsurance != null && askInsurance}
                                            onChange={() => setAskInsurance(true)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="ins"
                                            label="Non"
                                            checked={askInsurance != null && !askInsurance}
                                            onChange={() => { setAskInsurance(false); setInsurance(null) }}
                                        />

                                        {askInsurance && <Select
                                            value={insurance}
                                            defaultValue={insurance}
                                            onChange={setInsurance}
                                            isSearchable={true}
                                            options={
                                                tc.find(el => el.id == transport.id).insurance.map(el => {
                                                    return {
                                                        label: el.price + " EUR",
                                                        value: el
                                                    }
                                                })
                                            }
                                        />}

                                    </Form.Group>
                                    {
                                        askInsurance && insurance && <p>
                                            Nouveau prix après application de l’assurance :
                                            <strong>
                                                {parseFloat(parseFloat(transport.price) + parseFloat(insurance.value.value)).toFixed(2)} EUR /
                                                {getDinar((parseFloat(parseFloat(transport.price) + parseFloat(insurance.value.value)).toFixed(2)) * parameter.eur_to_dinar)} DZD
                                            </strong>

                                        </p>
                                    }
                                </Col>
                                <Col sm={12}>
                                    <h5>Mode de paiement</h5>
                                    <div className="d-flex">
                                        {
                                            paimentData && paimentData.map((el, key) => {
                                                return <div className="d-flex align-items-center p-2 border me-2">
                                                    <Form.Check
                                                        type="radio"
                                                        name="pai"
                                                        className="me-2"
                                                        checked={paiment_mod ? el.id_bank == paiment_mod.id_bank : false}
                                                        onChange={() => { setPaiment_mod(el); setImage(null) }}
                                                    />
                                                    <img src={ApiConfig.urlBankImages + el.logo} alt="" height="30" />
                                                </div>
                                            })
                                        }
                                    </div>

                                    <div className="mt-2">
                                        {paiment_mod &&
                                            <div class="alert alert-success p-2">
                                                {
                                                    JSON.parse(paiment_mod.shipping_data).map((el, key) => {
                                                        return <p className="d-flex m-0 p-0">
                                                            <strong>{el.item} : </strong>  {el.value}
                                                        </p>
                                                    })
                                                }
                                            </div>
                                        }
                                        <Form.Group controlId="logo" className="mt-2">
                                            <Form.Label>Image de la preuve de paiement</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="logo"
                                                accept="image/*"
                                                disabled={paiment_mod==null}
                                                onChange={(e) => { setImage(e.target.files[0]); setTimeout(() => { setggg(ggg + 1) }, 10) }}

                                            />
                                            {
                                                image ? <div className="imgh mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                                    <PreviewImages file={image} />
                                                </div> : (
                                                    update &&
                                                    <div className="imgh mt-1 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                                        <img src={ApiConfig.urlPaiment + datar.image_confirm} />
                                                    </div>
                                                )
                                            }
                                        </Form.Group>
                                    </div>
                                </Col>

                            </>
                        }
                        <Col sm={12} className="mt-3 d-flex justify-content-end">
                            <button className="btn btn-primary" disabled={disableButton() == false || loding}>
                                {loding ? spanLoding : ""}
                                {intl.formatMessage({ id: 'SUBMIT' })}
                            </button>
                        </Col>
                    </DivC>
                </Form>
            }

        </> : <Loader />
    );
}

export default Paiment;