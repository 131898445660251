import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { Link, useNavigate, useLocation, } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import images from "../../assets/images"
import { FaTachometerAlt, FaGem, FaRegLaughWink, FaHeart } from 'react-icons/fa';
import { DispatchContext, logout, StateContext } from "../Context/"


const SideBar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  const intl = useIntl();
  const exped = (
    <h4 className="m-0 ms-3 fw-bold" >
      Exped
    </h4>
  )
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useContext(DispatchContext);

  const logoutUser = () => {
    logout(dispatch);
    navigate("/")
  }

  const { user,globalStates } = useContext(StateContext);
  const getSidbarByRol = (rol) => {
    switch (rol) {
      case 2: return "superAdmin";
      case 1: return "admin";
      default: return "user";
    }
  }
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"

      onToggle={handleToggleSidebar}
      rtl={rtl == "rtl"}
      width={300}
    >
      <SidebarHeader className='d-flex align-items-center justify-content-center ps-3 pt-1'>
        <img src={images.logoleft} alt="" height="40" className='mt-1' />

        {!collapsed && <img src={images.logorightw} className='mt-1' alt="" height="54" />}
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle" className="p-3 mt-4">
          {
            SidebarData()[getSidbarByRol(user.rol)].map(
              (item, index) => {

                return (
                  item.type == "devider" ? <hr className="mt-1" /> :
                    item.type == "title" ? <li class="pro-menu-item ps-3">
                      <div tabindex="0" role="button" className="pro-inner-item "
                        style={{ display: "flex !important", alignItems: "center !important" }}>
                        <span className="pro-item-content" style={{flexGrow:"initial", textTransform: "uppercase", opacity: "1.1", color: "#0eb334CC" }}>
                          {item.title}</span>
                        <hr className="flex-grow-1" />
                      </div>
                    </li> :
                      item.subItem.length == 0 ? (

                        <MenuItem
                          key={index}
                          icon={item.icon}
                          active={(location.pathname) == (item.path) ? true : false}
                        >
                          <Link to={item.path} onClick={() => handleToggleSidebar(false)}>
                            {item.title}
                          </Link>
                        </MenuItem>

                      ) : (
                        <SubMenu
                          key={index}
                          title={item.title}
                          icon={item.icon}

                          className={("/" + location.pathname.split("/")[1]) == ("/" + item.path.split("/")[1]) ? "sub-active" : ""}
                        >
                          {
                            item.subItem.map((el, key) => {
                              return (
                                el.type == "devider" ? <hr className="mt-1 sub-devider" /> :
                                  <MenuItem
                                    icon={el.icon}
                                    key={key}
                                    {...el.suffix?{ suffix:el.suffix}:{}}
                                    className={(location.pathname == el.path) ? "sub-menu-active" : ""}
                                  >
                                    <Link to={el.path} >
                                      {el.title}
                                    </Link>

                                  </MenuItem>
                              )
                            })
                          }
                        </SubMenu>
                      )

                );
              }
            )
          }
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center', padding: "15px" }}>
        <a href="https://www.devgate.net" target="_blank">
          <img src={!collapsed ? images.devgate : images.devgate_logo} style={{ filter: "invert(100%)" }} alt="" height="18" />

        </a>
      </SidebarFooter>

    </ProSidebar>
  );
};

export default SideBar;