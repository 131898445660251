


import { useState, useContext, useEffect } from 'react';
import { Form, Button, Container, Col, Row } from 'react-bootstrap'
import images from "../../assets/images"
import { Link } from 'react-router-dom';
import { Toast, ToastStyle } from "../Views/Toast"
import UserService from '../Service/UserService';

import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';

import {
    StateContext,
    loginUser,
    DispatchContext,
} from "../Context/";
import { useNavigate } from "react-router-dom"

import "../../assets/style/bootstrap-extended.css"
import "../../assets/style/inline.css"
import Password from "../Views/Password"
import { useIntl } from 'react-intl';

const userShema = Yup.object().shape({

    fname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
        .min(6, 'Too Short!')
        .max(40, 'Too Long!')
        .required('Required'),
    cpassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password must match')
        .required('required'),
});

function Register() {
    const navigate = useNavigate();
    const dispatch = useContext(DispatchContext);
    const [loding, setLoding] = useState(false);

    const intl = useIntl();

    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )


    const Register = (ob) => {


        setLoding(true)
        UserService.registerUser(ob)
            .then(res => {
                if (res.data.error == "") {
                    loginUser(dispatch, res.data.user);
                    navigate("/dashboard")
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }
                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    return (
        <>

            <Container className="login_register m-0" style={{minWidth: "100%!important",maxWidth: "100%!important",width: "100%"}}>
                <div className="logo p-4 ">
                    <Link to="/" className="d-flex align-items-center">
                        <img src={images.logonoir} alt="" height="40" />

                    </Link>
                </div>
                <Row style={{ minHeight: "100%" }}>
                    <Col className="left d-flex align-items-center">

                        <img src={images.register} alt="" width="100%" />
                    </Col>
                    <Col className="for_form d-flex align-items-center justify-content-center">


                        <Formik
                            initialValues={{
                                fname: '',
                                lname: '',
                                email: '',
                                password: '',
                                cpassword: '',
                            }}

                            validationSchema={userShema}
                            onSubmit={(values, actions) => {
                                Register(values);
                            }}
                        >
                            {
                                (
                                    {
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors
                                    }
                                ) => (

                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Container>
                                            <Row>
                                                <h2 className="title">{intl.formatMessage({ id: 'CREATE_YOUR_ACC' })} hh</h2>
                                                <Col sm={12}>
                                                    <Form.Group controlId="fname">
                                                        <Form.Label>{intl.formatMessage({ id: 'FNAME' })}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="fname"
                                                            value={values.fname}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.fname}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.fname}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group controlId="lname">
                                                        <Form.Label>{intl.formatMessage({ id: 'LNAME' })}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="lname"
                                                            value={values.lname}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.lname}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.lname}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group controlId="email">
                                                        <Form.Label>{intl.formatMessage({ id: 'EMAIL' })}</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.email}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group controlId="password">
                                                        <Form.Label>{intl.formatMessage({ id: 'PASSWORD' })}</Form.Label>
                                                        <Password
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password}
                                                        >
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password}
                                                            </Form.Control.Feedback>
                                                        </Password>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group controlId="cpassword">
                                                        <Form.Label>{intl.formatMessage({ id: 'CPASSWORD' })}</Form.Label>
                                                        <Password
                                                            name="cpassword"
                                                            value={values.cpassword}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.cpassword}
                                                        >
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.cpassword}
                                                            </Form.Control.Feedback>
                                                        </Password>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group className="mb-2 d-flex" controlId="check">
                                                        <Form.Check type="checkbox" label="J'accepte la " />

                                                        <Link to="#" className="ms-1">
                                                            {intl.formatMessage({ id: 'PRIVACY_POL' })}
                                                        </Link>
                                                    </Form.Group>
                                                </Col>



                                                <Col sm={12} className="mb-2">
                                                    <Button variant="primary" type="submit" className='mt-2 btn-block' disabled={loding}>
                                                        {loding ? spanLoding : ""}
                                                        {intl.formatMessage({ id: 'REGISTER' })}
                                                    </Button>
                                                </Col>



                                                <Col sm={12} className="text-center">
                                                    {intl.formatMessage({ id: 'ALREADY_REG' })}
                                                    <Link to="/login">
                                                        {intl.formatMessage({ id: 'TO_LOGIN' })}
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>

                                )}
                        </Formik>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Register;