import {useState,useContext,useEffect} from 'react';
import {StateContext} from "../Context/";
import StoreService from '../Service/StoreService';
import Modal from "../Views/Modal"
import styled from "styled-components"
import { useIntl } from 'react-intl';
const DivC=styled.div`

th{
    background-color: inherit!important;;
    font-weight: 500;
}
td{
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.bg-rr{
    /* background-color: #ff3f39; */
}
`
const ModalBody=styled.div`
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
p{
    font-size: 16px;
}
`

function ExpedAddress(props) {
    const [data,setData]=useState(null);
    const [showModal, setShowModal] = useState(false);
    const {user } = useContext(StateContext);
    const [entrepot, setEntrepot] = useState(null);
    const getData=()=>{
        StoreService.getAll(user)
        .then(res=>{
            setData(res.data.data);
        }).catch(err=>{
            setData([])
        })
    }
    useEffect(()=>{
        getData();
    },[])
    const intl = useIntl();

    return (
        <DivC className='table-responsive-lg'>

            <table className='table table-borderless'>
                
                <tbody>


                    {
                        data&&data.map((el,key)=>{
                            return <tr>
                                <td>{el.name}</td>
                                <td>{el.country}</td>
                                <td className='d-flex justify-content-end'>
                                    <button className='btn btn-primary  btn-sm' onClick={
                                        ()=>{
                                            setEntrepot(el)
                                            setShowModal(true)
                                        }}> {intl.formatMessage({ id: 'SHOW' })}</button>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            
            {data&&<Modal
                show={showModal}
                showbtn={true}
                title={intl.formatMessage({ id: 'YOUR_ADDRESS_EXPED' })}
                onHide={() => setShowModal(false)}
            >
                <ModalBody>
                {entrepot&&<ul className="ps-2 pe-2 mb-2">
                    <li> <strong>{intl.formatMessage({ id: 'NAME' })} :</strong> {user.fname} {user.lname} <strong>{user.id_exped}</strong></li>
                    <li><strong>{intl.formatMessage({ id: 'ADDRESS_LINE' })} 1 :</strong> {entrepot.address_line1} </li>
                    <li><strong>{intl.formatMessage({ id: 'ADDRESS_LINE' })} 2 :</strong> {entrepot.address_line2} <strong>{user.id_exped}</strong></li>
                    <li><strong>{intl.formatMessage({ id: 'ZIP_CODE' })} : </strong> {entrepot.zip_code} </li>
                    <li><strong>{intl.formatMessage({ id: 'CITY' })} : </strong> {entrepot.city} </li>
                    <li><strong>{intl.formatMessage({ id: 'COMPANY' })} :</strong> {entrepot.state} </li>
                    <li><strong>{intl.formatMessage({ id: 'COUNTRY' })}  :</strong> {entrepot.country} </li>
                    <li><strong>{intl.formatMessage({ id: 'PHONE' })} :</strong> {entrepot.phone} </li>
                    <li><strong>{intl.formatMessage({ id: 'EMAIL' })} :</strong> {entrepot.email} </li> 
                </ul>}
                
                <p>
                {intl.formatMessage({ id: 'MESSAGE_ALL_ADD' })} : <strong>{user.id_exped}</strong> ,  
                {intl.formatMessage({ id: 'MESSAGE_ALL_ADD2' })}
                </p>
                </ModalBody>
            </Modal>}
        </DivC>
    );
}

export default ExpedAddress;