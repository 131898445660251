
import React from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { Container, Row, Col, Form } from "react-bootstrap"

import images from '../../assets/images';
import ScrollAnimation from 'react-animate-on-scroll';
const DivC = styled.div`
padding-top:80px;
padding-bottom:80px;
overflow: hidden;
.img{
    height:170px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.img img{
    max-width: 110px;
    width:100%
}
h2{
    font-weight: 900;
    color:#111
}
h1{
    font-size:20px;
    font-weight: 700;
}
p{
    font-size: 16px;
    text-align: center;
    line-height:16px;
    color:#b8b8b8;
    font-weight: 600;
}
.c-green{
    color:var(--main-color)
}

`

function CommentF() {
    return (

        <DivC id="commentmarche">
            <Container>
                <Row>
                    <Col>

                        <h2 className="text-center">
                            Comment fonctionne la <span className='c-green'>réexpédition ?</span>
                        </h2>
                        <p className="text-center">Nous avons sélectionné pour vous les transporteurs les plus rapides, vous bénéficiez des meilleurs tarifs.</p>

                    </Col>
                </Row>
                <Row className="mt-3">
                    <ScrollAnimation initiallyVisible={true} animateIn="bounce" className="col-lg-3 col-md-6 col-sm-12 p-2 d-flex flex-column align-items-center">
                        <div className="img">
                            <img src={images.func1} alt="" />
                        </div>
                        <h1>01. Inscription</h1>
                        <p>Je m’inscris et obtiens mon adresse en France </p>
                    </ScrollAnimation>

                    <ScrollAnimation initiallyVisible={true} delay={50} animateIn="bounce" className="col-lg-3 col-md-6 col-sm-12 p-2 d-flex flex-column align-items-center">

                        <div className="img">
                            <img src={images.func2} alt="" />
                        </div>
                        <h1>02. Achats en ligne</h1>
                        <p>Je commande en ligne et fais livrer à ma nouvelle adresse</p>
                    </ScrollAnimation>
                    <ScrollAnimation initiallyVisible={true} delay={100} animateIn="bounce" className="col-lg-3 col-md-6 col-sm-12 p-2 d-flex flex-column align-items-center">

                        <div className="img">
                            <img src={images.func3} alt="" />
                        </div>
                        <h1>03. Consolidation</h1>
                        <p>Je regroupe mes achats pour réduire les frais d’expédition</p>
                    </ScrollAnimation>
                    <ScrollAnimation initiallyVisible={true} delay={150} animateIn="bounce" className="col-lg-3 col-md-6 col-sm-12 p-2 d-flex flex-column align-items-center">

                        <div className="img">
                            <img src={images.func4} alt="" />
                        </div>
                        <h1>04. Expédition</h1>
                        <p>Vos colis sont réexpédiés dans le monde entier</p>
                    </ScrollAnimation>
                </Row>
            </Container>
        </DivC>
    )
}

export default CommentF