import React from 'react';
import styled from "styled-components"
import { FiSettings } from "react-icons/fi"
import { Link } from "react-router-dom"

const DivC = styled.div`


border-radius: 4px;
overflow:hidden;
position: relative;

.box{
    border-radius: 4px;
    position: relative;
    overflow:hidden;
    color:#FFF;
}
*:not(img){
    position:relative
}

`

function BankState({ title,image,bg,cd}) {
    console.log(cd)
    return (

        <DivC className='p-1 mt-1 '>
            <div className='d-flex justify-content-center box ' style={{background:bg}}>
                <h4 className="mt-2">{title} {cd[0].buy} DA</h4>
            </div>
        </DivC>

    );
}

export default BankState;