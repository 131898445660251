import { useState } from 'react';
import styled from "styled-components"
import ImageGallery from 'react-image-gallery';
import { MdClear } from "react-icons/md"
const DivC = styled.div`

.full{
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    height: 100vh;
    z-index:9999;
    background-color: #000A;
}
.full .imgE{

    max-width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.zoom{
    cursor: zoom-in;
}
.btnE{
    position: absolute;
    top:10px;
    right:10px;
    width: 50px;
    height: 50px;
    color: #fff;
    background-color: #ff3f39;
    font-size: 22px;
    cursor: pointer;
    z-index: 1000;
}
.btnE:hover{
    background-color: #e91e17;;
}
`

function ImageModal(props) {
    const [showModal, setShowModal] = useState(false)

    return (
        <DivC>



            <img src={props.src} height="25" alt="" onClick={()=>setShowModal(true)} className="zoom"/>

            {
                showModal && <div className="full d-flex align-items-center justify-content-end">
                    <MdClear className="btnE " onClick={()=>setShowModal(false)} />
                    <img src={props.src}  alt="" className="imgE"/>
                </div>
            }
        </DivC>
    );
}

export default ImageModal;