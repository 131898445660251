import React from 'react';
import TransportCompaniList from "./TransportCompaniList"
import AddTransportCompani from "./AddTransportCompani"
import {Container} from "react-bootstrap"
import { useLocation} from 'react-router-dom';

function TransportCompani(props) {
    const location=useLocation();
    const getView=(v)=>{
        switch(v){
            case "/tools" : return <TransportCompaniList/>;
            case "/tools/transport_companies/add" : return <AddTransportCompani update={false}/>;
            case "/tools/transport_companies/edit" : return <AddTransportCompani update={true}/>;
            default : return null;
        }
        return null;
    }

    return (
        <Container>
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default TransportCompani;