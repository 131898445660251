import React, { useState, useEffect } from 'react';

import { Col, Row, Form, Container, Button, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import { Toast, ToastStyle } from "../Views/Toast"
import { MdOutlineDelete } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import ShippingService from "../Service/ShippingService"
import { useIntl } from 'react-intl';
import AddShipping from '../Shipping/AddShipping';
import PreviewImages from "../Colis/PreviewImages"
import styled from "styled-components"
import ApiConfig from '../Config/ApiConfig';
import Select from 'react-select';
import StatService from '../Service/StatService';
import Loader from '../Views/Loader';
const Upload = styled.div`
img{
    max-height:200px
}

`



const storeShema = Yup.object().shape({

    
});

function ManageNotifications() {
    const [loding, setLoding] = useState(false);
    const location = useLocation()
    const intl = useIntl();
    const datar = location.state;
 
    const [notConfig,setNotConfig]=useState(null)
    //const [data,setData]=
    const getData = () => {
        StatService.getNotMess()
            .then(res => {
                setNotConfig(res.data.data);
                console.log(5555)
            }).catch(err => {
                setNotConfig(null)
                Toast.error(err.message, ToastStyle);
            })
    }
    useEffect(() => {
        getData()
    }, [])

    const spanLoding = (
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const colis_states = [
        intl.formatMessage({ id: 'COLIS_REC' }),
        intl.formatMessage({ id: 'COLIS_PREP' }),
        intl.formatMessage({ id: 'COLIS_ATT_PAIE' }),
        intl.formatMessage({ id: 'COLIS_ATT_VALID' }),
        intl.formatMessage({ id: 'COLIS_PAIE_ACC' }),
        intl.formatMessage({ id: 'COLIS_REJ' }),
        intl.formatMessage({ id: 'COLI_EN_ROUT' }),
        intl.formatMessage({ id: 'COLIS_ARR' }),
    ]
    const shipping_states = [
        intl.formatMessage({ id: 'ATT_PAIE' }),
        intl.formatMessage({ id: 'ATT_VALID' }),
        intl.formatMessage({ id: 'RECHARGE_DONE' }),
        intl.formatMessage({ id: 'PAIE_REJ' }),
    ]
    const visa_states = [
        "En attente de paiement",
        "Paiement validé",
        "Paiement accépté",
        "Paiement rejeté",
        "Cartes reçues à l'entrepôt",
        intl.formatMessage({ id: 'CARTE_ROUT' }),
        intl.formatMessage({ id: 'CARTE_ARR' }),
    ]
    

    const updateNot=(type,val,key)=>{
        setNotConfig(
            {
                ...notConfig,
                ...type=="colis"?{colis:notConfig.colis.map((el,index)=>{
                    if(index==key)return{...el,message:val}
                    else return el
                })
                }:type=="visa"?{
                    visa:notConfig.visa.map((el,index)=>{
                        if(index==key)return{...el,message:val}
                        else return el
                    })
                }:{
                    shipping:notConfig.shipping.map((el,index)=>{
                        if(index==key)return{...el,message:val}
                        else return el
                    })
                }
            }
        )
    }
   

    const updateParameter = (ob) => {
        setLoding(true)
        StatService.updateNotMess(ob)
            .then(res => {

                if (res.data.error === "") {
                    Toast.success("update success", ToastStyle);
                } else {
                    Toast.error(res.data.error, ToastStyle);
                }

                setLoding(false)
            }).catch(err => {
                setLoding(false)
                Toast.error(err.message, ToastStyle);
            })
    }
    return notConfig!=null?<Container className="p-0 m-0" style={{ width: "100%" }}>
            <Row className="">
                <Col className="d-flex justify-content-end">
                    <Link to="/tools" className="btn btn-default">
                        {intl.formatMessage({ id: 'BACK' })}
                    </Link>
                </Col>
            </Row>
            <Formik
                initialValues={{
                    
                }}

                validationSchema={storeShema}
                onSubmit={(values, actions) => {
                    updateParameter(JSON.stringify(notConfig))
                }}
            >
                {
                    (
                        {
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors
                        }
                    ) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="p-0 m-0">
                                <Col sm={12}>
                                    <h5 className="mt-5">Notification pour expédition de colis</h5>
                                </Col>
                                <Col sm={12}>
                                    {
                                        notConfig.colis.map((el,key)=>{
                                            return <Form.Group controlId="">
                                            <Form.Label>state {key} ( {colis_states[key]} )</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={el.message}
                                                onChange={(e)=>{updateNot("colis",e.target.value,key)}}
                                               
                                            />
                                        </Form.Group>
                                        })
                                    }
                                </Col>
                            </Row>

                            <Row className="p-0 m-0">
                                <Col sm={12}>
                                    <h5 className="mt-5">Notification pour demand de carte</h5>
                                </Col>
                                <Col sm={12}>
                                    {
                                        notConfig.visa.map((el,key)=>{
                                            return <Form.Group controlId="">
                                            <Form.Label>state {key} ( {visa_states[key]} )</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={el.message}
                                                onChange={(e)=>{updateNot("visa",e.target.value,key)}}
                                            />
                                        </Form.Group>
                                        })
                                    }
                                </Col>
                            </Row>

                            <Row className="p-0 m-0">
                                <Col sm={12}>
                                    <h5 className="mt-5">Notification pour recharges</h5>
                                </Col>
                                <Col sm={12}>
                                    {
                                        notConfig.shipping.map((el,key)=>{
                                            return <Form.Group controlId="">
                                            <Form.Label>state {key} ( {shipping_states[key]} )</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={el.message}
                                                onChange={(e)=>{updateNot("shipping",e.target.value,key)}}
                                               
                                            />
                                        </Form.Group>
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end mt-3">
                                    <Button type="submit" disabled={loding}>
                                        {loding ? spanLoding : ""}
                                        {
                                            intl.formatMessage({ id: 'SAVE' }) 
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                    )}

            </Formik>
        </Container>
    :<Loader />
}

export default ManageNotifications;